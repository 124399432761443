import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "../../components/Heading";

import { airports_airports_edges_node_handlings } from "./__generated__/airports";
import AirportHandling from "./AirportHandling";

interface Props {
  handlings: airports_airports_edges_node_handlings[] | null;
}

const AirportHandlingList: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { handlings } = props;

  if (!handlings) return null;

  return (
    <div>
      <Heading variant={"h3"}>Handling data</Heading>
      {handlings.map((handling: airports_airports_edges_node_handlings) => (
        <AirportHandling key={handling.id} handling={handling} />
      ))}
    </div>
  );
};

export default AirportHandlingList;

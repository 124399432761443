import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import TextInput from "../components/TextInput";
import Button from "../components/Button";
import Link from "../components/Link";
import BasicPage from "../parts/BasicPage";
import PageMeta from "../parts/PageMeta";
import { LOGIN_PAGE } from "../routes";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
});

interface Props {}

const ResetPassword: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  return (
    <BasicPage title={t("resetPassword.title")} subTitle={t("resetPassword.subtitle")}>
      {() => (
        <React.Fragment>
          <PageMeta title={t("resetPassword.pageTitle")} />
          <Formik
            initialValues={{
              email: ""
            }}
            onSubmit={(values, actions) => {
              // TODO: implement proper logic
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 1000);
            }}
            validationSchema={validationSchema}
            render={({ values, errors, touched, isSubmitting, submitCount, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  label={t("resetPassword.inputEmail")}
                  type="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  error={!!(touched.email && errors.email)}
                  fullWidth
                />
                <Button type="submit" variant="primary" loading={isSubmitting} fullWidth>
                  {t("resetPassword.submitButton")}
                </Button>
                <Link to={LOGIN_PAGE}>{t("resetPassword.loginLink")}</Link>
              </form>
            )}
          />
        </React.Fragment>
      )}
    </BasicPage>
  );
};

export default ResetPassword;

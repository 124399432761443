import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { LOGIN_PAGE } from "../routes";
import { AuthConsumer } from "./AuthContext";

interface Props extends RouteProps {
  component: React.ComponentType<Object>;
}

const ProtectedRoute: React.FunctionComponent<Props> = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isAuth }) => (
      <Route render={props => (isAuth ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />)} {...rest} />
    )}
  </AuthConsumer>
);

export default ProtectedRoute;

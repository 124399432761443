import gql from "graphql-tag";

export const OpeningTimesFragment = gql`
  fragment OpeningTimes on OpeningTimes {
    id
    opening
    closing
  }
`;

export const AirportAircraftCategoryFeeFragment = gql`
  fragment AirportAircraftCategoryFee on AirportAircraftCategoryFee {
    id
    category
    fee
  }
`;

export const AirportsFragment = gql`
  fragment Airport on Airport {
    id
    name
    codeIata
    codeIcao
    city
    country {
      id
      name
    }
    gps {
      id
      latitude
      longitude
    }
    timezone {
      id
      name
    }
    runwayLength
    openingTimes {
      ...OpeningTimes
    }
    handlings {
      id
      name
      url
      phone
      email
      openingTimes {
        ...OpeningTimes
      }
      handlingFees {
        ...AirportAircraftCategoryFee
      }
    }
    landingFees {
      ...AirportAircraftCategoryFee
    }
  }
  ${OpeningTimesFragment}
  ${AirportAircraftCategoryFeeFragment}
`;

export const AirportInfoFragment = gql`
  fragment AirportInfo on AirportConnection {
    edges {
      node {
        ...Airport
      }
      cursor
    }
  }
  ${AirportsFragment}
`;

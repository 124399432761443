export interface Colors {
  blue: string;
  darkBlue: string;
  black: string;
  grey: string;
  mediumGrey: string;
  lightGrey: string;
  white: string;
  green: string;
  red: string;
  darkRed: string;
}

interface Sizes {
  baseUnit: number;
}

interface Typography {
  fontSize: number;
  primaryFontFamily: string;
  secondaryFontFamily: string;
  pxToRem: (px: number) => string;
}

export interface Theme {
  colors: Colors;
  sizes: Sizes;
  typography: Typography;
}

const baseUnit = 8;
const baseFontSize = baseUnit * 2;

export const theme: Theme = {
  colors: {
    blue: "#0042ff",
    darkBlue: "#000044",
    black: "#000026",
    grey: "#828282",
    mediumGrey: "#ededed",
    lightGrey: "#f3f3f3",
    white: "#ffffff",
    green: "#7ed321",
    red: "#d0021b",
    darkRed: "#680217"
  },
  sizes: {
    baseUnit
  },
  typography: {
    fontSize: baseFontSize,
    primaryFontFamily: "Montserrat, sans-serif",
    secondaryFontFamily: "Open Sans, sans-serif",
    pxToRem: px => `${px/baseFontSize}rem`
  }
};

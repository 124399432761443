import React from "react";
import { useTranslation } from "react-i18next";
import { Segment, Grid, Form, Header, Button, Message } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";

import Layout from "../parts/Layout";
import PageMeta from "../parts/PageMeta";
import { AuthConsumer } from "../parts/AuthContext";

Yup.addMethod(Yup.string, "sameAs", function(ref, message) {
  return this.test("sameAs", message, function(value) {
    // @ts-ignore
    let other = this.resolve(ref);

    return !other || !value || value === other;
  });
});

const validationSchemaUser = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Email is not valid")
});

const validationSchemaPassword = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Minimum password length is 8 characters"),
  newPasswordAgain: Yup.string()
    .required("Repeat your password")
    // @ts-ignore
    .sameAs(Yup.ref("newPassword"), "Passwords do not match")
});

interface Props {}

const UserSettings: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  return (
    <Layout title="Settings">
      <PageMeta title={t("userSettings.pageTitle")} />
      <Header as="h1" content={t("userSettings.title")} />
      <Segment stacked>
        <Grid divided columns="equal">
          <Grid.Row>
            <Grid.Column>
              <AuthConsumer>
                {({ name, email }) => (
                  <Formik
                    initialValues={{
                      name,
                      email
                    }}
                    onSubmit={() => {}}
                    validationSchema={validationSchemaUser}
                    render={({ values, errors, touched, handleChange, handleSubmit, isSubmitting, submitCount }) => (
                      <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 && submitCount > 0}
                      >
                        <Header>{t("userSettings.userFormTitle")}</Header>
                        <Form.Input
                          id="name"
                          name="name"
                          label={t("userSettings.inputName")}
                          value={values.name}
                          onChange={handleChange}
                          error={!!(touched.name && errors.name)}
                        />
                        <Form.Input
                          id="email"
                          name="email"
                          label={t("userSettings.inputEmail")}
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          error={!!(touched.email && errors.email)}
                        />
                        <Message
                          error
                          header="Form Errors"
                          // @ts-ignore
                          list={Object.keys(errors).map(error => errors[error])}
                        />
                        <Button color="blue" type="submit">
                          {t("userSettings.userFormSubmit")}
                        </Button>
                      </Form>
                    )}
                  />
                )}
              </AuthConsumer>
            </Grid.Column>
            <Grid.Column>
              <Formik
                initialValues={{
                  currentPassword: "",
                  newPassword: "",
                  newPasswordAgain: ""
                }}
                onSubmit={() => {}}
                validationSchema={validationSchemaPassword}
                render={({ values, errors, touched, handleChange, handleSubmit, isSubmitting, submitCount }) => (
                  <Form
                    onSubmit={handleSubmit}
                    loading={isSubmitting}
                    error={Object.keys(errors).length > 0 && submitCount > 0}
                  >
                    <Header>{t("userSettings.passwordFormTitle")}</Header>
                    <Form.Input
                      id="currentPassword"
                      name="currentPassword"
                      label={t("userSettings.inputCurrentPassword")}
                      type="password"
                      values={values.currentPassword}
                      onChange={handleChange}
                      error={!!(touched.currentPassword && errors.currentPassword)}
                    />
                    <Form.Input
                      id="newPassword"
                      name="newPassword"
                      label={t("userSettings.inputNewPassword")}
                      type="password"
                      values={values.newPassword}
                      onChange={handleChange}
                      error={!!(touched.newPassword && errors.newPassword)}
                    />
                    <Form.Input
                      id="newPasswordAgain"
                      name="newPasswordAgain"
                      label={t("userSettings.inputNewPasswordAgain")}
                      type="password"
                      values={values.newPasswordAgain}
                      onChange={handleChange}
                      error={!!(touched.newPasswordAgain && errors.newPasswordAgain)}
                    />
                    <Message
                      error
                      header="Form Errors"
                      // @ts-ignore
                      list={Object.keys(errors).map(error => errors[error])}
                    />
                    <Button color="blue" type="submit">
                      {t("userSettings.passwordFormSubmit")}
                    </Button>
                  </Form>
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Layout>
  );
};

export default UserSettings;

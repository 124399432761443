import React from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";

const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal-root");
document.body.appendChild(modalRoot);

const styles = (theme: Theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 38, 0.8)"
  },
  modal: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    width: 620,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    backgroundColor: theme.colors.white
  },
  small: {
    width: 620
  },
  large: {
    width: 1120
  }
});

interface Props extends WithStyles<typeof styles> {
  handleClose: () => void;
  closeOnOverlayClick?: boolean;
  component?: React.ComponentType<any> | string;
  onSubmit?: Function;
  size?: "small" | "large";
}

class Modal extends React.Component<Props> {
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";
  }

  handleClose = () => {
    if (this.props.closeOnOverlayClick) {
      this.props.handleClose();
    }
  };

  render() {
    const { classes, component, size, handleClose, closeOnOverlayClick, ...props } = this.props;
    const Component = component || "div";

    return ReactDOM.createPortal(
      <React.Fragment>
        <div className={classes.overlay} onClick={this.handleClose} />
        <Component className={clsx(classes.modal, size && classes[size])} {...props}>
          {this.props.children}
        </Component>
      </React.Fragment>,
      modalRoot
    );
  }
}

export default withStyles(styles)(Modal);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import { Theme } from "../theme";
import Button from "../components/Button";
import Link from "../components/Link";
import TextInput from "../components/TextInput";
import BasicPage from "../parts/BasicPage";
import PageMeta from "../parts/PageMeta";
import { PASSWORD_RESET_PAGE } from "../routes";

import { LoginMethod } from "../types/functions";

const styles = (theme: Theme) => ({
  input: {
    marginBottom: 20
  },
  button: {
    marginTop: 12,
    marginBottom: 24
  }
});

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("login.errorEmailInvalid")
    .required("login.errorEmailRequired"),
  password: Yup.string().required("login.errorPasswordRequired")
});

interface Props extends WithStyles<typeof styles> {}

const Login: React.FunctionComponent<Props> = ({ classes }) => {
  const { t } = useTranslation();

  return (
    <BasicPage title={t("login.title")} subTitle={t("login.subtitle")}>
      {({ login }: { login: LoginMethod }) => (
        <React.Fragment>
          <PageMeta title={t("login.pageTitle")} />
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            onSubmit={(values, actions) => {
              login(values.email, values.password);
            }}
            validationSchema={validationSchema}
            render={({ values, errors, touched, isSubmitting, submitCount, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  type="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  label={t("login.inputEmail")}
                  placeholder="your@email.com"
                  fullWidth
                  error={!!(touched.email && errors.email)}
                  className={classes.input}
                />
                <TextInput
                  type="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  label={t("login.inputPassword")}
                  fullWidth
                  error={!!(touched.password && errors.password)}
                  className={classes.input}
                />
                <Button type="submit" variant="primary" loading={isSubmitting} fullWidth className={classes.button}>
                  {t("login.submitButton")}
                </Button>
                <Link to={PASSWORD_RESET_PAGE}>{t("login.resetPasswordLink")}</Link>
              </form>
            )}
          />
        </React.Fragment>
      )}
    </BasicPage>
  );
};

export default withStyles(styles)(Login);

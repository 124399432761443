import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import crossIcon from "../images/cross.svg";
import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    position: "relative",
    padding: theme.typography.pxToRem(16),
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.red,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 6,
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16);"
  },
  hidden: {
    display: "none"
  },
  title: {
    paddingBottom: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: 700,
    color: theme.colors.red
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.secondaryFontFamily,
    lineHeight: theme.typography.pxToRem(20),
    color: theme.colors.grey
  },
  cross: {
    position: "absolute",
    top: 12,
    right: 12,
    cursor: "pointer"
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
  title: string;
  message: string;
  closable?: boolean;
}

const Error: React.FunctionComponent<Props> = React.memo(({ classes, className, title, message, closable }) => {
  const [hidden, setHidden] = React.useState<boolean>(false);

  const handleSetHidden = () => setHidden(true);

  return (
    <div className={clsx(classes.root, className, hidden && classes.hidden)}>
      {closable && <img className={classes.cross} src={crossIcon} alt="" onClick={handleSetHidden} />}
      <div className={classes.title}>{title}</div>
      <div className={classes.message}>{message}</div>
    </div>
  );
});

export default withStyles(styles)(Error);

import React from "react";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { Query } from "react-apollo";

import Button from "../../components/Button";
import Layout from "../../parts/Layout";
import PageMeta from "../../parts/PageMeta";
import CrewMemberList from "./CrewMemberList";
import AddCrewMemberModal from "./AddCrewMemberModal";
import { CrewMemberInfoFragment } from "./crewMembers.fragment";
import { getCrewMembers, getCrewMembersVariables } from "./__generated__/getCrewMembers";

export const GET_CREW_MEMBERS = gql`
  query getCrewMembers($first: Int!, $after: String) {
    crewMembers(first: $first, after: $after) {
      ...CrewMemberInfo
    }
  }
  ${CrewMemberInfoFragment}
`;

class CrewMembersQuery extends Query<getCrewMembers, getCrewMembersVariables> {}

interface Props {}

const CrewMembers: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalState] = React.useState<boolean>(false);

  const openModal = () => setModalState(true);
  const closeModal = () => setModalState(false);

  return (
    <CrewMembersQuery query={GET_CREW_MEMBERS} variables={{ first: 100 }}>
      {({ data, loading, refetch }) => (
        <Layout
          title={t("crewMembers.title")}
          actionButton={
            <Button variant="primary" small onClick={openModal}>
              {t("crewMembers.addButton")}
            </Button>
          }
        >
          <PageMeta title={t("crewMembers.pageTitle")} />
          <CrewMemberList
            crewMembers={data && data.crewMembers ? data.crewMembers.edges : null}
            refetch={refetch}
            loading={loading}
          />
          {modalOpen && <AddCrewMemberModal handleClose={closeModal} />}
        </Layout>
      )}
    </CrewMembersQuery>
  );
};

export default CrewMembers;

import React from "react";
import { Mutation } from "react-apollo";

import { search, search_proposals_edges_node, searchVariables } from "../pages/search/__generated__/search";
import { Modal, ModalHeader, ModalContent, ModalFooter } from "../components/Modal";
import Button from "../components/Button";
import FlightInfo from "./FlightInfo";
import FlightLegs from "./FlightLegs";
import FlightPrices from "./FlightPrices";

import { SEARCH, CONFIRM_FLIGHT, QueryVariables } from "../pages/search/Search";
import { confirmProposal, confirmProposalVariables } from "../pages/search/__generated__/confirmProposal";
import { FlightStatus, ProposalStatus } from "../__generated__/globalTypes";

class ConfirmMutation extends Mutation<confirmProposal, confirmProposalVariables> {}

interface Props {
  handleClose: () => void;
  proposal: search_proposals_edges_node;
  queryVariables: QueryVariables | undefined;
}

class FlightDetail extends React.Component<Props> {
  render() {
    const { proposal } = this.props;

    return (
      <Modal closeOnOverlayClick handleClose={this.props.handleClose} size="large">
        <ModalHeader
          title="Flight detail"
          actionButton={
            <Button onClick={this.props.handleClose} variant="secondary" small>
              Back to list
            </Button>
          }
        />
        <ModalContent>
          <FlightInfo flight={proposal} />
          <FlightLegs legs={proposal.legs} legsToReplace={proposal.legsToReplace} />
          <FlightPrices flight={proposal} />
        </ModalContent>
        <ModalFooter>
          <ConfirmMutation
            mutation={CONFIRM_FLIGHT}
            variables={{ id: proposal.id }}
            update={(cache, data) => {
              const queryData = cache.readQuery<search, searchVariables>({
                query: SEARCH,
                variables: this.props.queryVariables
              });
              const proposalsCache = queryData && queryData.proposals;

              const confirmedFlight = data.data && data.data.confirmProposal;

              // check if those data exists
              if (!proposalsCache || !confirmedFlight) return;

              // check if mutation returned confirmed flight
              if (confirmedFlight.status !== FlightStatus.CONFIRMED) return;

              const updatedProposalIndex = proposalsCache.edges.findIndex(
                cachedProposal => cachedProposal.node.id === confirmedFlight.id
              );

              // check if object was really found in cache
              if (updatedProposalIndex === -1) return;

              proposalsCache.edges[updatedProposalIndex].node.status = ProposalStatus.EXPIRED;

              cache.writeQuery<search, searchVariables>({
                query: SEARCH,
                data: { proposals: proposalsCache }
              });
            }}
          >
            {(confirmFlight, { error, loading, called }) => {
              if (this.props.proposal.status === ProposalStatus.EXPIRED) {
                return (
                  <Button
                    variant="primary"
                    disabled
                  >
                    Confirmed
                  </Button>
                )
              }

              return (
                // TODO: Handle error properly
                <Button
                  variant="primary"
                  onClick={() => confirmFlight()}
                  loading={loading}
                  disabled={!!error && called}
                >
                  Confirm
                </Button>
              );
            }}
          </ConfirmMutation>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FlightDetail;

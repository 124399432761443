import React, { useCallback, useState } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import Layout from "../../parts/Layout";
import PageMeta from "../../parts/PageMeta";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getOperators, getOperatorsVariables } from "./__generated__/getOperators";
import { Table, TableCell, TableHeaderCell } from "../../components/Table";
import AddOperatorModal from "./AddOperatorModal";
import Button from "../../components/Button";
import UpdateOperatorModal from "./UpdateOperatorModal";

const GET_OPERATORS = gql`
  query getOperators($first: Int!) {
    operators(first: $first) {
      edges {
        node {
          id
          name
          codeIcao
        }
      }
    }
  }
`;

interface Props {}

const Operators: React.FunctionComponent<Props> = () => {
  const [addModalOpen, setAddModalState] = useState<boolean>(false);
  const [updateModalOpen, setUpdateModalState] = useState<string | null>(null);

  const openAddModal = useCallback(() => setAddModalState(true), []);
  const closeAddModal = useCallback(() => setAddModalState(false), []);

  const openUpdateModal = useCallback(
    (id: string) => () => {
      setUpdateModalState(id);
    },
    []
  );
  const closeUpdateModal = useCallback(() => {
    setUpdateModalState(null);
  }, []);

  return (
    <Layout
      title="Operators"
      actionButton={
        <Button variant="primary" small onClick={openAddModal}>
          {"Add new operator"}
        </Button>
      }
    >
      <PageMeta title=" Operators" />
      <Query<getOperators, getOperatorsVariables> query={GET_OPERATORS} variables={{ first: 500 }}>
        {({ loading, data, error }) => {
          if (loading) {
            return <Loader />;
          }

          if (error) {
            return <Message title="There was an error, please try again." />;
          }

          if (!data || data.operators == null || data.operators.edges == null || data.operators.edges.length <= 0) {
            return <Message title="There is nothing to show." />;
          }

          return (
            <Table>
              <thead>
                <tr>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>ICAO</TableHeaderCell>
                  <TableHeaderCell textAlign="right">Actions</TableHeaderCell>
                </tr>
              </thead>
              <tbody>
                {data.operators.edges.map(operator => (
                  <tr key={operator.node.id}>
                    <TableCell>{operator.node.name}</TableCell>
                    <TableCell>{operator.node.codeIcao}</TableCell>
                    <TableCell textAlign="right">
                      <Button onClick={openUpdateModal(operator.node.id)} variant="secondary" small>
                        {"Edit"}
                      </Button>
                      {updateModalOpen === operator.node.id && <UpdateOperatorModal operator={operator.node} handleClose={closeUpdateModal} />}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          );
        }}
      </Query>
      {addModalOpen && <AddOperatorModal handleClose={closeAddModal} />}
    </Layout>
  );
};

export default Operators;

import React from "react";
import { useTranslation } from "react-i18next";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import UpdateAircraftModal from "./UpdateAircraftModal";

import { Table, TableHeaderCell, TableCell, TableRow } from "../../components/Table";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Heading from "../../components/Heading";
import Message from "../../components/Message";

import { getAircrafts_aircrafts_edges } from "./__generated__/getAircrafts";
import { getAircraft, getAircraftVariables } from "./__generated__/getAircraft";

const GET_AIRCRAFT = gql`
  query getAircraft($id: UUID!) {
    aircraft(id: $id) {
      id
      name
      tailRegistration
      codeIcao
      maximumCapacity
      category
      homebase {
        id
        name
        codeIata
        codeIcao
      }
      turnaroundTime
      rateOfClimb
      rateOfDescent
      cruiseAltitude
      takeOffSpeed
      landingSpeed
      cruiseSpeed
      hourlyFeeFerry
      hourlyFeeOccupied
      fixedFee
      overnightFee
      passengerFee
      landingFee
      preflightCheckTime
      preflightCheckTimeHomebase
      postflightCheckTime
      flightRanges {
        id
        passengerCount
        maximumFlightRange
        minimalLandingRunwayLength
        runwayLengthWarning
      }
    }
  }
`;

class AircraftQuery extends Query<getAircraft, getAircraftVariables> {}

interface Props {
  aircrafts: ReadonlyArray<getAircrafts_aircrafts_edges> | null;
  refetch: Function;
  loading: boolean;
}

const AircraftList: React.FunctionComponent<Props> = ({ refetch, loading, aircrafts}) => {
  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const { t } = useTranslation();

  const [modalOpen, setModalState] = React.useState<boolean>(false);
  const [aircraftId, setAircraftId] = React.useState<string>("");

  const openModal = (id: string) => () => {
    setAircraftId(id);
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  if (loading) return <Loader />;

  if (!aircrafts) return <Message title="No aircrafts were found." />;

  return (
    <React.Fragment>
      <Table>
        <thead>
          <TableRow>
            <TableHeaderCell>{t("fleet.table.name")}</TableHeaderCell>
            <TableHeaderCell>{t("fleet.table.category")}</TableHeaderCell>
            <TableHeaderCell>{t("fleet.table.maximumCapacity")}</TableHeaderCell>
            <TableHeaderCell>{t("fleet.table.homebase")}</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </thead>
        <tbody>
          {aircrafts.map(aircraft => (
            <TableRow key={aircraft.node.id}>
              <TableCell>
                <Heading variant="h3">{aircraft.node.name}</Heading>
              </TableCell>
              <TableCell>{aircraft.node.category && t(`aircraftCategory.${aircraft.node.category}`)}</TableCell>
              <TableCell>{aircraft.node.maximumCapacity}</TableCell>
              <TableCell>{aircraft.node && aircraft.node.homebase && aircraft.node.homebase.name}</TableCell>
              <TableCell textAlign="right">
                <Button onClick={openModal(aircraft.node.id)} variant="secondary" small>
                  {t("fleet.table.edit")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {modalOpen && (
        <AircraftQuery query={GET_AIRCRAFT} variables={{ id: aircraftId }}>
          {queryData => {
            if (queryData.loading || !queryData.data) return null;

            const { aircraft } = queryData.data;

            if (queryData.error || !aircraft) {
              closeModal();

              return null;
            }

            return <UpdateAircraftModal handleClose={closeModal} aircraft={aircraft} />;
          }}
        </AircraftQuery>
      )}
    </React.Fragment>
  );
};

export default AircraftList;

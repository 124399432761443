import gql from "graphql-tag";
import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Query } from "react-apollo";
import { DateTime } from "luxon";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { Theme } from "../../theme";
import AddOnDutyModal from "./AddOnDutyModal";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import OnDuty from "./OnDutyFleetCalendarItem";
import PageMeta from "../../parts/PageMeta";
import Layout from "../../parts/Layout";
import { getScheduleFleet, getScheduleFleetVariables } from "./__generated__/getScheduleFleet";
import { OnDutyFragment } from "./onDuty.fragment";

const GET_SCHEDULE_FLEET = gql`
  query getScheduleFleet($since: DateTime!, $to: DateTime!, $first: Int!, $after: String) {
    aircrafts(first: $first, after: $after) {
      edges {
        node {
          id
          name
          onDuties(since: $since, to: $to) {
            ...OnDutyInfo
          }
        }
        cursor
      }
    }
  }
  ${OnDutyFragment}
`;

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    borderRadius: 6,
    backgroundColor: theme.colors.white
  },
  fleetTable: {
    width: "25%",
    minWidth: "25%",
    maxWidth: "25%",
    boxShadow: "4px 0 11px 0 rgba(0, 0, 0, 0.08)",
    "& th": {
      padding: "52px 24px 12px",
      borderBottom: "1px solid",
      borderBottomColor: theme.colors.lightGrey,
      textAlign: "left",
      fontSize: 16,
      fontFamily: theme.typography.primaryFontFamily,
      fontWeight: 400,
      color: theme.colors.grey
    },
    "& td": {
      maxWidth: 230,
      height: 44,
      padding: "12px 24px",
      borderBottom: "1px solid",
      borderBottomColor: theme.colors.lightGrey,
      fontSize: 16,
      fontFamily: theme.typography.primaryFontFamily,
      fontWeight: 400,
      color: theme.colors.black,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "tr:last-child &": {
        borderBottom: "none"
      }
    }
  },
  operationsWrapper: {
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden"
  },
  operationsTableWrapper: {
    flex: 1,
    overflowX: "auto",
    position: "relative"
  },
  operationsTable: {
    "& th": {
      padding: "12px 24px",
      border: "1px solid",
      borderColor: theme.colors.lightGrey,
      borderTop: "none",
      fontSize: 16,
      fontFamily: theme.typography.primaryFontFamily,
      fontWeight: 400,
      color: theme.colors.grey
    },
    "& td": {
      minWidth: 70,
      height: 44,
      padding: "12px 24px",
      border: "1px solid",
      borderColor: theme.colors.lightGrey,
      borderTop: "none"
    }
  },
  monthWrapper: {
    display: "flex",
    padding: "8px 21px",
    fontSize: 12
  },
  month: {
    flex: 1,
    textAlign: "center",
    fontWeight: 700,
    color: theme.colors.black
  },
  arrow: {
    cursor: "pointer",
    color: theme.colors.grey
  },
  thToday: {
    "$operationsTableWrapper &": {
      fontWeight: 700,
      color: theme.colors.blue,
      backgroundColor: "rgba(0, 66, 255, 0.04)"
    }
  },
  tdToday: {
    "$operationsTableWrapper &": {
      backgroundColor: "rgba(0, 66, 255, 0.04)"
    }
  },
  cellWeekend: {
    "$operationsTableWrapper &": {
      backgroundColor: "rgba(0, 0, 68, 0.08)"
    }
  },
  cellDisabled: {
    cursor: "no-drop"
  },
  cellEnabled: {
    cursor: "copy"
  }
});

class ScheduleFleetQuery extends Query<getScheduleFleet, getScheduleFleetVariables> {}

interface Props extends WithTranslation, WithStyles<typeof styles> {}

interface State {
  month: DateTime;
  daysInMonth: Array<DateTime>;
  modalOpen: boolean;
  onDutyData: any;
  showOnDuties: boolean;
}

const getDaysInMonth = (month: DateTime): Array<DateTime> => {
  const days = [];

  for (let day = month; day.valueOf() <= month.endOf("month").valueOf(); day = day.plus({ days: 1 })) {
    days.push(day);
  }

  return days;
};

class ScheduleFleet extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const month = DateTime.local().startOf("month");

    this.state = {
      month: month,
      daysInMonth: getDaysInMonth(month),
      modalOpen: false,
      onDutyData: null,
      showOnDuties: false
    };
  }

  componentDidMount(): void {
    this.setState({ showOnDuties: true });
  }

  // FIXME: Come up with better solution than setTimeout as ref callback
  private setScroll = () => {
    setTimeout(() => {
      if (this.operationsTableWrapperRef && this.operationsTableWrapperRef.current) {
        const tableWrapperElement = this.operationsTableWrapperRef.current;

        const todayCell = tableWrapperElement.querySelector<HTMLTableHeaderCellElement>(`.${this.props.classes.thToday}`);

        if (todayCell) {
          tableWrapperElement.scrollTo({
            left: todayCell.offsetLeft - tableWrapperElement.offsetLeft + 200,
            behavior: "smooth"
          });
        }
      }
    }, 1000);
  };

  private operationsTableWrapperRef = React.createRef<HTMLDivElement>();

  private today = DateTime.local().startOf("day");

  private previousMonth = (): void => {
    const month = this.state.month.minus({ months: 1 });

    this.setState({
      month: month,
      daysInMonth: getDaysInMonth(month)
    });
  };

  private nextMonth = (): void => {
    const month = this.state.month.plus({ months: 1 });

    this.setState({
      month: month,
      daysInMonth: getDaysInMonth(month)
    });
  };

  private isWeekend = (date: DateTime): boolean => {
    return date.weekday > 5;
  };

  private isToday = (date: DateTime): boolean => {
    return date.valueOf() === this.today.valueOf();
  };

  openModal = (data: any) => {
    this.setState({
      modalOpen: true,
      onDutyData: data
    });
  };

  closeModal = () => this.setState({ modalOpen: false });

  render() {
    const { t, classes } = this.props;

    return (
      <Layout title={t("scheduleFleet.title")}>
        <PageMeta title={t("scheduleFleet.pageTitle")} />
        <ScheduleFleetQuery
          query={GET_SCHEDULE_FLEET}
          variables={{
            first: 100,
            since: this.state.month.startOf("month").toISO(),
            to: this.state.month.endOf("month").toISO()
          }}
        >
          {({ data, error, loading }) => {
            if (loading) {
              return <Loader />;
            }

            if (error) {
              return <Message title="Schedule could not be loaded." />;
            }

            return (
              <div className={classes.root}>
                <table cellSpacing={0} cellPadding={0} className={classes.fleetTable} ref={this.setScroll}>
                  <thead>
                    <tr>
                      <th>Aircraft</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.aircrafts &&
                      data.aircrafts.edges.map(aircraft => (
                        <tr key={aircraft.node.id}>
                          <td>{aircraft.node.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className={classes.operationsWrapper}>
                  <div className={classes.monthWrapper}>
                    <KeyboardArrowLeftIcon className={classes.arrow} onClick={this.previousMonth} />
                    <div className={classes.month}>
                      {this.state.month.setLocale("en-US").monthLong.toUpperCase()} {this.state.month.year}
                    </div>
                    <KeyboardArrowRightIcon className={classes.arrow} onClick={this.nextMonth} />
                  </div>
                  <div className={classes.operationsTableWrapper} ref={this.operationsTableWrapperRef}>
                    <table cellSpacing={0} cellPadding={0} className={classes.operationsTable}>
                      <thead>
                        <tr>
                          {this.state.daysInMonth.map(day => (
                            <th key={day.day} className={clsx(this.isWeekend(day) && classes.cellWeekend, this.isToday(day) && classes.thToday)}>
                              {day.day}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.aircrafts &&
                          data.aircrafts.edges.map(aircraft => (
                            <tr key={aircraft.node.id}>
                              {this.state.daysInMonth.map(day => (
                                <td
                                  key={day.day}
                                  id={`${aircraft.node.id}-${day.toISODate()}`}
                                  className={clsx(
                                    this.isToday(day) && classes.tdToday,
                                    this.isWeekend(day) && classes.cellWeekend,
                                    day.valueOf() < this.today.valueOf() ? classes.cellDisabled : classes.cellEnabled
                                  )}
                                  onClick={
                                    day.valueOf() >= this.today.valueOf()
                                      ? () =>
                                          this.openModal({
                                            aircraft: aircraft.node,
                                            date: day
                                          })
                                      : () => null
                                  }
                                >
                                  &nbsp;
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {this.state.showOnDuties &&
                      data &&
                      data.aircrafts &&
                      data.aircrafts.edges.map(
                        aircraft =>
                          aircraft.node.onDuties &&
                          aircraft.node.onDuties.map(onDuty => <OnDuty key={onDuty.id} id={aircraft.node.id} onDuty={onDuty} />)
                      )}
                  </div>
                </div>
                {this.state.modalOpen && this.state.onDutyData && (
                  <AddOnDutyModal
                    handleClose={this.closeModal}
                    refetchQueries={["getScheduleCrew", "getScheduleFleet"]}
                    data={
                      this.state.onDutyData || {
                        aircraft: null,
                        crewMember: null,
                        date: null
                      }
                    }
                  />
                )}
              </div>
            );
          }}
        </ScheduleFleetQuery>
      </Layout>
    );
  }
}

export default withTranslation()(withStyles(styles)(ScheduleFleet));

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import { NavLink, NavLinkProps } from "react-router-dom";
import clsx from "clsx";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 20px",
    marginBottom: theme.typography.pxToRem(8),
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.colors.grey,
    backgroundColor: "transparent",
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    transition: "background-color 0.2s, color 0.2s",
    "&:hover, &:focus": {
      color: theme.colors.white,
      backgroundColor: theme.colors.darkBlue
    }
  },
  active: {
    color: theme.colors.white
  },
  icon: {
    marginRight: 12
  }
});

interface Props extends NavLinkProps, WithStyles<typeof styles> {
  icon?: React.ComponentType<any>;
}

const MenuItem: React.FunctionComponent<Props> = ({ classes, children, icon, className, ...props }) => {
  const Icon = icon;

  return (
    <NavLink className={clsx(classes.root, className)} activeClassName={classes.active} {...props}>
      {Icon && <Icon className={classes.icon} />}
      {children}
    </NavLink>
  );
};

export default withStyles(styles)(MenuItem);

import gql from "graphql-tag";

export const OnDutyFragment = gql`
  fragment OnDutyInfo on OnDuty {
    id
    since
    to
    aircraft {
      id
      name
    }
    crewMember {
      id
      name
    }
  }
`;

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";
import AirportSelector, { Props as AirportSelectorProps } from "../pages/world/AirportSelector";

const styles = (theme: Theme) => ({
  wrapper: {
    display: "inline-block",
    position: "relative",
    verticalAlign: "bottom"
  },
  label: {
    position: "absolute",
    top: "20px",
    left: "20px",
    fontSize: "16px",
    lineHeight: "16px",
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.grey,
    transformOrigin: "left top",
    transition: "transform 0.1s, color 0.1s",
    pointerEvents: "none",
    transform: "scale(0.8) translateY(-12px)"
  },
  root: {
    boxSizing: "border-box",
    padding: 0,
    border: "1px solid",
    backgroundColor: theme.colors.white,
    borderColor: "#cdcdcd",
    borderRadius: 4,
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.black,
    fontSize: "16px",
    lineHeight: "16px",
    transition: "border-color 0.1s",
    "& .ui.selection.dropdown": {
      padding: "29px 20px 10px",
      border: "none"
    },
    "& i.dropdown.icon": {
      display: "none"
    },
    "& .ui.search.dropdown>.text": {
      left: 0
    },
    "& .ui.search.dropdown>input.search": {
      top: 18,
      left: 5,
      fontFamily: theme.typography.secondaryFontFamily
    }
  },
  fullWidth: {
    width: "100%",
    "& .ui.selection.dropdown": {
      width: "100%"
    }
  },
  error: {
    borderColor: theme.colors.red
  },
  paddingBottom: {
    paddingBottom: 20
  }
});

interface Props extends AirportSelectorProps, WithStyles<typeof styles> {
  fullWidth?: boolean;
  className?: string;
  paddingBottom?: boolean;
}

class AirportDropdown extends React.Component<Props> {
  render() {
    const { classes, className, label, fullWidth, error, paddingBottom, ...props } = this.props;

    return (
      <div
        className={clsx(
          classes.wrapper,
          fullWidth && classes.fullWidth,
          paddingBottom && classes.paddingBottom,
          className
        )}
      >
        <AirportSelector
          className={clsx(classes.root, fullWidth && classes.fullWidth, error && classes.error)}
          {...props}
        />
        <label className={clsx(classes.label)}>{label}</label>
      </div>
    );
  }
}

export default withStyles(styles)(AirportDropdown);

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AircraftCategory {
  HEAVY = "HEAVY",
  LIGHT = "LIGHT",
  MIDSIZE = "MIDSIZE",
  SUPER_LIGHT = "SUPER_LIGHT",
  SUPER_MIDSIZE = "SUPER_MIDSIZE",
  ULTRA_LONG_RANGE = "ULTRA_LONG_RANGE",
  VERY_LIGHT = "VERY_LIGHT",
}

export enum CrewMemberRank {
  CAPTAIN = "CAPTAIN",
  FIRST_OFFICER = "FIRST_OFFICER",
}

export enum FlightStatus {
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  UNCONFIRMED = "UNCONFIRMED",
}

export enum LegStatus {
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  UNCONFIRMED = "UNCONFIRMED",
}

export enum LegType {
  FERRY = "FERRY",
  OCCUPIED = "OCCUPIED",
}

export enum OpsStatus {
  CONFIRMED = "CONFIRMED",
  DISABLED = "DISABLED",
  REQUESTED = "REQUESTED",
  TODO = "TODO",
}

export enum OpsType {
  CATERING = "CATERING",
  CREW = "CREW",
  FLIGHT_PLANNING = "FLIGHT_PLANNING",
  FUEL = "FUEL",
  HANDLING = "HANDLING",
  PERMITS = "PERMITS",
}

export enum ProposalStatus {
  EXPIRED = "EXPIRED",
  VALID = "VALID",
}

export interface AircraftRangeInput {
  id: any;
  maximumFlightRange: number;
  minimalLandingRunwayLength: number;
  passengerCount: number;
  runwayLengthWarning: number;
}

export interface AirportFilter {
  query: string;
}

export interface FlightFilter {
  status: FlightStatus[];
}

export interface LegFilter {
  status: LegStatus[];
}

export interface OnDutyAddInput {
  aircraftId: any;
  crewMemberId: any;
  id: any;
  since: any;
  to: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

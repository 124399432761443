import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.colors.white,
    border: "1px solid",
    borderColor: theme.colors.lightGrey,
    borderRadius: 6,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)"
  }
});

interface Props extends React.HTMLProps<HTMLTableElement>, WithStyles<typeof styles> {}

const Table: React.FunctionComponent<Props> = ({ classes, children, className, ...props }) => (
  <table cellPadding={0} cellSpacing={0} className={clsx(classes.root, className)} {...props}>
    {children}
  </table>
);

export default withStyles(styles)(Table);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import GoogleMapReact from "google-map-react";
import AircraftLocation from "./AircraftLocation";
import { getAircraftsForMap_aircrafts_edges_node } from "../pages/__generated__/getAircraftsForMap";

const styles = {
  root: {
    height: "75vh",
    width: "100%"
  }
};

interface Props extends WithStyles<typeof styles> {
  aircrafts: getAircraftsForMap_aircrafts_edges_node[];
  zoom?: number;
  center?: {
    lat: number;
    lng: number;
  };
}

class AircraftMap extends React.Component<Props> {
  render() {
    return (
      <div className={this.props.classes.root}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
          }}
          defaultCenter={{
            lat: 50.0823494,
            lng: 14.4220517
          }}
          defaultZoom={4}
        >
          {this.props.aircrafts.map(aircraft => {
            if (aircraft.gps == null || aircraft.gps.latitude == null || aircraft.gps.longitude == null) {
              return null;
            }

            return (
              <AircraftLocation
                key={aircraft.id}
                lat={aircraft.gps.latitude}
                lng={aircraft.gps.longitude}
                aircraft={aircraft}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default withStyles(styles)(AircraftMap);

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "typeface-montserrat";
import "typeface-open-sans";

import "./i18n";

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(<App />, rootElement);
} else {
  throw new Error("The root element was not found");
}

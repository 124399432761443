import React from "react";
import withStyles, { WithStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import Heading from "../components/Heading";

const styles = {
  "@global": {
    "html, body, #root": {
      minHeight: "100%",
      "-webkit-font-smoothing": "antialiased",
      "font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale"
    },
    "#root": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  root: {
    textAlign: "center"
  }
};

interface Props extends WithStyles<typeof styles> {}

const NotFound: React.FunctionComponent<Props> = React.memo(({ classes }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img src="/logo-clear.png" alt="" />
      <Heading variant="h1">{t("notFound.title")}</Heading>
      <Heading variant="h2">{t("notFound.subtitle")}</Heading>
    </div>
  );
});

export default withStyles(styles)(NotFound);

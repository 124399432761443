import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";
import Typography from "./Typography";

const styles = (theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.grey
  }
});

interface Props extends React.HTMLProps<HTMLParagraphElement>, WithStyles<typeof styles> {}

class Paragraph extends React.PureComponent<Props> {
  render() {
    const { classes, className, children, color, ...props } = this.props;

    return (
      <Typography component="p" className={clsx(classes.root, className)} {...props}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(Paragraph);

import React from "react";
import { useTranslation } from "react-i18next";
import withStyles, { WithStyles } from "react-jss";
import { Mutation } from "react-apollo";
import { DateTime, Settings } from "luxon";
import gql from "graphql-tag";
import uuid from "uuid/v4";
import { Query } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import { Modal, ModalContent, ModalHeader, ModalFooter } from "../../components/Modal";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { Theme } from "../../theme";

import { updateOnDuty, updateOnDutyVariables } from "./__generated__/updateOnDuty";
import { deleteOnDuty, deleteOnDutyVariables } from "./__generated__/deleteOnDuty";
import { getOnDutyFormValues, getOnDutyFormValuesVariables } from "./__generated__/getOnDutyFormValues";

import Loader from "../../components/Loader";
import Message from "../../components/Message";
import TextInput from "../../components/TextInput";
import Paragraph from "../../components/Paragraph";
import Error from "../../components/Error";
import DeleteLink from "../../components/DeleteLink";
import { OnDutyInfo } from "./__generated__/OnDutyInfo";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const validationSchema = Yup.object().shape({
  crewMemberId: Yup.string().required("Crew member is required"),
  aircraftId: Yup.string().required("Aircraft is required"),
  since: Yup.date().required("Since is required"),
  to: Yup.date().required("To is required"),
  prependingCrewMemberId: Yup.string().nullable(),
  appendingCrewMemberId: Yup.string().nullable()
});

const styles = (theme: Theme) => ({
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  black: {
    color: theme.colors.black
  }
});

const UPDATE_ON_DUTIES = gql`
  mutation updateOnDuty($add: [OnDutyAddInput!]!, $delete: [UUID!]) {
    changeOnDuties(add: $add, delete: $delete)
  }
`;

const DELETE_ON_DUTY = gql`
  mutation deleteOnDuty($delete: [UUID!]!) {
    changeOnDuties(delete: $delete)
  }
`;

const GET_ON_DUTY_FORM_VALUES = gql`
  query getOnDutyFormValues($first: Int!) {
    crewMembers(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
    aircrafts(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

class UpdateOnDutyMutation extends Mutation<updateOnDuty, updateOnDutyVariables> {}
class DeleteOnDutyMutation extends Mutation<deleteOnDuty, deleteOnDutyVariables> {}

interface Props extends WithStyles<typeof styles> {
  handleClose: () => void;
  refetchQueries: string[];
  onDuty: OnDutyInfo;
}

class OnDutyFormValuesQuery extends Query<getOnDutyFormValues, getOnDutyFormValuesVariables> {}

const UpdateCrewOnDutyModal: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const today = DateTime.utc().startOf("day");

  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);

  return (
    <UpdateOnDutyMutation mutation={UPDATE_ON_DUTIES} refetchQueries={props.refetchQueries} awaitRefetchQueries>
      {(updateOnDuty, { error: mutationError, loading }) => (
        <Formik
          initialValues={{
            aircraftId: props.onDuty.aircraft && props.onDuty.aircraft.id,
            crewMemberId: props.onDuty.crewMember && props.onDuty.crewMember.id,
            since: DateTime.fromISO(props.onDuty.since),
            to: DateTime.fromISO(props.onDuty.to),
            prependingCrewMemberId: null,
            appendingCrewMemberId: null
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const add = [
              {
                id: uuid(),
                aircraftId: values.aircraftId,
                crewMemberId: values.crewMemberId,
                since: values.since,
                to: values.to
              }
            ];

            if (values.prependingCrewMemberId) {
              add.push({
                id: uuid(),
                aircraftId: values.aircraftId,
                crewMemberId: values.prependingCrewMemberId,
                since: props.onDuty.since,
                to: values.since.minus({ days: 1 }).endOf("day")
              });
            }

            if (values.appendingCrewMemberId) {
              add.push({
                id: uuid(),
                aircraftId: values.aircraftId,
                crewMemberId: values.appendingCrewMemberId,
                since: values.to.plus({ days: 1 }).startOf("day"),
                to: props.onDuty.to
              });
            }

            try {
              await updateOnDuty({
                variables: {
                  add: add,
                  delete: [props.onDuty.id]
                }
              });
              props.handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
          render={({ values, errors, touched, isSubmitting, submitCount, setSubmitting, handleChange, handleSubmit, setFieldValue }) => (
            <Modal component="form" onSubmit={handleSubmit} handleClose={props.handleClose} closeOnOverlayClick>
              <ModalHeader
                title="Update event"
                actionButton={
                  <Button onClick={props.handleClose} variant="secondary" small>
                    Back to schedule
                  </Button>
                }
              />
              <ModalContent>
                <OnDutyFormValuesQuery
                  query={GET_ON_DUTY_FORM_VALUES}
                  variables={{
                    first: 100
                  }}
                >
                  {({ data, error }) => {
                    if (loading) {
                      return <Loader />;
                    }

                    if (error || data == null) {
                      return <Message title="Form values could not be loaded." />;
                    }

                    return (
                      <div>
                        <Paragraph>
                          Update event for{" "}
                          {props.onDuty.crewMember && <strong className={props.classes.black}>{props.onDuty.crewMember.name}</strong>}
                        </Paragraph>
                        <Select
                          options={
                            (data.aircrafts &&
                              data.aircrafts.edges.map(aircraft => {
                                return {
                                  key: aircraft.node.id,
                                  value: aircraft.node.id,
                                  text: aircraft.node.name || ""
                                };
                              })) ||
                            []
                          }
                          value={values.aircraftId}
                          label="Aircraft"
                          onChange={(event: any, data: { value: string }) => {
                            setFieldValue("aircraftId", data.value);
                          }}
                          error={!!errors.aircraftId}
                          paddingBottom
                          fullWidth
                        />
                        <div className={props.classes.dateWrapper}>
                          <DayPickerInput
                            component={TextInput}
                            inputProps={{
                              label: "Start date",
                              paddingBottom: true
                            }}
                            value={values.since == null ? today.toJSDate() : values.since.toJSDate()}
                            format="LLL dd yyyy"
                            formatDate={(date, format) => DateTime.fromJSDate(date).toFormat(format)}
                            dayPickerProps={{
                              disabledDays: {
                                before: today.toJSDate(),
                                after: today
                                  .plus({ years: 100 })
                                  .endOf("year")
                                  .toJSDate()
                              }
                            }}
                            onDayChange={selectedDay => {
                              const value = DateTime.fromJSDate(selectedDay).startOf("day");
                              setFieldValue("since", value);

                              if (values.since <= DateTime.fromISO(props.onDuty.since)) {
                                setFieldValue("prependingCrewMemberId", null);
                              }
                            }}
                          />
                          <DayPickerInput
                            component={TextInput}
                            inputProps={{
                              label: "End date",
                              paddingBottom: true
                            }}
                            value={values.to == null ? today.endOf("day").toJSDate() : values.to.toJSDate()}
                            format="LLL dd yyyy"
                            formatDate={(date, format) => DateTime.fromJSDate(date).toFormat(format)}
                            dayPickerProps={{
                              disabledDays: {
                                before: today.toJSDate(),
                                after: today
                                  .plus({ years: 100 })
                                  .endOf("year")
                                  .toJSDate()
                              }
                            }}
                            onDayChange={selectedDay => {
                              const value = DateTime.fromJSDate(selectedDay).endOf("day");
                              setFieldValue("to", value);

                              if (values.to >= DateTime.fromISO(props.onDuty.to)) {
                                setFieldValue("appendingCrewMemberId", null);
                              }
                            }}
                          />
                        </div>
                        {values.since > DateTime.fromISO(props.onDuty.since) && (
                          <React.Fragment>
                            <label>
                              From <strong>{DateTime.fromISO(props.onDuty.since).toFormat("LLL dd yyyy")}</strong> to{" "}
                              <strong>{values.since.minus({ days: 1 }).toFormat("LLL dd yyyy")}</strong>
                            </label>
                            <Select
                              options={
                                (data.crewMembers &&
                                  data.crewMembers.edges.map(crewMember => {
                                    return {
                                      key: crewMember.node.id,
                                      value: crewMember.node.id,
                                      text: crewMember.node.name || ""
                                    };
                                  })) ||
                                []
                              }
                              value={values.prependingCrewMemberId || ""}
                              label="Prepending crew member"
                              onChange={(event: any, data: { value: string }) => {
                                setFieldValue("prependingCrewMemberId", data.value);
                              }}
                              error={!!errors.prependingCrewMemberId}
                              paddingBottom
                              fullWidth
                            />
                          </React.Fragment>
                        )}
                        {values.to < DateTime.fromISO(props.onDuty.to) && (
                          <React.Fragment>
                            <label>
                              From <strong>{values.to.plus({ days: 1 }).toFormat("LLL dd yyyy")}</strong> to{" "}
                              <strong>{DateTime.fromISO(props.onDuty.to).toFormat("LLL dd yyyy")}</strong>
                            </label>
                          <Select
                            options={
                              (data.crewMembers &&
                                data.crewMembers.edges.map(crewMember => {
                                  return {
                                    key: crewMember.node.id,
                                    value: crewMember.node.id,
                                    text: crewMember.node.name || ""
                                  };
                                })) ||
                              []
                            }
                            value={values.appendingCrewMemberId || ""}
                            label="Appending crew member"
                            onChange={(event: any, data: { value: string }) => {
                              setFieldValue("appendingCrewMemberId", data.value);
                            }}
                            error={!!errors.appendingCrewMemberId}
                            paddingBottom
                            fullWidth
                          />
                          </React.Fragment>
                        )}
                        {mutationError && <Error title={mutationError.name} message={mutationError.message} closable />}
                      </div>
                    );
                  }}
                </OnDutyFormValuesQuery>
              </ModalContent>
              <ModalFooter>
                <DeleteOnDutyMutation
                  mutation={DELETE_ON_DUTY}
                  variables={{ delete: [props.onDuty.id] }}
                  onCompleted={props.handleClose}
                  onError={() => setSubmitting(false)}
                  refetchQueries={props.refetchQueries}
                  awaitRefetchQueries
                >
                  {(deleteOnDutyMutationFn, { error, loading }) => (
                    <DeleteLink
                      link="Delete event"
                      confirm="Delete event?"
                      activeCallback={deleteActive => setSubmitDisabled(deleteActive)}
                      action={() => {
                        deleteOnDutyMutationFn();
                        setSubmitting(true);
                      }}
                    />
                  )}
                </DeleteOnDutyMutation>
                <Button type="submit" variant="primary" loading={isSubmitting} disabled={submitDisabled || values.since > values.to}>
                  Update event
                </Button>
              </ModalFooter>
            </Modal>
          )}
        />
      )}
    </UpdateOnDutyMutation>
  );
};

export default withStyles(styles)(UpdateCrewOnDutyModal);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";
import Button from "../Button";
import Heading from "../Heading";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 80,
    padding: "20px 32px",
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.mediumGrey
  },
  heading: {
    marginBottom: 0
  }
});

interface Props extends WithStyles<typeof styles> {
  title?: string;
  actionButton?: React.ReactElement<typeof Button>;
  className?: string;
}

class ModalHeader extends React.PureComponent<Props> {
  render() {
    const { classes, title, actionButton, className } = this.props;

    return (
      <div className={clsx(classes.root, className)}>
        <Heading variant="h1" className={classes.heading}>
          {title}
        </Heading>
        {actionButton}
      </div>
    );
  }
}

export default withStyles(styles)(ModalHeader);

import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";

import { getOperators_operators_edges_node } from "./__generated__/getOperators";
import { updateOperator, updateOperatorVariables } from "./__generated__/updateOperator";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../../components/Modal";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Error from "../../components/Error";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  codeIcao: Yup.string().required("ICAO is required")
});

const UPDATE_OPERATOR = gql`
    mutation updateOperator($id: UUID!, $name: String!, $codeIcao: String!) {
        updateOperator(id: $id, data: { name: $name, codeIcao: $codeIcao }) {
            id
        }
    }
`;

interface Props {
  operator: getOperators_operators_edges_node
  handleClose: () => void;
}

const UpdateOperatorModal: React.FunctionComponent<Props> = ({ operator, handleClose }) => {
  return (
    <Mutation<updateOperator, updateOperatorVariables> mutation={UPDATE_OPERATOR} refetchQueries={["getOperators"]} awaitRefetchQueries>
      {(updateOperatorFn, { error: mutationError }) => (
        <Formik
          initialValues={{
            name: operator.name || "",
            codeIcao: operator.codeIcao || ""
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              // @ts-ignore
              await updateOperatorFn({
                variables: {
                  id: operator.id,
                  ...values
                }
              });
              handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, error }) => (
            <Modal component="form" onSubmit={handleSubmit} handleClose={handleClose} closeOnOverlayClick>
              <ModalHeader
                title={"Operator detail"}
                actionButton={
                  <Button onClick={handleClose} variant="secondary" small>
                    Back to list
                  </Button>
                }
              />
              <ModalContent>
                <TextInput
                  name="name"
                  id="name"
                  label={"Name"}
                  value={values.name}
                  onChange={handleChange}
                  error={!!(touched.name && errors.name)}
                  fullWidth
                  paddingBottom
                />
                <TextInput
                  name="codeIcao"
                  id="codeIcao"
                  label={"ICAO"}
                  value={values.codeIcao}
                  onChange={handleChange}
                  error={!!(touched.codeIcao && errors.codeIcao)}
                  fullWidth
                  paddingBottom
                />
                {error && <Error title={error.name} message={error.message} closable={false} />}
                {mutationError && <Error title={mutationError.name} message={mutationError.message} closable={false} />}
              </ModalContent>
              <ModalFooter>
                <Button type="submit" variant="primary" loading={isSubmitting}>
                  {"Save"}
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Formik>
      )}
    </Mutation>
  );
};

export default UpdateOperatorModal;

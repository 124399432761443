import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import withStyles, { WithStyles } from "react-jss";
import GoogleMapReact from "google-map-react";
import LocationOn from "@material-ui/icons/LocationOn";

import { Modal, ModalContent, ModalHeader } from "../../components/Modal";
import Button from "../../components/Button";
import BasicTable from "../../components/BasicTable";
import Heading from "../../components/Heading";

import { AirportInfo_edges_node } from "./__generated__/AirportInfo";
import AirportAircraftCategoryFeeTable from "./AirportAircraftCategoryFeeTable";
import AirportHandlingList from "./AirportHandlingList";

const ICON_WIDTH = 14;
const ICON_HEIGHT = 20;

const styles = {
  tableMapWrapper: {
    display: "flex"
  },
  mapWrapper: {
    flexBasis: "100%"
  },
  icon: {
    position: "absolute",
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
    left: -ICON_WIDTH / 2,
    top: -ICON_HEIGHT
  }
};

interface Props extends WithStyles<typeof styles> {
  airport: AirportInfo_edges_node;
  handleClose: (event?: any) => void;
}

interface LocationIconProps extends WithStyles<typeof styles> {
  lat: number;
  lng: number;
}

const LocationIcon: React.FunctionComponent<LocationIconProps> = ({ classes }) => {
  return <LocationOn className={classes.icon} />;
};

const AirportDetailModal: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { airport, classes } = props;

  if (!airport) return null;

  return (
    <Modal handleClose={props.handleClose} closeOnOverlayClick size={"large"}>
      <ModalHeader
        title={t("airportDetail.title")}
        actionButton={
          <Button onClick={props.handleClose} variant="secondary" small>
            Back to list
          </Button>
        }
      />
      <ModalContent>
        <Heading variant={"h2"}>{airport.name}</Heading>
        <div className={classes.tableMapWrapper}>
          <BasicTable>
            <tbody>
              <tr>
                <td>ICAO code</td>
                <td align="right">{airport.codeIcao}</td>
              </tr>
              <tr>
                <td>IATA code</td>
                <td align="right">{airport.codeIata}</td>
              </tr>
              <tr>
                <td>City</td>
                <td align="right">{airport.city}</td>
              </tr>
              {airport.country && (
                <tr>
                  <td>Country</td>
                  <td align="right">{airport.country.name}</td>
                </tr>
              )}
              {airport.gps && airport.gps.latitude && airport.gps.longitude && (
                <tr>
                  <td>GPS</td>
                  <td align="right">
                    {Math.round(airport.gps.latitude * Math.pow(10, 6)) / Math.pow(10, 6)},{" "}
                    {Math.round(airport.gps.longitude * Math.pow(10, 6)) / Math.pow(10, 6)}
                  </td>
                </tr>
              )}
              {airport.timezone && (
                <tr>
                  <td>Timezone</td>
                  <td align="right">{airport.timezone.name}</td>
                </tr>
              )}
              <tr>
                <td>Runway length</td>
                <td align="right">{airport.runwayLength} ft</td>
              </tr>
              {airport.openingTimes && (
                <tr>
                  <td>Opening times</td>
                  <td align="right">
                    {DateTime.fromISO(airport.openingTimes.opening).toFormat("HH:mm")} -{" "}
                    {DateTime.fromISO(airport.openingTimes.closing).toFormat("HH:mm")}
                  </td>
                </tr>
              )}
            </tbody>
          </BasicTable>

          {airport.gps && (
            <div className={classes.mapWrapper}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
                }}
                defaultCenter={{
                  lat: airport.gps.latitude || 50.0823494,
                  lng: airport.gps.longitude || 14.4220517
                }}
                defaultZoom={4}
              >
                <LocationIcon
                  classes={classes}
                  lat={airport.gps.latitude || 50.0823494}
                  lng={airport.gps.longitude || 14.4220517}
                />
              </GoogleMapReact>
            </div>
          )}
        </div>

        <AirportAircraftCategoryFeeTable fees={airport.landingFees} />

        <hr />

        <AirportHandlingList handlings={airport.handlings} />
      </ModalContent>
    </Modal>
  );
};

export default withStyles(styles)(AirportDetailModal);

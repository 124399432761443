import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import { Query } from "react-apollo";
import { DateTime, Settings } from "luxon";

import PageMeta from "../../parts/PageMeta";
import LegList from "./LegList";
import Layout from "../../parts/Layout";
import { LegInfoFragment } from "./legs.fragment";
import { getLegs, getLegsVariables } from "./__generated__/getLegs";
import FilterForm, { LEG_FILTER_STATUS } from "./FilterForm";
import { LegStatus } from "../../__generated__/globalTypes";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const GET_LEGS = gql`
  query getLegs($since: DateTime!, $to: DateTime!, $first: Int!, $after: String, $filter: LegFilter) {
    legs(since: $since, to: $to, first: $first, after: $after, filter: $filter) {
      ...LegInfo
    }
  }
  ${LegInfoFragment}
`;

class LegsQuery extends Query<getLegs, getLegsVariables> {}

interface Props {}

const Operations: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [dateString, setDate] = React.useState<string>(DateTime.utc().toISODate());
  const [legFilterStatus, setLegFilterStatus] = React.useState<LEG_FILTER_STATUS>("confirmed" as LEG_FILTER_STATUS);
  const date = DateTime.fromISO(dateString);

  let statusFilter: LegStatus[];

  if (legFilterStatus === "confirmed") {
    statusFilter = [LegStatus.CONFIRMED];
  } else if (legFilterStatus === "cancelled") {
    statusFilter = [LegStatus.CANCELED];
  } else {
    statusFilter = [LegStatus.CONFIRMED, LegStatus.CANCELED];
  }

  return (
    <Layout title={t("operations.title")}>
      <PageMeta title={t("operations.pageTitle")} />
      <FilterForm
        date={date}
        legFilterStatus={legFilterStatus}
        onSubmit={({ date, legFilterStatus }) => {
          setDate(date.toISODate());
          setLegFilterStatus(legFilterStatus);
        }}
      >
        <LegsQuery
          query={GET_LEGS}
          variables={{
            first: 500,
            since: date.startOf("day").toISO(),
            to: date.endOf("day").toISO(),
            filter: {
              status: statusFilter
            }
          }}
        >
          {({ data, refetch, loading, error }) => (
            <LegList
              legs={data && data.legs ? data.legs.edges : null}
              refetch={refetch}
              loading={loading}
              error={error}
            />
          )}
        </LegsQuery>
      </FilterForm>
    </Layout>
  );
};

export default Operations;

import * as React from "react";
import { Redirect } from "react-router-dom";
import withStyles, { WithStyles } from "react-jss";

import logoWhite from "../images/strafos-logo-white.svg";
import Heading from "../components/Heading";
import Typography from "../components/Typography";
import { Theme } from "../theme";
import { HOME_PAGE } from "../routes";
import { LoginMethod } from "../types/functions";
import { AuthConsumer } from "./AuthContext";

const styles = (theme: Theme) => ({
  "@global": {
    "#root": {
      height: "100%",
      minHeight: "100%"
    }
  },
  root: {
    display: "flex",
    alignItems: "stretch",
    height: "100%",
    minHeight: "100%"
  },
  left: {
    width: "50%",
    padding: "100px 5%",
    backgroundImage: "url('/images/strafos-basic-page-bg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  logoWhite: {
    marginBottom: 50
  },
  leftHeading: {
    marginBottom: 16,
    fontSize: "42px",
    lineHeight: "42px",
    fontWeight: 700,
    color: theme.colors.white
  },
  leftSubHeading: {
    fontSize: "24px",
    lineHeight: "42px",
    fontWeight: 400,
    color: theme.colors.white
  },
  right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%"
  },
  rightContent: {
    width: "65%",
    maxWidth: 420
  },
  heading: {
    fontSize: "42px",
    lineHeight: "42px"
  },
  subHeading: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    marginBottom: 52,
    color: theme.colors.grey
  }
});

interface ChildrenArgs {
  isAuth: boolean;
  login: LoginMethod;
}

interface Props extends WithStyles<typeof styles> {
  title: string;
  subTitle?: string;
  children: (args: ChildrenArgs) => React.ReactNode;
}

class BasicPage extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <AuthConsumer>
        {({ isAuth, login }) => {
          if (isAuth) {
            return <Redirect to={HOME_PAGE} />;
          }

          return (
            <div className={classes.root}>
              <div className={classes.left}>
                <img src={logoWhite} className={classes.logoWhite} alt="" />
                <Typography variant="primary" component="div" className={classes.leftHeading}>
                  Welcome to Strafos.
                </Typography>
                <Typography variant="primary" component="p" className={classes.leftSubHeading}>
                  Smart solution for private jet
                  <br /> operators and brokers.
                </Typography>
              </div>
              <div className={classes.right}>
                <div className={classes.rightContent}>
                  <Heading variant="h1" className={this.props.classes.heading}>
                    {this.props.title}
                  </Heading>
                  <Typography variant="primary" component="p" className={classes.subHeading}>
                    {this.props.subTitle}
                  </Typography>
                  {this.props.children({ isAuth, login })}
                </div>
              </div>
            </div>
          );
        }}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles)(BasicPage);

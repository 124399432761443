import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../../theme";
import { DateTime } from "luxon";
import { OnDutyInfo } from "./__generated__/OnDutyInfo";
import Tooltip from "../../components/Tooltip";
import UpdateCrewOnDutyModal from "./UpdateCrewOnDutyModal";

const styles = (theme: Theme) => ({
  root: {
    position: "absolute",
    padding: 8,
    textTransform: "uppercase",
    color: theme.colors.white,
    backgroundColor: theme.colors.green,
    fontSize: 12,
    fontWeight: 700,
    fontFamily: theme.typography.primaryFontFamily
  },
  clickable: {
    cursor: "pointer"
  }
});

interface Props extends WithStyles<typeof styles> {
  id: string;
  onDuty: OnDutyInfo;
}

interface State {
  style: {
    display: string;
    left: number;
    top: number;
    width: number;
    height: number;
  };
  updateModalOpen: boolean;
}

class OnDutyCrewCalendarItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      style: {
        display: "none",
        left: 0,
        top: 0,
        width: 0,
        height: 0
      },
      updateModalOpen: false
    };
  }

  today: DateTime = DateTime.utc().startOf("day");

  componentDidMount() {
    const calendarCellStart = document.getElementById(
      `${this.props.id}-${DateTime.fromISO(this.props.onDuty.since).toISODate()}`
    );
    const calendarCellEnd = document.getElementById(
      `${this.props.id}-${DateTime.fromISO(this.props.onDuty.to).toISODate()}`
    );

    if (calendarCellStart && calendarCellEnd) {
      this.setState({
        style: {
          display: "block",
          left: calendarCellStart.offsetLeft,
          top: calendarCellStart.offsetTop,
          width: calendarCellEnd.offsetLeft + calendarCellEnd.offsetWidth - calendarCellStart.offsetLeft - 1,
          height: calendarCellStart.offsetHeight - 1
        }
      });
    }
  }

  handleOpenUpdateModal = () => this.setState({ updateModalOpen: true });
  handleCloseUpdateModal = () => this.setState({ updateModalOpen: false });

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip
          key={this.props.onDuty.id}
          placement="top"
          overlay={this.props.onDuty.aircraft && this.props.onDuty.aircraft.name}
        >
          <div
            className={clsx(
              classes.root,
              this.today.valueOf() <= DateTime.fromISO(this.props.onDuty.to).valueOf() && classes.clickable
            )}
            style={{ ...this.state.style }}
            onClick={() => {
              if (this.today.valueOf() <= DateTime.fromISO(this.props.onDuty.to).valueOf()) {
                this.handleOpenUpdateModal();
              }
            }}
          >
            <span>Duty</span>
          </div>
        </Tooltip>
        {this.state.updateModalOpen && (
          <UpdateCrewOnDutyModal
            handleClose={this.handleCloseUpdateModal}
            onDuty={this.props.onDuty}
            refetchQueries={["getScheduleCrew"]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OnDutyCrewCalendarItem);

import React, { Component } from "react";
import { DateTime, Settings } from "luxon";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";

import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import Radio from "../../components/Radio";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    marginBottom: 24,
    backgroundColor: theme.colors.white,
    borderRadius: 6,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)"
  },
  field: {
    width: 192,
    padding: 24,
    borderRight: "1px solid",
    borderRightColor: theme.colors.lightGrey
  },
  radioGroup: {
    flex: 1,
    padding: 24
  },
  radio: {
    width: "auto",
    marginRight: "16px",
    paddingLeft: 24,
    paddingRight: 24
  },
  button: {
    margin: 16,
    alignSelf: "center"
  },
  label: {
    paddingBottom: 8,
    fontFamily: theme.typography.primaryFontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "16px",
    color: theme.colors.darkBlue
  }
});

const validate = (date: string) => {
  const now = DateTime.utc();

  return {
    date: DateTime.fromISO(date).valueOf() < now.startOf("day").valueOf()
  };
};

export type LEG_FILTER_STATUS = "confirmed" | "cancelled" | "all";

interface SubmitData {
  date: DateTime;
  legFilterStatus: LEG_FILTER_STATUS;
}

interface Props extends WithStyles<typeof styles> {
  date: DateTime;
  legFilterStatus: LEG_FILTER_STATUS;
  onSubmit: (values: SubmitData) => void;
  children: React.ReactNode;
}

interface State {
  date: string;
  selectedRadio: LEG_FILTER_STATUS;
}

class FilterForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      date: this.props.date.toISODate(),
      selectedRadio: this.props.legFilterStatus
    };
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = validate(this.state.date);
    // @ts-ignore
    const isValid = !Object.keys(errors).some(field => errors[field]);

    if (isValid) {
      const values: SubmitData = {
        date: DateTime.fromISO(this.state.date),
        legFilterStatus: this.state.selectedRadio
      };
      this.props.onSubmit(values);
    }
  };

  render() {
    const errors = validate(this.state.date);
    const { classes } = this.props;
    // @ts-ignore
    const isValid = !Object.keys(errors).some(field => errors[field]);

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit} className={classes.root}>
          <div className={classes.field}>
            <div className={classes.label}>Date</div>
            <DatePicker
              defaultDate={this.props.date}
              onChange={(date: string) => {
                this.setState({ date: date });
              }}
            />
          </div>
          <div className={classes.radioGroup}>
            <Radio
              className={classes.radio}
              checked={this.state.selectedRadio === "confirmed"}
              onChange={() => this.setState({ selectedRadio: "confirmed" })}
            >
              Confirmed
            </Radio>
            <Radio
              className={classes.radio}
              checked={this.state.selectedRadio === "cancelled"}
              onChange={() => this.setState({ selectedRadio: "cancelled" })}
            >
              Cancelled
            </Radio>
            <Radio
              className={classes.radio}
              checked={this.state.selectedRadio === "all"}
              onChange={() => this.setState({ selectedRadio: "all" })}
            >
              All
            </Radio>
          </div>
          <Button className={classes.button} type="submit" variant="primary" disabled={!isValid}>
            Filter
          </Button>
        </form>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FilterForm);

import React from "react";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { Query } from "react-apollo";

import Button from "../../components/Button";
import PageMeta from "../../parts/PageMeta";
import AircraftList from "./AircraftList";
import AddAircraftModal from "./AddAircraftModal";
import Layout from "../../parts/Layout";
import { AircraftInfoFragment } from "./aircrafts.fragment";
import { getAircrafts, getAircraftsVariables } from "./__generated__/getAircrafts";

export const GET_AIRCRAFTS = gql`
  query getAircrafts($first: Int!, $after: String) {
    aircrafts(first: $first, after: $after) {
      ...AircraftInfo
    }
  }
  ${AircraftInfoFragment}
`;

class FleetQuery extends Query<getAircrafts, getAircraftsVariables> {}

interface Props {}

const Fleet: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalState] = React.useState<boolean>(false);

  const openModal = () => setModalState(true);
  const closeModal = () => setModalState(false);

  return (
    <FleetQuery query={GET_AIRCRAFTS} variables={{ first: 100 }}>
      {({ data, refetch, loading }) => (
        <Layout
          title={t("fleet.title")}
          actionButton={
            <Button variant="primary" onClick={openModal} small>
              {t("fleet.addButton")}
            </Button>
          }
        >
          <PageMeta title={t("fleet.pageTitle")} />
          <AircraftList
            aircrafts={data && data.aircrafts ? data.aircrafts.edges : null}
            refetch={refetch}
            loading={loading}
          />
          {modalOpen && <AddAircraftModal handleClose={closeModal} />}
        </Layout>
      )}
    </FleetQuery>
  );
};

export default Fleet;

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import ClearIcon from "@material-ui/icons/Clear";

import Button from "../../components/Button";
import Layout from "../../parts/Layout";
import PageMeta from "../../parts/PageMeta";
import AirportList from "./AirportList";
import { AirportInfoFragment } from "./airports.fragment";
import TextInput from "../../components/TextInput";
import { Theme } from "../../theme";

import { airports, airportsVariables } from "./__generated__/airports";

export const GET_AIRPORTS = gql`
  query airports($filter: AirportFilter!, $first: Int!, $after: String) {
    airports(filter: $filter, first: $first, after: $after) {
      ...AirportInfo
    }
  }
  ${AirportInfoFragment}
`;

const styles = (theme: Theme) => ({
  searchForm: {
    display: "flex"
  },
  searchInput: {
    marginRight: 16
  },
  clearIcon: {
    color: theme.colors.grey,
    transitionDuration: "0.2s",
    transform: "translateY(-3px)",
    cursor: "pointer",
    "&:hover": {
      color: theme.colors.blue
    }
  }
});

class AirportsQuery extends Query<airports, airportsVariables> {}

interface Props extends WithStyles<typeof styles> {}

const Airports: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { classes } = props;

  const [query, setQuery] = React.useState<string>("");
  const [usedQuery, setUsedQuery] = React.useState<string>("");

  return (
    <AirportsQuery query={GET_AIRPORTS} variables={{ filter: { query: usedQuery }, first: 100 }}>
      {({ data, refetch, loading }) => (
        <Layout
          title={t("airports.title")}
          actionButton={
            <form
              className={classes.searchForm}
              onSubmit={event => {
                event.preventDefault();
                setUsedQuery(query);
              }}
            >
              <TextInput
                className={classes.searchInput}
                label="Search airports"
                value={query}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQuery(event.target.value);
                }}
                labelAfter={
                  <ClearIcon
                    className={classes.clearIcon}
                    onClick={() => {
                      setQuery("");
                      setUsedQuery("");
                    }}
                  />
                }
              />
              <Button type="submit" variant="primary" loading={loading}>
                Search
              </Button>
            </form>
          }
        >
          <PageMeta title={t("airports.pageTitle")} />
          <AirportList
            airports={data && data.airports ? data.airports.edges : null}
            refetch={refetch}
            loading={loading}
          />
        </Layout>
      )}
    </AirportsQuery>
  );
};

export default withStyles(styles)(Airports);

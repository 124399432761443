import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  wrapper: {
    display: "inline-block",
    position: "relative"
  },
  label: {
    position: "absolute",
    top: "20px",
    left: "20px",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.grey,
    transformOrigin: "left top",
    transition: "transform 0.1s, color 0.1s",
    pointerEvents: "none"
  },
  labelShrinked: {
    transform: "scale(0.8) translateY(-10px)"
  },
  labelFocus: {
    color: theme.colors.blue
  },
  root: {
    boxSizing: "border-box",
    padding: "24px 20px 8px",
    border: "1px solid",
    backgroundColor: theme.colors.white,
    borderColor: "#cdcdcd",
    borderRadius: 4,
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.black,
    fontSize: "16px",
    lineHeight: "16px",
    transition: "border-color 0.1s",
    "&[type='number']": {
      "-moz-appearance": "textfield"
    },
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none"
    },
    "&:hover, &:focus": {
      borderColor: theme.colors.blue,
      outline: "none"
    },
    "&::placeholder": {
      opacity: 0,
      transition: "opacity 0.1s"
    },
    "&:focus::placeholder": {
      opacity: 1
    }
  },
  fullWidth: {
    width: "100%"
  },
  disabled: {
    backgroundColor: theme.colors.mediumGrey,
    borderColor: theme.colors.mediumGrey,
    "&:hover, &:focus": {
      borderColor: theme.colors.mediumGrey
    }
  },
  error: {
    borderColor: theme.colors.red
  },
  paddingBottom: {
    paddingBottom: 20
  },
  afterLabel: {
    position: "absolute",
    top: 20,
    right: 20,
    fontSize: 16,
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.black
  }
});

interface Props extends React.HTMLProps<HTMLInputElement>, WithStyles<typeof styles> {
  fullWidth?: boolean;
  error?: boolean;
  paddingBottom?: boolean;
  labelAfter?: string | React.ReactElement;
}

interface State {
  hasFocus: boolean;
}

class TextInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasFocus: false };
  }

  handleFocus = (event: any) => {
    if (this.props.onFocus) this.props.onFocus(event);

    this.setState({ hasFocus: true });
  };

  handleBlur = (event: any) => {
    if (this.props.onBlur) this.props.onBlur(event);

    this.setState({ hasFocus: false });
  };

  render() {
    const { fullWidth, classes, className, error, disabled, label, paddingBottom, labelAfter, ...props } = this.props;

    return (
      <div
        className={clsx(
          classes.wrapper,
          fullWidth && classes.fullWidth,
          paddingBottom && classes.paddingBottom,
          className
        )}
      >
        <input
          className={clsx(
            classes.root,
            fullWidth && classes.fullWidth,
            error && classes.error,
            disabled && classes.disabled
          )}
          disabled={disabled}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...props}
        />
        <label
          className={clsx(
            classes.label,
            (this.props.value || this.props.value === 0 || this.state.hasFocus) && classes.labelShrinked,
            this.state.hasFocus && classes.labelFocus
          )}
        >
          {label}
        </label>
        {labelAfter && <span className={classes.afterLabel}>{labelAfter}</span>}
      </div>
    );
  }
}

export default withStyles(styles)(TextInput);

import React from "react";
import ReactDOM from "react-dom";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";
import KeyBoardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Theme } from "../theme";

const selectRoot = document.createElement("div");
selectRoot.setAttribute("id", "select-root");
document.body.appendChild(selectRoot);

const styles = (theme: Theme) => ({
  root: {
    position: "relative",
    display: "inline-flex",
    verticalAlign: "bottom",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 211,
    padding: "14px 20px",
    border: "1px solid",
    backgroundColor: theme.colors.white,
    borderColor: "#cdcdcd",
    borderRadius: 4,
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.grey,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    transition: "border-color 0.1s",
    "&:hover, &:focus": {
      borderColor: theme.colors.blue,
      outline: "none"
    }
  },
  label: {
    position: "absolute",
    top: "20px",
    left: "20px",
    transformOrigin: "left top",
    color: theme.colors.grey,
    transition: "transform 0.1s, color 0.1s"
  },
  labelSelected: {
    transform: "scale(0.8) translateY(-10px)"
  },
  value: {
    paddingTop: 10,
    transform: "translateY(2px)"
  },
  selected: {
    color: theme.colors.black
  },
  fullWidth: {
    width: "100%"
  },
  open: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  iconWrapper: {
    textAlign: "right",
    color: theme.colors.grey
  },
  disabled: {
    backgroundColor: theme.colors.mediumGrey,
    borderColor: theme.colors.mediumGrey,
    "&:hover, &:focus": {
      borderColor: theme.colors.mediumGrey
    }
  },
  error: {
    borderColor: theme.colors.red
  },
  items: {
    position: "absolute",
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    border: "1px solid",
    borderColor: theme.colors.blue,
    backgroundColor: theme.colors.white
  },
  item: {
    padding: "16px 24px",
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: theme.colors.black,
    transition: "background-color 0.2s",
    cursor: "pointer",
    "&:hover, &:focus": {
      fontWeight: 700,
      backgroundColor: "rgba(0, 66, 255, 0.08)"
    }
  },
  paddingBottom: {
    marginBottom: 20
  }
});

interface SelectOption {
  key: string;
  value: string;
  text: string;
}

interface Props extends WithStyles<typeof styles> {
  className?: string;
  label: string;
  value: string | null;
  options: ReadonlyArray<SelectOption>;
  onChange?: (event: any, data: SelectOption) => void;
  error?: boolean;
  paddingBottom?: boolean;
  fullWidth?: boolean;
}

interface State {
  open: boolean;
}

class Select extends React.PureComponent<Props, State> {
  elementRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      open: false
    };
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClose);
  }

  handleClose = () => {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.handleClose);
    });
  };

  handleOpen = () => {
    this.setState({ open: true }, () => {
      document.addEventListener("click", this.handleClose);
    });
  };

  handleChange = (data: SelectOption) => (event: any) => {
    if (this.props.onChange) this.props.onChange(event, data);
  };

  getStyle = () => {
    const elementDimensions = this.elementRef.current!.getBoundingClientRect();

    return {
      top: elementDimensions.top + elementDimensions.height - 1,
      left: elementDimensions.left,
      width: elementDimensions.width
    };
  };

  render() {
    const { classes, className, label, value, error, paddingBottom, options } = this.props;

    const selected = value && options.find(option => option.value === value);

    return (
      <div
        className={clsx(
          classes.root,
          selected && classes.selected,
          error && classes.error,
          paddingBottom && classes.paddingBottom,
          this.state.open && classes.open,
          this.props.fullWidth && classes.fullWidth,
          className
        )}
        onClick={this.handleOpen}
        tabIndex={0}
        ref={this.elementRef}
      >
        <div className={clsx(classes.label, selected && classes.labelSelected)}>{label}</div>
        <div className={classes.value}>{selected && selected.text}</div>
        <div className={classes.iconWrapper}>
          <KeyBoardArrowDownIcon />
        </div>
        {this.state.open &&
          ReactDOM.createPortal(
            <div className={classes.items} style={this.getStyle()}>
              {options.map(option => (
                <div key={option.key} className={classes.item} onClick={this.handleChange(option)}>
                  {option.text}
                </div>
              ))}
            </div>,
            selectRoot
          )}
      </div>
    );
  }
}

export default withStyles(styles)(Select);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";
import { DateTime } from "luxon";
import FlightIcon from "@material-ui/icons/Flight";

import Typography from "../components/Typography";
import { Theme } from "../theme";
import { LegType } from "../__generated__/globalTypes";
import { search_proposals_edges_node, search_proposals_edges_node_legs } from "../pages/search/__generated__/search";
import {
  getFlights_flights_edges_node,
  getFlights_flights_edges_node_legs
} from "../pages/sales/__generated__/getFlights";
import { formatDuration } from "../utils";

const styles = (theme: Theme) => ({
  root: {
    margin: "-28px -32px 0",
    padding: "28px 32px",
    backgroundColor: "rgba(0, 66, 255, 0.08);"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  evenChildren: {
    "& > *": {
      flexBasis: "30%",
      textAlign: "center",
      "&:first-child": {
        textAlign: "left"
      },
      "&:last-child": {
        textAlign: "right"
      }
    }
  },
  marginBottom8: {
    marginBottom: 8
  },
  marginBottom24: {
    marginBottom: 24
  },
  line: {
    flex: 1,
    margin: " 0 20px",
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.blue,
    opacity: 0.2
  },
  rotatedIcon: {
    color: theme.colors.blue,
    transform: "rotate(90deg)"
  }
});

interface Props extends WithStyles<typeof styles> {
  flight: search_proposals_edges_node | getFlights_flights_edges_node;
}

class FlightInfo extends React.Component<Props> {
  getFlightDuration = (
    occupiedLegs: search_proposals_edges_node_legs[] | getFlights_flights_edges_node_legs[]
  ): string => {
    const accumulatedDuration: number = occupiedLegs.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.duration || 0);
    }, 0);

    return formatDuration(accumulatedDuration)
  };

  getFlightPassengers = (
    occupiedLegs: search_proposals_edges_node_legs[] | getFlights_flights_edges_node_legs[]
  ): number => {
    return occupiedLegs.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.passengerCount || 0);
    }, 0);
  };

  render() {
    const { classes, flight } = this.props;

    const occupiedLegs: search_proposals_edges_node_legs[] | getFlights_flights_edges_node_legs[] = flight.legs
      ? flight.legs.filter(leg => leg.type === LegType.OCCUPIED)
      : [];

    const departureLeg: search_proposals_edges_node_legs | getFlights_flights_edges_node_legs | undefined =
      occupiedLegs[0];
    const arrivalLeg: search_proposals_edges_node_legs | getFlights_flights_edges_node_legs | undefined =
      occupiedLegs[occupiedLegs.length - 1];

    return (
      <div className={classes.root}>
        <div className={clsx(classes.flex, classes.evenChildren, classes.marginBottom8)}>
          <Typography fontSize={14} color="grey" variant="primary">
            AIRPORT FROM
          </Typography>
          <FlightIcon className={classes.rotatedIcon} />
          <Typography fontSize={14} color="grey" variant="primary">
            AIRPORT TO
          </Typography>
        </div>
        <div className={clsx(classes.flex, classes.marginBottom24)}>
          <Typography fontSize={24} color="black" fontWeight={700} variant="primary">
            {departureLeg && departureLeg.from && departureLeg.from.codeIcao}
          </Typography>
          <div className={classes.line} />
          <Typography fontSize={16} color="blue" fontWeight={700} variant="primary">
            {this.getFlightDuration(occupiedLegs)}
          </Typography>
          <div className={classes.line} />
          <Typography fontSize={24} color="black" fontWeight={700} variant="primary">
            {arrivalLeg && arrivalLeg.to && arrivalLeg.to.codeIcao}
          </Typography>
        </div>
        <div className={clsx(classes.flex, classes.evenChildren, classes.marginBottom8)}>
          <Typography fontSize={14} color="grey" variant="primary">
            DEPARTURE
          </Typography>
          <Typography fontSize={12} color="grey" variant="primary">
            PAX NR
          </Typography>
          <Typography fontSize={14} color="grey" variant="primary">
            ARRIVAL
          </Typography>
        </div>
        <div className={clsx(classes.flex, classes.evenChildren)}>
          <Typography fontSize={20} color="black" fontWeight={700} variant="primary">
            {departureLeg && DateTime.fromISO(departureLeg.takeOffTime).toFormat("dd MMM yyyy HH:mm")}
          </Typography>
          <Typography fontSize={16} color="black" fontWeight={700} variant="primary">
            {this.getFlightPassengers(occupiedLegs)} PAX
          </Typography>
          <Typography fontSize={20} color="black" fontWeight={700} variant="primary">
            {arrivalLeg && DateTime.fromISO(arrivalLeg.landingTime).toFormat("dd MMM yyyy HH:mm")}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FlightInfo);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import { Link as RouterLink, LinkProps } from "react-router-dom";

import Typography from "./Typography";
import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    fontSize: "inherit",
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.colors.blue,
    textDecoration: "underline",
    transition: "color 0.1s",
    "&:hover, &:focus": {
      color: theme.colors.darkBlue,
      textDecoration: "none"
    }
  }
});

interface Props extends LinkProps, WithStyles<typeof styles> {}

class Link extends React.PureComponent<Props> {
  render() {
    const { classes, className, children, color, ...props } = this.props;

    return (
      <Typography className={clsx(classes.root, className)} component={RouterLink} {...props}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(Link);

import React, { Component } from "react";
import gql from "graphql-tag";
import Layout from "../parts/Layout";
import Loader from "../components/Loader";
import AircraftMap from "../components/AircraftMap";
import PageMeta from "../parts/PageMeta";
import { Query } from "react-apollo";

import { getAircraftsForMap, getAircraftsForMapVariables } from "./__generated__/getAircraftsForMap";

export const GET_AIRCRAFTS_FOR_MAP = gql`
  query getAircraftsForMap($first: Int!) {
    aircrafts(first: $first) {
      edges {
        node {
          id
          name
          tailRegistration
          gps {
            id
            latitude
            longitude
          }
        }
      }
    }
  }
`;

class AircraftsForMapQuery extends Query<getAircraftsForMap, getAircraftsForMapVariables> {}

class Home extends Component {
  render() {
    return (
      <Layout title="Dashboard">
        <PageMeta title="Dashboard" />
        <AircraftsForMapQuery query={GET_AIRCRAFTS_FOR_MAP} variables={{ first: 100 }} pollInterval={60 * 1000}>
          {({ data, loading }) => {
            if (loading) {
              return <Loader />;
            }

            const aircrafts = data != null && data.aircrafts != null ? data.aircrafts.edges.map(edge => edge.node) : [];

            return <AircraftMap aircrafts={aircrafts} />;
          }}
        </AircraftsForMapQuery>
      </Layout>
    );
  }
}

export default Home;

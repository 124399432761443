import { ApolloConsumer, Mutation } from "react-apollo";
import gql from "graphql-tag";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PageMeta from "../../parts/PageMeta";

import Message from "../../components/Message";
import { ProposalList, ProposalListItem } from "../../components/ProposalList";
import Loader from "../../components/Loader";
import SearchForm from "./SearchForm";
import Layout from "../../parts/Layout";
import { search, search_proposals_edges_node, searchVariables } from "./__generated__/search";
import { confirmProposal, confirmProposalVariables } from "./__generated__/confirmProposal";
import Button from "../../components/Button";
import FlightDetail from "../../parts/FlightDetail";
import { FlightStatus, ProposalStatus } from "../../__generated__/globalTypes";

export const SEARCH = gql`
  query search(
    $fromAirportId: UUID!
    $toAirportId: UUID!
    $departureTime: DateTime!
    $passengerCount: Int!
    $first: Int!
    $after: String
  ) {
    proposals(
      parameters: {
        fromAirportId: $fromAirportId
        toAirportId: $toAirportId
        departureTime: $departureTime
        passengerCount: $passengerCount
      }
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          passengerCount
          feePerPassenger
          passengersFee
          fixedFee
          durationFee
          airportFee
          handlingFee
          overnightFee
          totalPrice
          status
          aircraft {
            id
            name
            category
            maximumCapacity
          }
          legsToReplace {
            id
            type
            status
            distance
            passengerCount
            takeOffTime
            landingTime
            duration
            feePerHour
            durationFee
            overnightFee
            airportFee
            handlingFee
            totalPrice
            from {
              id
              codeIcao
            }
            to {
              id
              codeIcao
            }
          }
          legs {
            id
            type
            distance
            passengerCount
            takeOffTime
            landingTime
            duration
            feePerHour
            durationFee
            overnightFee
            airportFee
            handlingFee
            totalPrice
            from {
              id
              codeIcao
            }
            to {
              id
              codeIcao
            }
          }
        }
        cursor
      }
    }
  }
`;

export const CONFIRM_FLIGHT = gql`
  mutation confirmProposal($id: UUID!) {
    confirmProposal(id: $id) {
      id
      status
    }
  }
`;

class ConfirmMutation extends Mutation<confirmProposal, confirmProposalVariables> {}

interface Props extends WithTranslation {}

export interface QueryVariables {
  first: number;
  fromAirportId: string | null;
  toAirportId: string | null;
  departureTime: string;
  passengerCount: number;
}

interface State {
  openDetail: search_proposals_edges_node | null;
  queryVariables: QueryVariables | undefined;
  queryResult: search | null;
  loading: boolean;
  error: Error | null;
}

class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDetail: null,
      queryVariables: undefined,
      queryResult: null,
      loading: false,
      error: null
    };
  }

  handleOpenDetail = (proposal: search_proposals_edges_node) => {
    this.setState({ openDetail: proposal });
  };

  handleCloseDetail = () => {
    this.setState({ openDetail: null });
  };

  render() {
    const { t } = this.props;

    return (
      <Layout title={t("search.title")}>
        <PageMeta title={t("search.pageTitle")} />
        <ApolloConsumer>
          {client => (
            <SearchForm
              onSubmit={async variables => {
                this.setState({ error: null, loading: true, queryVariables: variables });

                try {
                  const queryResult = await client.query({
                    query: SEARCH,
                    variables: variables,
                    fetchPolicy: "network-only"
                  });

                  this.setState({ queryResult: queryResult.data });
                } catch (error) {
                  this.setState({ error });
                } finally {
                  this.setState({ loading: false });
                }
              }}
            >
              {() => {
                const { queryResult, loading, error } = this.state;

                if (loading) {
                  return <Loader />;
                }

                if (error) {
                  return <Message title="There was an error performing search, please try again." />;
                }

                if (!queryResult || queryResult.proposals == null) {
                  return <Message title="There is nothing to show." subtitle="Search for flights first." />;
                }

                if (queryResult.proposals.edges.length === 0) {
                  return <Message title="No results found." subtitle="Try to select another date and time." />;
                }

                return (
                  <ProposalList>
                    {queryResult.proposals.edges.map(proposal => (
                      <ProposalListItem
                        key={proposal.node.id}
                        proposal={proposal.node}
                        openDetail={() => {
                          this.handleOpenDetail(proposal.node);
                        }}
                        action={
                          <ConfirmMutation
                            mutation={CONFIRM_FLIGHT}
                            variables={{ id: proposal.node.id }}
                            update={(cache, data) => {
                              const queryData = cache.readQuery<search, searchVariables>({
                                query: SEARCH,
                                variables: this.state.queryVariables
                              });
                              const proposalsCache = queryData && queryData.proposals;

                              const confirmedFlight = data.data && data.data.confirmProposal;

                              // check if those data exists
                              if (!proposalsCache || !confirmedFlight) return;

                              // check if mutation returned confirmed flight
                              if (confirmedFlight.status !== FlightStatus.CONFIRMED) return;

                              const updatedProposalIndex = proposalsCache.edges.findIndex(
                                cachedProposal => cachedProposal.node.id === confirmedFlight.id
                              );

                              // check if object was really found in cache
                              if (updatedProposalIndex === -1) return;

                              proposalsCache.edges[updatedProposalIndex].node.status = ProposalStatus.EXPIRED;

                              cache.writeQuery<search, searchVariables>({
                                query: SEARCH,
                                data: { proposals: proposalsCache }
                              });
                            }}
                          >
                            {(confirmFlight, { error, loading, called }) => {
                              if (proposal.node.status === ProposalStatus.EXPIRED) {
                                return "Confirmed";
                              }

                              return (
                                <Button
                                  variant="secondary"
                                  small
                                  onClick={() => confirmFlight()}
                                  loading={loading}
                                  disabled={!!error && called}
                                >
                                  Confirm
                                </Button>
                              );
                            }}
                          </ConfirmMutation>
                        }
                      />
                    ))}
                  </ProposalList>
                );
              }}
            </SearchForm>
          )}
        </ApolloConsumer>
        {this.state.openDetail && (
          <FlightDetail
            handleClose={this.handleCloseDetail}
            proposal={this.state.openDetail}
            queryVariables={this.state.queryVariables}
          />
        )}
      </Layout>
    );
  }
}

export default withTranslation()(Search);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6
  },
  header: {
    backgroundColor: theme.colors.white,
    display: "flex",
    alignItems: "center",
    padding: 24,
    fontFamily: theme.typography.primaryFontFamily,
    color: theme.colors.grey,
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.lightGrey,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6
  },
  aircraft: {
    flexGrow: 0,
    flexBasis: "30%",
    maxWidth: "30%"
  },
  time: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  position: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  price: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  action: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  arrow: {
    flexGrow: 0,
    flexBasis: "10%",
    maxWidth: "10%"
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
}

const ProposalList: React.FunctionComponent<Props> = ({ classes, className, children }) => (
  <div className={clsx(classes.root, className)}>
    <div className={classes.header}>
      <div className={classes.aircraft}>Aircraft</div>
      <div className={classes.time}>Flight time</div>
      <div className={classes.position}>Position</div>
      <div className={classes.price}>Price</div>
      <div className={classes.action}>Action</div>
      <div className={classes.arrow} />
    </div>
    {children}
  </div>
);

export default withStyles(styles)(ProposalList);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    minWidth: 64,
    boxSizing: "border-box",
    border: "none",
    padding: "20px 40px",
    lineHeight: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: 700,
    borderRadius: 6,
    transition: "background-color 0.1s, color 0.1s, border-color 0.1s",
    "&:focus": {
      outline: "none"
    }
  },
  primary: {
    color: theme.colors.white,
    backgroundColor: theme.colors.blue,
    "&:hover, &:focus": {
      backgroundColor: theme.colors.darkBlue
    }
  },
  secondary: {
    color: theme.colors.blue,
    backgroundColor: theme.colors.white,
    border: "1px solid",
    borderColor: theme.colors.blue,
    "&:hover, &:focus": {
      color: theme.colors.white,
      backgroundColor: theme.colors.darkBlue,
      borderColor: theme.colors.darkBlue
    }
  },
  danger: {
    color: theme.colors.white,
    backgroundColor: theme.colors.red,
    "&:hover, &:focus": {
      backgroundColor: theme.colors.darkRed
    }
  },
  secondaryDanger: {
    color: theme.colors.red,
    backgroundColor: theme.colors.white,
    border: "1px solid",
    borderColor: theme.colors.red,
    "&:hover, &:focus": {
      color: theme.colors.white,
      backgroundColor: theme.colors.darkRed,
      borderColor: theme.colors.darkRed
    }
  },
  small: {
    padding: "12px 20px",
    lineHeight: theme.typography.pxToRem(14),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400
  },
  fullWidth: {
    width: "100%"
  },
  disabled: {
    backgroundColor: theme.colors.mediumGrey,
    color: theme.colors.grey,
    cursor: "default",
    "&:hover, &:focus": {
      backgroundColor: theme.colors.mediumGrey,
      color: theme.colors.grey
    }
  },
  loading: {
    pointerEvents: "none",
    backgroundColor: theme.colors.darkBlue,
    "&$disabled": {
      backgroundColor: theme.colors.mediumGrey,
    }
  },
  loader: {
    display: "inline-block",
    marginRight: 8
  }
});

interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    WithStyles<typeof styles> {
  variant: "primary" | "secondary" | "danger" | "secondaryDanger";
  small?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

interface LoaderProps {
  className?: string;
}

const Loader = React.memo(({ className }: LoaderProps) => (
  <div className={className}>
    <svg width="14" height="14" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="4">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  </div>
));

class Button extends React.PureComponent<Props> {
  render() {
    const { variant, small, fullWidth, disabled, classes, className, children, loading, ...props } = this.props;

    return (
      <button
        className={clsx(
          classes.root,
          classes[variant],
          small && classes.small,
          fullWidth && classes.fullWidth,
          disabled && classes.disabled,
          loading && classes.loading,
          className
        )}
        disabled={disabled}
        {...props}
      >
        {loading && <Loader className={classes.loader} />}
        {children}
      </button>
    );
  }
}

export default withStyles(styles)(Button);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import { DateTime } from "luxon";

import { Theme } from "../theme";
import Switcher from "../components/Switcher";
import Typography from "../components/Typography";
import BasicTable from "../components/BasicTable";
import { LegType } from "../__generated__/globalTypes";
import {
  search_proposals_edges_node_legs,
  search_proposals_edges_node_legsToReplace
} from "../pages/search/__generated__/search";
import { formatDuration } from "../utils";

const styles = (theme: Theme) => ({
  headline: {
    padding: "12px 24px",
    color: theme.colors.black,
    fontWeight: 700
  },
  legSwitcher: {
    float: "right"
  },
  legPricing: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 0 13px 0",
    padding: "12px 26px",
    backgroundColor: theme.colors.lightGrey,
    borderRadius: 2,
    "$occupiedRow + tr &": {
      backgroundColor: "rgba(0, 66, 255, 0.08)"
    }
  },
  legPricingArrow: {
    content: "''",
    display: "block",
    position: "absolute",
    left: "-5px",
    top: "calc(50% - 5px)",
    height: 0,
    width: 0,
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderRight: "5px solid",
    borderRightColor: theme.colors.lightGrey,
    "$occupiedRow &": {
      borderRightColor: "rgba(0, 66, 255, 0.08);"
    }
  },
  legPricingLabel: {
    marginBottom: 13,
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.primaryFontFamily,
    color: theme.colors.grey
  },
  legPricingText: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.primaryFontFamily,
    color: theme.colors.grey
  },
  legPricingPrice: {
    marginLeft: 12,
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.primaryFontFamily,
    color: theme.colors.black
  },
  noBorder: {
    "& td": {
      borderBottom: "none"
    }
  },
  occupiedRow: {
    "& td": {
      fontWeight: 700
    }
  },
  legToReplace: {
    "& td": {
      color: theme.colors.red
    }
  },
  date: {
    whiteSpace: "nowrap"
  }
});

interface Props extends WithStyles<typeof styles> {
  legs: Array<search_proposals_edges_node_legs> | null;
  legsToReplace?: Array<search_proposals_edges_node_legsToReplace> | null;
}

interface State {
  showLegPrices: boolean;
}

class FlightLegs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showLegPrices: false
    };
  }

  toggleLegPrices = (show: boolean) => {
    this.setState({ showLegPrices: show });
  };

  render() {
    const { classes, legs, legsToReplace } = this.props;

    return (
      <React.Fragment>
        <Typography variant="primary" component="div" className={classes.headline}>
          Itinerary
          <Switcher
            text="Show leg prices"
            className={classes.legSwitcher}
            onClick={this.toggleLegPrices}
            isDefaultChecked={this.state.showLegPrices}
          />
        </Typography>
        <BasicTable>
          <thead>
            <tr>
              <th align="left">Date</th>
              <th align="left">From (ADEP)</th>
              <th align="left">To (ADES)</th>
              <th align="left">Pax (NR)</th>
              <th align="left">Departure time</th>
              <th align="left">Arrival time</th>
              <th align="left">Flight time</th>
              <th align="left">Distance</th>
            </tr>
          </thead>
          <tbody>
            {legs &&
              legs.map(leg => (
                <React.Fragment key={leg.id}>
                  <tr
                    className={clsx(
                      leg.type === LegType.OCCUPIED && classes.occupiedRow,
                      this.state.showLegPrices && classes.noBorder
                    )}
                  >
                    <td className={classes.date}>{DateTime.fromISO(leg.takeOffTime).toFormat("dd MMM yyyy")}</td>
                    <td>{leg.from && leg.from.codeIcao}</td>
                    <td>{leg.to && leg.to.codeIcao}</td>
                    <td>{leg.passengerCount || "-"}</td>
                    <td>{DateTime.fromISO(leg.takeOffTime).toFormat("HH:mm")}</td>
                    <td>{DateTime.fromISO(leg.landingTime).toFormat("HH:mm")}</td>
                    <td>
                      {formatDuration(leg.duration)}
                    </td>
                    <td>{leg.distance && Math.round(leg.distance)} miles</td>
                  </tr>
                  {this.state.showLegPrices && (
                    <tr>
                      <td align="right">
                        <div className={classes.legPricingLabel}>Leg pricing</div>
                      </td>
                      <td colSpan={7}>
                        <div className={classes.legPricing}>
                          <div className={classes.legPricingArrow} />
                          <div>
                            <span className={classes.legPricingText}>Airport handling</span>
                            <span className={classes.legPricingPrice}>{leg.handlingFee} EUR</span>
                          </div>
                          <div>
                            <span className={classes.legPricingText}>Other</span>
                            <span className={classes.legPricingPrice}>
                              {Number(leg.totalPrice) - Number(leg.handlingFee) - Number(leg.durationFee)} EUR
                            </span>
                          </div>
                          <div>
                            <span className={classes.legPricingText}>Flight</span>
                            <span className={classes.legPricingPrice}>{leg.durationFee} EUR</span>
                          </div>
                          <div>
                            <span className={classes.legPricingText}>Sum (total)</span>
                            <strong className={classes.legPricingPrice}>{leg.totalPrice} EUR</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </BasicTable>
        {legsToReplace && legsToReplace.length > 0 && (
          <React.Fragment>
            <Typography variant="primary" component="div" className={classes.headline}>
              Replaced legs
            </Typography>
            <BasicTable>
              <thead>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">From (ADEP)</th>
                  <th align="left">To (ADES)</th>
                  <th align="left">Pax (NR)</th>
                  <th align="left">Departure time</th>
                  <th align="left">Arrival time</th>
                  <th align="left">Flight time</th>
                  <th align="left">Distance</th>
                </tr>
              </thead>
              <tbody>
                {legsToReplace.map(leg => (
                  <React.Fragment key={leg.id}>
                    <tr
                      className={clsx(
                        leg.type === LegType.OCCUPIED && classes.occupiedRow,
                        classes.legToReplace,
                        this.state.showLegPrices && classes.noBorder
                      )}
                    >
                      <td className={classes.date}>{DateTime.fromISO(leg.takeOffTime).toFormat("dd MMM yyyy")}</td>
                      <td>{leg.from && leg.from.codeIcao}</td>
                      <td>{leg.to && leg.to.codeIcao}</td>
                      <td>{leg.passengerCount || "-"}</td>
                      <td>{DateTime.fromISO(leg.takeOffTime).toFormat("HH:mm")}</td>
                      <td>{DateTime.fromISO(leg.landingTime).toFormat("HH:mm")}</td>
                      <td>
                        {formatDuration(leg.duration)}
                      </td>
                      <td>{leg.distance && Math.round(leg.distance)} miles</td>
                    </tr>
                    {this.state.showLegPrices && (
                      <tr>
                        <td align="right">
                          <div className={classes.legPricingLabel}>Leg pricing</div>
                        </td>
                        <td colSpan={7}>
                          <div className={classes.legPricing}>
                            <div className={classes.legPricingArrow} />
                            <div>
                              <span className={classes.legPricingText}>Airport handling</span>
                              <span className={classes.legPricingPrice}>{leg.handlingFee} EUR</span>
                            </div>
                            <div>
                              <span className={classes.legPricingText}>Other</span>
                              <span className={classes.legPricingPrice}>
                                {Number(leg.totalPrice) - Number(leg.handlingFee) - Number(leg.durationFee)} EUR
                              </span>
                            </div>
                            <div>
                              <span className={classes.legPricingText}>Flight</span>
                              <span className={classes.legPricingPrice}>{leg.durationFee} EUR</span>
                            </div>
                            <div>
                              <span className={classes.legPricingText}>Sum (total)</span>
                              <strong className={classes.legPricingPrice}>{leg.totalPrice} EUR</strong>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </BasicTable>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FlightLegs);

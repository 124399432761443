import React from "react";

import { getFlights_flights_edges_node } from "./__generated__/getFlights";
import { Modal, ModalHeader, ModalContent, ModalFooter } from "../../components/Modal";
import Button from "../../components/Button";
import FlightInfo from "../../parts/FlightInfo";
import FlightLegs from "../../parts/FlightLegs";
import FlightPrices from "../../parts/FlightPrices";
import { Mutation } from "react-apollo";
import { cancelFlight, cancelFlightVariables } from "./__generated__/cancelFlight";
import { CANCEL_FLIGHT } from "./Sales";

class CancelMutation extends Mutation<cancelFlight, cancelFlightVariables> {}

interface Props {
  handleClose: () => void;
  flight: getFlights_flights_edges_node;
}

class FlightDetail extends React.Component<Props> {
  render() {
    const { flight } = this.props;

    return (
      <Modal closeOnOverlayClick handleClose={this.props.handleClose} size="large">
        <ModalHeader
          title="Flight detail"
          actionButton={
            <Button onClick={this.props.handleClose} variant="secondary" small>
              Back to list
            </Button>
          }
        />
        <ModalContent>
          <FlightInfo flight={flight} />
          <FlightLegs legs={flight.legs} />
          <FlightPrices flight={flight} />
        </ModalContent>
        <ModalFooter>
          <CancelMutation
            mutation={CANCEL_FLIGHT}
            variables={{ id: flight.id }}
            onCompleted={this.props.handleClose}
            refetchQueries={["getFlights"]}
            awaitRefetchQueries
          >
            {(cancelFlight, { error, loading, called }) => (
              // TODO: Handle error properly
              <Button variant="danger" onClick={() => cancelFlight()} loading={loading} disabled={!!error && called}>
                Cancel
              </Button>
            )}
          </CancelMutation>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FlightDetail;

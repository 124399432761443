import React from "react";
import { useTranslation } from "react-i18next";
import withStyles, { WithStyles } from "react-jss";
import { Mutation } from "react-apollo";
import { DateTime, Settings } from "luxon";
import gql from "graphql-tag";
import uuid from "uuid/v4";
import { Query } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import { Modal, ModalContent, ModalHeader, ModalFooter } from "../../components/Modal";
import Button from "../../components/Button";
import Select from "../../components/Select";
import Error from "../../components/Error";
import { Theme } from "../../theme";

import { addOnDuty, addOnDutyVariables } from "./__generated__/addOnDuty";
import { getOnDutyFormValues, getOnDutyFormValuesVariables } from "./__generated__/getOnDutyFormValues";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import TextInput from "../../components/TextInput";
import Paragraph from "../../components/Paragraph";
import { getScheduleCrew_crewMembers_edges_node } from "./__generated__/getScheduleCrew";
import { getScheduleFleet_aircrafts_edges_node } from "./__generated__/getScheduleFleet";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const validationSchema = Yup.object().shape({
  crewMemberId: Yup.string().required("Crew member is required"),
  aircraftId: Yup.string().required("Aircraft is required"),
  since: Yup.date().required("Since is required"),
  to: Yup.date().required("To is required")
});

const styles = (theme: Theme) => ({
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  black: {
    color: theme.colors.black
  },
  error: {
    color: theme.colors.red
  }
});

const ADD_ON_DUTIES = gql`
  mutation addOnDuty($add: [OnDutyAddInput!]!) {
    changeOnDuties(add: $add)
  }
`;

const GET_ON_DUTY_FORM_VALUES = gql`
  query getOnDutyFormValues($first: Int!) {
    crewMembers(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
    aircrafts(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

class AddOnDutyMutation extends Mutation<addOnDuty, addOnDutyVariables> {}

interface Props extends WithStyles<typeof styles> {
  handleClose: () => void;
  refetchQueries: string[];
  data: {
    crewMember: getScheduleCrew_crewMembers_edges_node | null;
    aircraft: getScheduleFleet_aircrafts_edges_node | null;
    date: DateTime;
  };
}

class OnDutyFormValuesQuery extends Query<getOnDutyFormValues, getOnDutyFormValuesVariables> {}

const AddOnDutyModal: React.FunctionComponent<Props> = props => {
  const today = DateTime.utc().startOf("day");
  const { t } = useTranslation();

  return (
    <AddOnDutyMutation mutation={ADD_ON_DUTIES} refetchQueries={props.refetchQueries} awaitRefetchQueries>
      {(addOnDuty, { error: mutationError, loading }) => (
        <Formik
          initialValues={{
            aircraftId: props.data.aircraft && props.data.aircraft.id,
            crewMemberId: props.data.crewMember && props.data.crewMember.id,
            since: props.data.date.startOf("day"),
            to: props.data.date.endOf("day")
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              // @ts-ignore
              await addOnDuty({
                variables: {
                  add: [
                    {
                      id: uuid(),
                      ...values
                    }
                  ]
                }
              });
              props.handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
          render={({
            values,
            errors,
            touched,
            isSubmitting,
            submitCount,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <Modal component="form" onSubmit={handleSubmit} handleClose={props.handleClose} closeOnOverlayClick>
              <ModalHeader
                title="Add new event"
                actionButton={
                  <Button onClick={props.handleClose} variant="secondary" small>
                    Back to schedule
                  </Button>
                }
              />
              <ModalContent>
                <OnDutyFormValuesQuery
                  query={GET_ON_DUTY_FORM_VALUES}
                  variables={{
                    first: 100
                  }}
                >
                  {({ data, error }) => {
                    if (loading) {
                      return <Loader />;
                    }

                    if (error || data == null) {
                      return <Message title="Form values could not be loaded." />;
                    }

                    return (
                      <div>
                        <Paragraph>
                          New event for{" "}
                          {props.data.crewMember && (
                            <strong className={props.classes.black}>{props.data.crewMember.name}</strong>
                          )}
                          {props.data.aircraft && (
                            <strong className={props.classes.black}>{props.data.aircraft.name}</strong>
                          )}
                        </Paragraph>
                        {props.data.aircraft ? (
                          <Select
                            options={
                              (data.crewMembers &&
                                data.crewMembers &&
                                data.crewMembers.edges.map(crewMember => {
                                  return {
                                    key: crewMember.node.id,
                                    value: crewMember.node.id,
                                    text: crewMember.node.name || ""
                                  };
                                })) ||
                              []
                            }
                            value={values.crewMemberId}
                            label="Crew member"
                            onChange={(event: any, data: { value: string }) => {
                              setFieldValue("crewMemberId", data.value);
                            }}
                            error={!!errors.crewMemberId}
                            paddingBottom
                            fullWidth
                          />
                        ) : (
                          <Select
                            options={
                              (data.aircrafts &&
                                data.aircrafts &&
                                data.aircrafts.edges.map(aircraft => {
                                  return {
                                    key: aircraft.node.id,
                                    value: aircraft.node.id,
                                    text: aircraft.node.name || ""
                                  };
                                })) ||
                              []
                            }
                            value={values.aircraftId}
                            label="Aircraft"
                            onChange={(event: any, data: { value: string }) => {
                              setFieldValue("aircraftId", data.value);
                            }}
                            error={!!errors.aircraftId}
                            paddingBottom
                            fullWidth
                          />
                        )}
                        <div className={props.classes.dateWrapper}>
                          <DayPickerInput
                            component={TextInput}
                            inputProps={{
                              label: "Start date",
                              paddingBottom: true
                            }}
                            value={values.since == null ? today.toJSDate() : values.since.toJSDate()}
                            format="LLL dd yyyy"
                            formatDate={(date, format) => DateTime.fromJSDate(date).toFormat(format)}
                            dayPickerProps={{
                              disabledDays: {
                                before: today.toJSDate(),
                                after: today
                                  .plus({ years: 100 })
                                  .endOf("year")
                                  .toJSDate()
                              }
                            }}
                            onDayChange={selectedDay => {
                              const value = DateTime.fromJSDate(selectedDay).startOf("day");
                              setFieldValue("since", value);
                            }}
                          />
                          <DayPickerInput
                            component={TextInput}
                            inputProps={{
                              label: "End date",
                              paddingBottom: true
                            }}
                            value={values.to == null ? today.endOf("day").toJSDate() : values.to.toJSDate()}
                            format="LLL dd yyyy"
                            formatDate={(date, format) => DateTime.fromJSDate(date).toFormat(format)}
                            dayPickerProps={{
                              disabledDays: {
                                before: today.toJSDate(),
                                after: today
                                  .plus({ years: 100 })
                                  .endOf("year")
                                  .toJSDate()
                              }
                            }}
                            onDayChange={selectedDay => {
                              const value = DateTime.fromJSDate(selectedDay).endOf("day");
                              setFieldValue("to", value);
                            }}
                          />
                        </div>
                        {mutationError && <Error title={mutationError.name} message={mutationError.message} closable />}
                      </div>
                    );
                  }}
                </OnDutyFormValuesQuery>
              </ModalContent>
              <ModalFooter>
                <Button type="submit" variant="primary" loading={isSubmitting} disabled={values.since > values.to}>
                  Add event
                </Button>
              </ModalFooter>
            </Modal>
          )}
        />
      )}
    </AddOnDutyMutation>
  );
};

export default withStyles(styles)(AddOnDutyModal);

import React, { Component, Suspense } from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "react-jss";

import Loader from "./components/Loader";

import ProtectedRoute from "./parts/ProtectedRoute";
import { AuthProvider } from "./parts/AuthContext";
import * as routes from "./routes";
import { theme } from "./theme";

import Home from "./pages/Home";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/NewPassword";
import UserSettings from "./pages/UserSettings";
import CrewMembers from "./pages/crew/CrewMembers";
import Airports from "./pages/airports/Airports";
import Fleet from "./pages/fleet/Fleet";
import ScheduleCrew from "./pages/schedule/ScheduleCrew";
import ScheduleFleet from "./pages/schedule/ScheduleFleet";
import Search from "./pages/search/Search";
import Sales from "./pages/sales/Sales";
import Operations from "./pages/operations/Operations";
import NotFound from "./pages/NotFound";
import Operators from "./pages/operators/Operators";

/**
 * Setup Apollo client
 */
const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL
});

class App extends Component<{}> {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <AuthProvider client={client}>
            <ApolloProvider client={client}>
              <Router>
                <Switch>
                  <Route exact path={routes.LOGIN_PAGE} component={Login} />
                  <Route exact path={routes.PASSWORD_RESET_PAGE} component={ResetPassword} />
                  <Route exact path={routes.NEW_PASSWORD_PAGE} component={NewPassword} />
                  <ProtectedRoute exact path={routes.HOME_PAGE} component={Home} />
                  <ProtectedRoute exact path={routes.USER_SETTINGS_PAGE} component={UserSettings} />
                  <ProtectedRoute exact path={routes.SEARCH_PAGE} component={Search} />
                  <ProtectedRoute exact path={routes.SALES_PAGE} component={Sales} />
                  <ProtectedRoute exact path={routes.OPERATIONS_PAGE} component={Operations} />
                  <ProtectedRoute exact path={routes.AIRPORTS_PAGE} component={Airports} />
                  <ProtectedRoute exact path={routes.CREW_PAGE} component={CrewMembers} />
                  <ProtectedRoute exact path={routes.FLEET_PAGE} component={Fleet} />
                  <ProtectedRoute exact path={routes.OPERATORS_PAGE} component={Operators} />
                  <ProtectedRoute exact path={routes.SCHEDULE_CREW_PAGE} component={ScheduleCrew} />
                  <ProtectedRoute exact path={routes.SCHEDULE_FLEET_PAGE} component={ScheduleFleet} />
                  <Route component={NotFound} />
                </Switch>
              </Router>
            </ApolloProvider>
          </AuthProvider>
        </Suspense>
      </ThemeProvider>
    );
  }
}

export default App;

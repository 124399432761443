import gql from "graphql-tag";

export const FlightsFragment = gql`
  fragment Flight on Flight {
    id
    passengerCount
    feePerPassenger
    passengersFee
    fixedFee
    durationFee
    airportFee
    handlingFee
    overnightFee
    totalPrice
    aircraft {
      id
      name
      category
      maximumCapacity
    }
    legs {
      id
      type
      passengerCount
      takeOffTime
      landingTime
      duration
      distance
      feePerHour
      durationFee
      overnightFee
      airportFee
      handlingFee
      totalPrice
      from {
        id
        codeIcao
      }
      to {
        id
        codeIcao
      }
    }
  }
`;

export const FlightInfoFragment = gql`
  fragment FlightInfo on FlightConnection {
    edges {
      node {
        ...Flight
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
  ${FlightsFragment}
`;

import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import uuid from "uuid/v4";
import { Formik } from "formik";
import * as Yup from "yup";

import { Modal, ModalContent, ModalHeader, ModalFooter } from "../../components/Modal";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Select from "../../components/Select";
import Paragraph from "../../components/Paragraph";
import AirportDropdown from "../../components/AirportDropdown";

import { CrewMemberRank } from "../../__generated__/globalTypes";
import { CrewMembersFragment } from "./crewMembers.fragment";
import { addCrewMember, addCrewMemberVariables } from "./__generated__/addCrewMember";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  rank: Yup.string()
    .required("Rank is required")
    .oneOf(Object.values(CrewMemberRank), "Rank has to be one of the defined types"),
  homebaseId: Yup.string().required("Homebase is required")
});

const ADD_CREW_MEMBER = gql`
  mutation addCrewMember($id: UUID!, $name: String!, $rank: CrewMemberRank!, $homebaseId: UUID!) {
    addCrewMember(id: $id, data: { name: $name, rank: $rank, homebaseId: $homebaseId }) {
      ...CrewMember
    }
  }
  ${CrewMembersFragment}
`;

class CrewMemberMutation extends Mutation<addCrewMember, addCrewMemberVariables> {}

interface Props {
  handleClose: () => void;
}

const AddCrewMemberModal: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const [homebaseQuery, setHomebaseQuery] = React.useState<string>("");

  const ranks = React.useMemo(
    () =>
      Object.values(CrewMemberRank).map(rank => ({
        key: rank,
        text: t(`crewMemberRank.${rank}`),
        value: rank
      })),
    [CrewMemberRank]
  );

  return (
    <CrewMemberMutation mutation={ADD_CREW_MEMBER} refetchQueries={["getCrewMembers"]} awaitRefetchQueries>
      {(addCrewMember, { error }) => (
        <Formik
          initialValues={{
            name: "",
            homebaseId: "",
            rank: null
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              // @ts-ignore
              await addCrewMember({
                variables: {
                  id: uuid(),
                  ...values
                }
              });
              props.handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
          render={({
            values,
            errors,
            touched,
            isSubmitting,
            submitCount,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <Modal component="form" onSubmit={handleSubmit} handleClose={props.handleClose} closeOnOverlayClick>
              <ModalHeader
                title={t("addCrewMember.title")}
                actionButton={
                  <Button onClick={props.handleClose} variant="secondary" small>
                    Back to list
                  </Button>
                }
              />
              <ModalContent>
                <Paragraph>Fill in the name and details of the new crew member.</Paragraph>
                <TextInput
                  name="name"
                  id="name"
                  label={t("addCrewMember.inputName")}
                  value={values.name}
                  onChange={handleChange}
                  error={!!(touched.name && errors.name)}
                  fullWidth
                  paddingBottom
                />
                <AirportDropdown
                  name="homebaseId"
                  id="homebaseId"
                  placeholder="Select homebase"
                  label={t("addCrewMember.inputHomebase")}
                  value={values.homebaseId}
                  query={homebaseQuery}
                  onChange={(event: ChangeEvent, data: { value: string; searchQuery: string }) => {
                    setFieldValue("homebaseId", data.value);
                    setHomebaseQuery(data.searchQuery);
                  }}
                  error={!!(touched.homebaseId && errors.homebaseId)}
                  fullWidth
                  paddingBottom
                />
                <Select
                  options={ranks}
                  value={values.rank}
                  label={t("addCrewMember.inputRank")}
                  onChange={(event: any, data: { value: string }) => {
                    setFieldValue("rank", data.value);
                  }}
                  error={!!errors.rank}
                  paddingBottom
                  fullWidth
                />
              </ModalContent>
              <ModalFooter>
                <Button type="submit" variant="primary" loading={isSubmitting}>
                  {t("addCrewMember.buttonSubmit")}
                </Button>
              </ModalFooter>
            </Modal>
          )}
        />
      )}
    </CrewMemberMutation>
  );
};

export default AddCrewMemberModal;

import React, { Component } from "react";
import withStyles, { WithStyles } from "react-jss";
import { DateTime, Settings } from "luxon";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

import { Theme } from "../theme";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const styles = (theme: Theme) => ({
  datetimeWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  date: {
    border: "none",
    padding: 0,
    width: 100,
    color: theme.colors.black,
    fontSize: 16,
    lineHeight: "16px",
    fontFamily: theme.typography.secondaryFontFamily,
    textTransform: "uppercase",
    "&:focus": {
      outline: "none"
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      margin: 0
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  onChange: (date: string) => void;
  defaultDate: DateTime;
}

interface State {
  date: string;
}

class DatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      date: this.props.defaultDate.toISODate()
    };
  }

  private datePickerInput = React.createRef<HTMLInputElement>();
  private datePickerInstance: flatpickr.Instance | null = null;

  componentDidMount() {
    if (this.datePickerInput.current) {
      // @ts-ignore
      this.datePickerInstance = flatpickr(this.datePickerInput.current, {
        altInput: true,
        altFormat: "M d Y",
        dateFormat: "Y-m-d",
        minDate: "today",
        defaultDate: this.props.defaultDate.toISODate(),
        onChange: (selectedDates: string[], dateStr: string): void => {
          this.setState({ date: dateStr });
          this.props.onChange(dateStr);
        }
      });
    }
  }

  componentWillUnmount() {
    this.datePickerInstance && this.datePickerInstance.destroy();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.datetimeWrapper}>
          <input
            name="date"
            className={classes.date}
            value={this.state.date}
            ref={this.datePickerInput}
            onChange={() => {}} // We use flatpickr to change state, this only prevents warnings
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DatePicker);

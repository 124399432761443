import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: 172,
    height: 56,
    backgroundColor: theme.colors.white,
    border: "1px solid",
    borderColor: "#cdcdcd",
    borderRadius: 4,
    textAlign: "center",
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.colors.black,
    transition: "border-color 0.2s",
    cursor: "pointer",
    "&:hover, &:focus": {
      borderColor: theme.colors.grey,
      outline: "none"
    }
  },
  disabled: {
    backgroundColor: theme.colors.mediumGrey
  },
  checked: {
    borderColor: theme.colors.green,
    boxShadow: `0 0 0 1px ${theme.colors.green} inset`,
    "&:hover, &:focus": {
      borderColor: theme.colors.green,
      outline: "none"
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
  name?: string;
  id?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (e: any) => void;
  value?: any;
}

class Radio extends React.PureComponent<Props> {
  handleChange = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const { props } = this;
    if (props.disabled) {
      return;
    }

    this.props.onChange &&
      this.props.onChange({
        target: {
          ...props,
          checked: true
        },
        stopPropagation() {
          e.stopPropagation();
        },
        preventDefault() {
          e.preventDefault();
        },
        nativeEvent: e.nativeEvent
      });
  };

  render() {
    const { classes, className, disabled, children, checked } = this.props;

    const classString = clsx(classes.root, className, checked && classes.checked, disabled && classes.disabled);

    return (
      <div className={classString} tabIndex={1} onClick={this.handleChange}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Radio);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";
import MessageIcon from "../images/message-icon.svg";

const styles = (theme: Theme) => ({
  message: {
    textAlign: "center"
  },
  messageIcon: {
    marginBottom: 16
  },
  messageTitle: {
    marginBottom: 12,
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.colors.black
  },
  messageSubtitle: {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.colors.grey
  }
});

interface Props extends WithStyles<typeof styles> {
  title: string;
  subtitle?: string;
  className?: string;
}

class Message extends React.PureComponent<Props> {
  render() {
    const { title, subtitle, classes, className } = this.props;

    return (
      <div className={clsx(classes.message, className)}>
        <img src={MessageIcon} className={classes.messageIcon} alt="" />
        <div className={classes.messageTitle}>{title}</div>
        {subtitle && <div className={classes.messageSubtitle}>{subtitle}</div>}
      </div>
    );
  }
}

export default withStyles(styles)(Message);

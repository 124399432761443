import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import Typography from "./Typography";
import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  h1: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    color: theme.colors.black
  },
  h2: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    color: theme.colors.darkBlue
  },
  h3: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    color: theme.colors.darkBlue
  }
});

interface Props extends React.HTMLProps<HTMLHeadingElement>, WithStyles<typeof styles> {
  variant: "h1" | "h2" | "h3";
}

class Heading extends React.PureComponent<Props> {
  render() {
    const { variant, classes, className, children, color, ...props } = this.props;

    return (
      <Typography component={variant} className={clsx(classes[variant], className)} variant="primary" {...props}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(Heading);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Theme } from "../../theme";
import Button from "../../components/Button";
import FlightLegs from "../../parts/FlightLegs";
import FlightPrices from "../../parts/FlightPrices";
import { LegType } from "../../__generated__/globalTypes";
import { getFlights_flights_edges_node, getFlights_flights_edges_node_legs } from "./__generated__/getFlights";
import Typography from "../../components/Typography";
import { formatDuration } from "../../utils";

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.colors.white,
    marginTop: 0,
    marginBottom: 0,
    transition: "margin-top 0.2s, margin-bottom 0.2s"
  },
  open: {
    marginTop: 8,
    marginBottom: 8,
    boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.08)"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 24,
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.lightGrey,
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: "rgba(0, 66, 255, 0.08);"
    },
    "$root:last-child &": {
      borderBottom: "none"
    }
  },
  headerNameCell: {
    flexGrow: 0,
    flexBasis: "30%",
    maxWidth: "30%"
  },
  aircraftName: {
    marginBottom: 8,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.darkBlue
  },
  aircraftPax: {
    fontSize: 14,
    color: theme.colors.grey
  },
  time: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  position: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  totalPrice: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%",
    color: theme.colors.black
  },
  action: {
    flexGrow: 0,
    flexBasis: "15%",
    maxWidth: "15%"
  },
  arrowWrapper: {
    flexGrow: 0,
    flexBasis: "10%",
    maxWidth: "10%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column"
  },
  arrow: {
    display: "inline-block",
    transition: "transform 0.2s",
    cursor: "pointer"
  },
  arrowOpen: {
    transform: "rotate(-180deg)"
  },
  content: {
    overflow: "hidden",
    transition: "max-height 0.2s"
  },
  contentClosed: {
    maxHeight: 0
  },
  contentOpen: {
    maxHeight: 900
  },
  detailButtonWraper: {
    padding: 20,
    textAlign: "center"
  }
});

const getOccupiedLegsDurationSum = (legs: Array<getFlights_flights_edges_node_legs>) => {
  return legs
    .filter(filterLeg => filterLeg.type === LegType.OCCUPIED)
    .reduce((partial_sum, occupiedLeg) => {
      return partial_sum + (occupiedLeg ? occupiedLeg.duration || 0 : 0);
    }, 0);
};

const findOccupiedLeg = (legs: Array<getFlights_flights_edges_node_legs>) => {
  return legs.find(filterLeg => filterLeg.type === LegType.OCCUPIED);
};

interface Props extends WithStyles<typeof styles> {
  flight: getFlights_flights_edges_node;
  action?: React.ReactElement<typeof Button>;
  openDetail: () => void;
}

interface State {
  open: boolean;
  showLegPrices: boolean;
}

class FlightListItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      showLegPrices: false
    };
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  toggleLegPrices = (show: boolean) => {
    this.setState({ showLegPrices: show });
  };

  render() {
    const { classes, flight } = this.props;

    const occupiedLeg = flight.legs ? findOccupiedLeg(flight.legs) : null;

    return (
      <div className={clsx(classes.root, this.state.open && classes.open)}>
        <div className={classes.header}>
          <div className={classes.headerNameCell}>
            <Typography variant="primary" component="div" className={classes.aircraftName}>
              {flight.aircraft && flight.aircraft.name}
            </Typography>
            <Typography variant="primary" component="div" className={classes.aircraftPax}>
              {flight.aircraft && flight.aircraft.maximumCapacity} PAX
            </Typography>
          </div>
          <div className={classes.time}>
            {flight.legs && getOccupiedLegsDurationSum(flight.legs)
              ? formatDuration(getOccupiedLegsDurationSum(flight.legs))
              : "-"}
          </div>
          <div className={classes.position}>
            {(occupiedLeg && occupiedLeg.from && occupiedLeg.from.codeIcao) || "-"}
          </div>
          <div className={classes.totalPrice}>
            <strong>{flight.totalPrice} EUR</strong>
          </div>
          <div className={classes.action}>{this.props.action}</div>
          <div className={classes.arrowWrapper}>
            <div className={clsx(classes.arrow, this.state.open && classes.arrowOpen)}>
              <KeyboardArrowDownIcon onClick={this.toggleOpen} />
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.content,
            !this.state.open && classes.contentClosed,
            this.state.open && classes.contentOpen
          )}
        >
          <FlightLegs legs={flight.legs} />
          <FlightPrices flight={flight} />

          <div className={classes.detailButtonWraper}>
            <Button variant="primary" small onClick={this.props.openDetail}>
              Open detail
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FlightListItem);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.colors.white,
    transition: "background-color 0.2s",
    "tbody > &:hover": {
      backgroundColor: "rgba(0, 66, 255, 0.08)"
    }
  }
});

interface Props extends React.HTMLProps<HTMLTableRowElement>, WithStyles<typeof styles> {}

const TableRow: React.FunctionComponent<Props> = ({ classes, className, children, ...props }) => (
  <tr className={clsx(classes.root, className)} {...props}>
    {children}
  </tr>
);

export default withStyles(styles)(TableRow);

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Loader from "../../components/Loader";
import { Table, TableRow, TableHeaderCell, TableCell } from "../../components/Table";
import Message from "../../components/Message";

import { getCrewMembers_crewMembers_edges } from "./__generated__/getCrewMembers";
import UpdateCrewMemberModal from "./UpdateCrewMemberModal";
import { CrewMemberInfo_edges_node } from "./__generated__/CrewMemberInfo";

interface Props {
  crewMembers: ReadonlyArray<getCrewMembers_crewMembers_edges> | null;
  refetch: Function;
  loading: boolean;
}

const CrewMemberList: React.FunctionComponent<Props> = React.memo(({ refetch, loading, crewMembers }) => {
  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const { t } = useTranslation();

  const [modalOpen, setModalState] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<CrewMemberInfo_edges_node | null>(null);

  const openModal = (data: any) => () => {
    setEditData(data);
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  if (loading) return <Loader />;

  if (!crewMembers) return <Message title="No crewmembers were found." />;

  return (
    <React.Fragment>
      <Table>
        <thead>
          <TableRow>
            <TableHeaderCell>{t("crewMembers.table.name")}</TableHeaderCell>
            <TableHeaderCell>{t("crewMembers.table.rank")}</TableHeaderCell>
            <TableHeaderCell>{t("crewMembers.table.homebase")}</TableHeaderCell>
            <TableHeaderCell textAlign="right">Actions</TableHeaderCell>
          </TableRow>
        </thead>
        <tbody>
          {crewMembers.map(crewMember => (
            <TableRow key={crewMember.node.id}>
              <TableCell>
                <Heading variant="h3">{crewMember.node.name}</Heading>
              </TableCell>
              <TableCell>{crewMember.node.rank && t(`crewMemberRank.${crewMember.node.rank}`)}</TableCell>
              <TableCell>{crewMember.node.homebase && crewMember.node.homebase.name}</TableCell>
              <TableCell textAlign="right">
                <Button onClick={openModal(crewMember.node)} variant="secondary" small>
                  {t("crewMembers.table.edit")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {modalOpen && editData && <UpdateCrewMemberModal handleClose={closeModal} crewMember={editData} />}
    </React.Fragment>
  );
});

export default CrewMemberList;

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:focus": {
      outline: "none"
    }
  },
  text: {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.colors.blue,
    textDecoration: "underline",
    transition: "color 0.2s",
    "$root:hover &, $root:focus &": {
      color: theme.colors.darkBlue,
      textDecoration: "none"
    }
  },
  switcher: {
    position: "relative",
    width: 28,
    height: 16,
    marginLeft: 8,
    borderRadius: 8,
    backgroundColor: "#cdcdcd",
    transition: "background-color 0.2s"
  },
  checked: {
    backgroundColor: theme.colors.blue
  },
  switcherDot: {
    position: "absolute",
    top: 2,
    left: 2,
    width: 12,
    height: 12,
    borderRadius: 8,
    backgroundColor: theme.colors.white,
    transition: "transform 0.2s",
    "$checked &": {
      transform: "translateX(12px)"
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  text: string;
  onClick?: (checked: boolean) => void;
  className?: string;
  isDefaultChecked?: boolean;
}

interface State {
  checked: boolean;
}

class Switcher extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { checked: props.isDefaultChecked || false };
  }

  handleClick = () => {
    this.setState({ checked: !this.state.checked });
    if (this.props.onClick) this.props.onClick(!this.state.checked);
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      this.handleClick();
    }
  };

  render() {
    const { text, classes, className } = this.props;

    return (
      <div
        className={clsx(classes.root, className)}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        tabIndex={0}
      >
        <div className={classes.text}>{text}</div>
        <div className={clsx(classes.switcher, this.state.checked && classes.checked)}>
          <div className={classes.switcherDot} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Switcher);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: "1px solid",
    borderColor: theme.colors.mediumGrey,
    padding: "18px 32px",
    "& > *": {
      marginLeft: 16
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
}

class ModalFooter extends React.PureComponent<Props> {
  render() {
    const { classes, children, className } = this.props;

    return <div className={clsx(classes.root, className)}>{children}</div>;
  }
}

export default withStyles(styles)(ModalFooter);

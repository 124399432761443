import React from "react";
import withStyles, { WithStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import DashboardIcon from "@material-ui/icons/DashboardRounded";
import SearchIcon from "@material-ui/icons/SearchRounded";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoffRounded";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PeopleIcon from "@material-ui/icons/PeopleRounded";
import FlightIcon from "@material-ui/icons/FlightRounded";
import LanguageIcon from "@material-ui/icons/LanguageRounded";
import DateRangeIcon from "@material-ui/icons/DateRangeRounded";
import EventIcon from "@material-ui/icons/EventRounded";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { Theme } from "../theme";
import * as routes from "../routes";
import PageMeta from "./PageMeta";

import Heading from "../components/Heading";
import MenuItem from "../components/MenuItem";
import MenuDivider from "../components/MenuDivider";
import Button from "../components/Button";
import Logo from "../images/strafos-logo-white.svg";
import UserDropdown from "./UserDropdown";

const styles = (theme: Theme) => ({
  "@global": {
    ":root": {
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.secondaryFontFamily
    },
    "html, body, #root": {
      minHeight: "100%",
      "-webkit-font-smoothing": "antialiased",
      "font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale"
    },
    "#root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch"
    }
  },
  root: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    height: "100%",
    minHeight: "100%",
    backgroundColor: theme.colors.lightGrey
  },
  wrapper: {
    flex: 1,
    overflowX: "auto"
  },
  sidebar: {
    boxSizing: "border-box",
    minWidth: 260,
    paddingRight: 20,
    backgroundImage: `linear-gradient(to bottom, ${theme.colors.black}, ${theme.colors.darkBlue})`,
    borderBottom: "1px solid",
    borderColor: theme.colors.mediumGrey
  },
  logo: {
    margin: 20
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 80,
    padding: "20px 32px",
    backgroundColor: theme.colors.white,
    boxShadow: `0 1px 0 0 ${theme.colors.mediumGrey}`
  },
  heading: {
    marginBottom: 0,
    flex: 1
  },
  content: {
    padding: "25px 32px"
  }
});

interface Props extends WithStyles<typeof styles> {
  title: string;
  actionButton?: React.ReactElement<typeof Button>;
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<Props> = ({ children, classes, title, actionButton }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <PageMeta />
      <div className={classes.sidebar}>
        <div className={classes.logo}>
          <img src={Logo} width={52} alt="" />
        </div>
        <UserDropdown />
        <nav>
          <MenuItem exact to={routes.HOME_PAGE} icon={DashboardIcon}>
            {t("menu.dashboard")}
          </MenuItem>
          <MenuItem exact to={routes.SEARCH_PAGE} icon={SearchIcon}>
            {t("menu.search")}
          </MenuItem>
          <MenuItem exact to={routes.SALES_PAGE} icon={FlightTakeoffIcon}>
            {t("menu.sales")}
          </MenuItem>
          <MenuItem exact to={routes.OPERATIONS_PAGE} icon={AssignmentIcon}>
            {t("menu.operations")}
          </MenuItem>
          <MenuItem exact to={routes.CREW_PAGE} icon={PeopleIcon}>
            {t("menu.crew")}
          </MenuItem>
          <MenuItem exact to={routes.AIRPORTS_PAGE} icon={LanguageIcon}>
            {t("menu.airports")}
          </MenuItem>
          <MenuItem exact to={routes.FLEET_PAGE} icon={FlightIcon}>
            {t("menu.fleet")}
          </MenuItem>
          <MenuItem exact to={routes.OPERATORS_PAGE} icon={AssignmentIndIcon}>
            {"Operators"}
          </MenuItem>
          <MenuItem exact to={routes.SCHEDULE_CREW_PAGE} icon={DateRangeIcon}>
            {t("menu.scheduleCrew")}
          </MenuItem>
          <MenuItem exact to={routes.SCHEDULE_FLEET_PAGE} icon={EventIcon}>
            {t("menu.scheduleFleet")}
          </MenuItem>
          <MenuDivider />
          <MenuItem exact to={routes.USER_SETTINGS_PAGE}>
            {t("menu.settings")}
          </MenuItem>
          <MenuItem exact to={routes.HOME_PAGE}>
            {t("menu.support")}
          </MenuItem>
        </nav>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading variant="h1" className={classes.heading}>
            {title}
          </Heading>
          {actionButton}
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Layout);

import React, { ChangeEvent, Component } from "react";
import { DateTime, Settings } from "luxon";
import withStyles, { WithStyles } from "react-jss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

import { Theme } from "../../theme";

import NumberInput from "../../components/NumberInput";
import Button from "../../components/Button";
import AirportSelector from "../world/AirportSelector";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const MIN_PASSENGER_COUNT = 1;

const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    marginBottom: 24,
    backgroundColor: theme.colors.white,
    borderRadius: 6,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)"
  },
  field: {
    flex: 1,
    minWidth: 192,
    padding: 24,
    borderRight: "1px solid",
    borderRightColor: theme.colors.lightGrey
  },
  button: {
    margin: 16,
    alignSelf: "center"
  },
  label: {
    paddingBottom: 8,
    fontFamily: theme.typography.primaryFontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "16px",
    color: theme.colors.darkBlue
  },
  airportSelector: {
    "& .ui.search.dropdown": {
      border: 0,
      minWidth: "initial",
      minHeight: "initial",
      width: "100%",
      padding: 0
    },
    "& i.dropdown.icon": {
      display: "none"
    },
    "& .ui.selection.active.dropdown": {
      boxShadow: "none"
    },
    "& .text, & .ui.search.dropdown > input.search": {
      padding: 0,
      height: 20,
      color: theme.colors.black,
      fontSize: 16,
      lineHeight: "16px",
      fontFamily: theme.typography.secondaryFontFamily
    },
    "& .text": {
      display: "block!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& .ui.search.dropdown > input.search": {
      top: -4
    },
    "& .ui.dropdown .menu": {
      left: -23,
      top: "calc(100% + 28px)",
      width: 320,
      border: "none",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)!important"
    }
  },
  datetimeWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  date: {
    border: "none",
    padding: 0,
    width: 100,
    color: theme.colors.black,
    fontSize: 16,
    lineHeight: "16px",
    fontFamily: theme.typography.secondaryFontFamily,
    textTransform: "uppercase",
    "&:focus": {
      outline: "none"
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      margin: 0
    }
  },
  time: {
    border: "none",
    padding: 0,
    color: theme.colors.black,
    fontSize: 16,
    lineHeight: "16px",
    fontFamily: theme.typography.secondaryFontFamily,
    "&:focus": {
      outline: "none"
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      margin: 0
    }
  }
});

const validate = (
  fromAirportId: string | null,
  toAirportId: string | null,
  date: string,
  time: string,
  passengerCount: number
) => {
  const now = DateTime.utc();
  const dateInstance = DateTime.fromISO(date).startOf("day");
  const timeInstance = DateTime.fromFormat(`${date} ${time}`, DATE_TIME_FORMAT).startOf("minute");

  return {
    fromAirportId: fromAirportId == null,
    toAirportId: toAirportId == null,
    date: dateInstance.valueOf() < now.startOf("day").valueOf(),
    time: timeInstance.valueOf() < now.startOf("minute").valueOf(),
    passengerCount: passengerCount < MIN_PASSENGER_COUNT
  };
};

interface SubmitData {
  first: number;
  fromAirportId: string | null;
  toAirportId: string | null;
  departureTime: string;
  passengerCount: number;
}

interface Props extends WithStyles<typeof styles> {
  onSubmit: (values: SubmitData) => void;
  children: () => React.ReactNode
}

type TouchedField = {
  fromAirportId: boolean;
  toAirportId: boolean;
  date: boolean;
  time: boolean;
  passengerCount: boolean;
};

interface State {
  fromAirportId: string | null;
  fromQuery: string;
  toAirportId: string | null;
  toQuery: string;
  date: string;
  time: string;
  passengerCount: number;
  touched: TouchedField;
}

class SearchForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const now = DateTime.utc();

    this.state = {
      fromAirportId: null,
      fromQuery: "",
      toAirportId: null,
      toQuery: "",
      date: now.startOf("day").toISODate(),
      time: now
        .startOf("hour")
        .plus({ hours: 2 })
        .toFormat("HH:mm"),
      passengerCount: MIN_PASSENGER_COUNT,
      touched: {
        fromAirportId: false,
        toAirportId: false,
        date: false,
        time: false,
        passengerCount: false
      }
    };
  }

  datePickerInput = React.createRef<HTMLInputElement>();
  timePickerInput = React.createRef<HTMLInputElement>();

  datePickerInstance: flatpickr.Instance | null = null;
  timePickerInstance: flatpickr.Instance | null = null;

  componentDidMount() {
    if (this.datePickerInput.current) {
      this.datePickerInstance = flatpickr(this.datePickerInput.current, {
        altInput: true,
        altFormat: "M d Y",
        dateFormat: "Y-m-d",
        minDate: "today",
        defaultDate: this.state.date,
        onChange: (selectedDates, dateStr) => {
          this.setState({ date: dateStr });
        }
      });
    }

    if (this.timePickerInput.current) {
      this.timePickerInstance = flatpickr(this.timePickerInput.current, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        onChange: (selectedDates, dateStr) => {
          this.setState({ time: dateStr });
        }
      });
    }
  }

  componentWillUnmount() {
    this.datePickerInstance && this.datePickerInstance.destroy();
    this.timePickerInstance && this.timePickerInstance.destroy();
  }

  handleBlur = (field: string) => () => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = validate(
      this.state.fromAirportId,
      this.state.toAirportId,
      this.state.date,
      this.state.time,
      this.state.passengerCount
    );
    // @ts-ignore
    const isValid = !Object.keys(errors).some(field => errors[field]);

    if (isValid) {
      const values = {
        first: 100,
        fromAirportId: this.state.fromAirportId,
        departureTime: DateTime.fromFormat(`${this.state.date} ${this.state.time}`, DATE_TIME_FORMAT)
          .startOf("minute")
          .toISO(),
        toAirportId: this.state.toAirportId,
        passengerCount: this.state.passengerCount
      };
      this.props.onSubmit(values);
    }
  };

  render() {
    const errors = validate(
      this.state.fromAirportId,
      this.state.toAirportId,
      this.state.date,
      this.state.time,
      this.state.passengerCount
    );
    const { classes } = this.props;
    // @ts-ignore
    const isValid = !Object.keys(errors).some(field => errors[field]);
    const shouldMarkError = (field: string) => {
      // @ts-ignore
      const hasError = errors[field];
      // @ts-ignore
      const shouldShow = this.state.touched[field];

      return hasError && shouldShow ? new Error(`Field ${field} has errors`) : undefined;
    };

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit} className={classes.root}>
          <div className={classes.field}>
            <div className={classes.label}>From</div>
            <AirportSelector
              className={classes.airportSelector}
              placeholder="Select airport"
              name="fromAirport"
              error={shouldMarkError("fromAirportId")}
              value={this.state.fromAirportId}
              disabledValue={this.state.toAirportId}
              query={this.state.fromQuery}
              onBlur={this.handleBlur("fromAirportId")}
              onChange={(event: ChangeEvent, data: { value: string; searchQuery: string }) =>
                this.setState({
                  fromAirportId: data.value,
                  fromQuery: data.searchQuery
                })
              }
            />
          </div>
          <div className={classes.field}>
            <div className={classes.label}>To</div>
            <AirportSelector
              className={classes.airportSelector}
              placeholder="Select destination"
              name="toAirport"
              error={shouldMarkError("toAirportId")}
              value={this.state.toAirportId}
              disabledValue={this.state.fromAirportId}
              query={this.state.toQuery}
              onBlur={this.handleBlur("toAirportId")}
              onChange={(event: ChangeEvent, data: { value: string; searchQuery: string }) =>
                this.setState({
                  toAirportId: data.value,
                  toQuery: data.searchQuery
                })
              }
            />
          </div>
          <div className={classes.field}>
            <div className={classes.label}>Date & Time</div>
            <div className={classes.datetimeWrapper}>
              <input
                name="date"
                className={classes.date}
                value={this.state.date}
                ref={this.datePickerInput}
                onChange={() => {}} // We use flatpickr to change state, this only prevents warnings
              />
              <input
                name="time"
                className={classes.time}
                value={this.state.time}
                ref={this.timePickerInput}
                onChange={() => {}} // We use flatpickr to change state, this only prevents warnings
              />
            </div>
          </div>
          <div className={classes.field}>
            <NumberInput
              // name="passengerCount"
              label="Passengers"
              min={MIN_PASSENGER_COUNT}
              value={this.state.passengerCount}
              // onBlur={this.handleBlur("passengerCount")}
              onChange={(data: { value: number }) => {
                this.handleBlur("passengerCount")();
                this.setState({ passengerCount: data.value });
              }}
            />
          </div>
          <Button className={classes.button} type="submit" variant="primary" disabled={!isValid}>
            Search
          </Button>
        </form>
        {this.props.children()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SearchForm);

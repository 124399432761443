import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    marginBottom: 20,
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: theme.typography.pxToRem(14),
    "& th": {
      height: 30,
      padding: "0 24px",
      color: theme.colors.grey,
      fontWeight: 400,
      backgroundColor: theme.colors.lightGrey
    },
    "& td": {
      height: 38,
      padding: "0 24px",
      color: theme.colors.black,
      borderBottom: "1px solid",
      borderBottomColor: theme.colors.lightGrey
    }
  }
});

interface Props extends React.HTMLProps<HTMLTableElement>, WithStyles<typeof styles> {}

class BasicTable extends React.Component<Props> {
  render() {
    const { classes, className, children, ...props } = this.props;

    return (
      <table className={clsx(classes.root, className)} cellSpacing={0} cellPadding={0} {...props}>
        {children}
      </table>
    );
  }
}

export default withStyles(styles)(BasicTable);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import { Duration } from "luxon";

import { Theme } from "../theme";
import Typography from "../components/Typography";
import BasicTable from "../components/BasicTable";
import { search_proposals_edges_node, search_proposals_edges_node_legs } from "../pages/search/__generated__/search";
import {
  getFlights_flights_edges_node,
  getFlights_flights_edges_node_legs
} from "../pages/sales/__generated__/getFlights";
import { LegType } from "../__generated__/globalTypes";

const styles = (theme: Theme) => ({
  headline: {
    padding: "12px 24px",
    color: theme.colors.black,
    fontWeight: 700
  },
  occupiedRow: {
    "& td": {
      fontWeight: 700
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  flight: search_proposals_edges_node | getFlights_flights_edges_node;
}

interface LegsSummary {
  readonly duration: Duration;
  readonly unitPrice: number;
  readonly sum: number;
}

const getLegsSummary = (
  legs: search_proposals_edges_node_legs[] | getFlights_flights_edges_node_legs[]
): LegsSummary => {
  return legs.reduce(
    (carry: LegsSummary, leg: search_proposals_edges_node_legs | getFlights_flights_edges_node_legs): LegsSummary => {
      return {
        duration: carry.duration.plus(
          Duration.fromObject({
            minutes: leg.duration || 0
          })
        ),
        unitPrice: leg.feePerHour || 0, // We assume there will be no difference in multiple legs in one flight because they are handled by one aircraft
        sum: carry.sum + (leg.durationFee || 0)
      };
    },
    {
      duration: Duration.fromMillis(0),
      unitPrice: 0,
      sum: 0
    }
  );
};

class FlightPrices extends React.Component<Props> {
  render() {
    const { classes, flight } = this.props;
    const ferryLegs =
      flight.legs == null
        ? []
        : flight.legs.filter(
            (leg: search_proposals_edges_node_legs | getFlights_flights_edges_node_legs) => leg.type === LegType.FERRY
          );
    const occupiedLegs =
      flight.legs == null
        ? []
        : flight.legs.filter(
            (leg: search_proposals_edges_node_legs | getFlights_flights_edges_node_legs) =>
              leg.type === LegType.OCCUPIED
          );
    const ferrySummary = getLegsSummary(ferryLegs);
    const occupiedSummary = getLegsSummary(occupiedLegs);

    return (
      <React.Fragment>
        <Typography variant="primary" component="div" className={classes.headline}>
          Price breakdown
        </Typography>
        <BasicTable>
          <thead>
            <tr>
              <th align="left">Unit name</th>
              <th align="right">Quantity (time)</th>
              <th align="right">Unit price</th>
              <th align="right">Sum price (total)</th>
            </tr>
          </thead>
          <tbody>
            <tr className={clsx(classes.occupiedRow)}>
              <td>Revenue leg rate</td>
              <td align="right">{occupiedSummary.duration.toFormat("hh:mm")}</td>
              <td align="right">{occupiedSummary.unitPrice} EUR</td>
              <td align="right">
                <strong>{occupiedSummary.sum} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>Empty leg rate</td>
              <td align="right">{ferrySummary.duration.toFormat("hh:mm")}</td>
              <td align="right">{ferrySummary.unitPrice} EUR</td>
              <td align="right">
                <strong>{ferrySummary.sum} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>Fixed revenue leg fee</td>
              <td align="right">1</td>
              <td align="right">{flight.fixedFee} EUR</td>
              <td align="right">
                <strong>{flight.fixedFee} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>Overnight fee</td>
              <td align="right">1</td>
              <td align="right">{flight.overnightFee} EUR</td>
              <td align="right">
                <strong>{flight.overnightFee} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>Passenger fee</td>
              <td align="right">{flight.passengerCount}</td>
              <td align="right">{flight.feePerPassenger} EUR</td>
              <td align="right">
                <strong>{flight.passengersFee} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>Airport fee</td>
              <td align="right">{(flight.legs && flight.legs.length) || 0}</td>
              <td align="right" />
              <td align="right">
                <strong>{flight.airportFee} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>Handling fee</td>
              <td align="right">{(flight.legs && flight.legs.length) || 0}</td>
              <td align="right" />
              <td align="right">
                <strong>{flight.handlingFee} EUR</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>TOTAL PRICE</strong>
              </td>
              <td align="right" />
              <td align="right" />
              <td align="right">
                <strong>{flight.totalPrice} EUR</strong>
              </td>
            </tr>
          </tbody>
        </BasicTable>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FlightPrices);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import { Theme, Colors } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    fontFamily: (props: Props) =>
      props.variant === "primary" ? theme.typography.primaryFontFamily : theme.typography.secondaryFontFamily,
    fontSize: (props: Props) => (props.fontSize ? theme.typography.pxToRem(props.fontSize) : undefined),
    fontWeight: (props: Props) => (props.fontWeight ? props.fontWeight : undefined),
    color: (props: Props) => (props.color ? theme.colors[props.color] : undefined)
  }
});

interface Props extends React.HTMLProps<HTMLElement>, WithStyles<typeof styles> {
  component?: any;
  variant?: "primary" | "secondary";
  fontSize?: number;
  fontWeight?: number;
  color?: keyof Colors;
}

class Typography extends React.PureComponent<Props> {
  render() {
    const { component, children, classes, className, variant, ...props } = this.props;

    const Component = component || "span";

    return (
      <Component className={clsx(classes.root, className)} {...props}>
        {children}
      </Component>
    );
  }
}

export default withStyles(styles)(Typography);

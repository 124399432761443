import React from "react";
import { useTranslation } from "react-i18next";

import BasicTable from "../../components/BasicTable";
import Heading from "../../components/Heading";

import {
  airports_airports_edges_node_landingFees,
  airports_airports_edges_node_handlings_handlingFees
} from "./__generated__/airports";

type FeeType = airports_airports_edges_node_landingFees & airports_airports_edges_node_handlings_handlingFees;

interface Props<T extends FeeType> {
  fees: T[] | null;
}

const AirportAircraftCategoryFeeTable: React.FunctionComponent<Props<FeeType>> = <T extends FeeType>(
  props: Props<T>
) => {
  const { t } = useTranslation();
  const { fees } = props;

  if (!fees) return null;

  return (
    <div>
      <Heading variant={"h3"}>Pricing</Heading>
      <BasicTable>
        <thead>
          <tr>
            {fees.map((fee: T) => (
              <th key={fee.id}>{fee.category}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {fees.map((fee: T) => (
              <td key={fee.id}>{fee.fee} EUR</td>
            ))}
          </tr>
        </tbody>
      </BasicTable>
    </div>
  );
};

export default AirportAircraftCategoryFeeTable;

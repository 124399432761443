import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    padding: 24,
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(16),
    color: theme.colors.black,
    borderTop: "1px solid",
    borderColor: theme.colors.lightGrey,
    textAlign: (props: Props) => (props.textAlign ? props.textAlign : "left")
  },
  alignRight: {
    textAlign: "right"
  }
});

interface Props extends React.HTMLProps<HTMLTableCellElement>, WithStyles<typeof styles> {
  textAlign?: string;
}

const TableCell: React.FunctionComponent<Props> = ({ classes, className, children, textAlign, ...props }) => (
  <td className={clsx(classes.root, className)} {...props}>
    {children}
  </td>
);

export default withStyles(styles)(TableCell);

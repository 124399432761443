import React from "react";
import TooltipElement, { RCTooltip } from "rc-tooltip";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    display: "inline-block",
    padding: 8,
    backgroundColor: theme.colors.black,
    borderRadius: 4,
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    color: theme.colors.white,
    "&::after": {
      content: "''",
      position: "absolute"
    },
    "&-hidden": {
      display: "none"
    },
    "&-placement-top": {
      "&::after": {
        top: "100%",
        left: "calc(50% - 5px);",
        borderTop: `5px solid ${theme.colors.black}`,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent"
      }
    },
    "&-placement-bottom": {
      "&::after": {
        top: "-5px",
        left: "calc(50% - 5px);",
        borderBottom: `5px solid ${theme.colors.black}`,
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent"
      }
    },
    "&-placement-left": {
      "&::after": {
        top: "calc(50% - 5px)",
        right: "-5px",
        borderLeft: `5px solid ${theme.colors.black}`,
        borderTop: "5px solid transparent",
        borderBottom: "5px solid transparent"
      }
    },
    "&-placement-right": {
      "&::after": {
        top: "calc(50% - 5px)",
        left: "-5px",
        borderRight: `5px solid ${theme.colors.black}`,
        borderTop: "5px solid transparent",
        borderBottom: "5px solid transparent"
      }
    }
  }
});

interface Props extends WithStyles<typeof styles>, RCTooltip.Props {
  placement: "left" | "right" | "top" | "bottom";
}

class Tooltip extends React.Component<Props> {
  render() {
    const { classes, children, ...props } = this.props;

    return (
      <TooltipElement prefixCls={classes.root} {...props}>
        {children}
      </TooltipElement>
    );
  }
}

export default withStyles(styles)(Tooltip);

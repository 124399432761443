export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const PASSWORD_RESET_PAGE = "/reset-password";
export const NEW_PASSWORD_PAGE = "/new-password";
export const USER_SETTINGS_PAGE = "/user-settings";
export const SEARCH_PAGE = "/search";
export const SALES_PAGE = "/sales";
export const OPERATIONS_PAGE = "/operations";
export const AIRPORTS_PAGE = "/airports";
export const CREW_PAGE = "/crew";
export const FLEET_PAGE = "/fleet";
export const SCHEDULE_CREW_PAGE = "/schedule/crew";
export const SCHEDULE_FLEET_PAGE = "/schedule/fleet";
export const OPERATORS_PAGE = "/operators";

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  font: {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(16)
  },
  link: {
    color: theme.colors.red,
    textDecoration: "underline",
    cursor: "pointer",
    transition: "color 0.1s",
    "&:hover, &:focus": {
      color: theme.colors.darkRed,
      textDecoration: "none",
      outline: "none"
    }
  },
  confirm: {
    color: theme.colors.grey
  },
  no: {
    color: theme.colors.blue,
    "&:hover, &:focus": {
      color: theme.colors.darkBlue
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  link: string;
  confirm: string;
  activeCallback?: (isActive: boolean) => void;
  action: () => void;
}

const DeleteLink: React.FunctionComponent<Props> = ({ classes, link, confirm, activeCallback, action }) => {
  const [active, setActive] = React.useState<boolean>(false);

  const activate = () => {
    setActive(true);
    if (activeCallback) {
      activeCallback(true);
    }
  };

  const deactivate = () => {
    setActive(false);
    if (activeCallback) {
      activeCallback(false);
    }
  };

  if (active) {
    return (
      <React.Fragment>
        <span className={clsx(classes.font, classes.confirm)}>{confirm}</span>
        <span tabIndex={0} className={clsx(classes.font, classes.link)} onClick={action}>
          Yes
        </span>
        <span tabIndex={0} className={clsx(classes.font, classes.link, classes.no)} onClick={deactivate}>
          No
        </span>
      </React.Fragment>
    );
  }

  return (
    <span tabIndex={0} className={clsx(classes.font, classes.link)} onClick={activate}>
      {link}
    </span>
  );
};

export default withStyles(styles)(DeleteLink);

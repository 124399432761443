import gql from "graphql-tag";

export const AicraftsFragment = gql`
  fragment Aircraft on Aircraft {
    id
    name
    category
    maximumCapacity
    homebase {
      id
      name
      codeIata
      codeIcao
    }
  }
`;

export const AircraftInfoFragment = gql`
  fragment AircraftInfo on AircraftConnection {
    edges {
      node {
        ...Aircraft
      }
      cursor
    }
  }
  ${AicraftsFragment}
`;

import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
}

const PageMeta: React.FunctionComponent<Props> = React.memo(({ title }) => (
  <Helmet>
    <title>{title ? `${title} | Strafos` : "Strafos"}</title>
  </Helmet>
));

export default PageMeta;

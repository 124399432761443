import gql from "graphql-tag";

export const CrewMembersFragment = gql`
  fragment CrewMember on CrewMember {
    id
    name
    rank
    homebase {
      id
      name
      codeIcao
    }
  }
`;

export const CrewMemberInfoFragment = gql`
  fragment CrewMemberInfo on CrewMemberConnection {
    edges {
      node {
        ...CrewMember
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  ${CrewMembersFragment}
`;

import React from "react";
import FlightIcon from "@material-ui/icons/Flight";
import withStyles, { WithStyles } from "react-jss";
import Tooltip from "./Tooltip";
import { getAircraftsForMap_aircrafts_edges_node } from "../pages/__generated__/getAircraftsForMap";

const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;

const styles = {
  icon: {
    position: "absolute",
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
    left: -ICON_WIDTH / 2,
    top: -ICON_HEIGHT / 2
  }
};

interface Props extends WithStyles<typeof styles> {
  lat: number; // needed by GoogleMapReact component to be visualized on the correct location
  lng: number; // needed by GoogleMapReact component to be visualized on the correct location
  aircraft: getAircraftsForMap_aircrafts_edges_node;
}

class AircraftLocation extends React.Component<Props> {
  render() {
    const { classes, aircraft } = this.props;

    return (
      <Tooltip
        placement={"top"}
        trigger={["click"]}
        overlay={
          <span>
            {aircraft.name} ({aircraft.tailRegistration})
          </span>
        }
      >
        <FlightIcon className={classes.icon} titleAccess={`${aircraft.name} (${aircraft.tailRegistration})`} />
      </Tooltip>
    );
  }
}

export default withStyles(styles)(AircraftLocation);

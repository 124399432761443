import gql from "graphql-tag";

export const LegFragment = gql`
  fragment Leg on Leg {
    id
    type
    takeOffTime
    landingTime
    duration
    passengerCount
    feePerHour
    durationFee
    overnightFee
    airportFee
    handlingFee
    totalPrice
    status
    from {
      id
      name
      codeIata
      codeIcao
    }
    to {
      id
      name
      codeIata
      codeIcao
    }
    opsCrew: ops(type: CREW)
    opsFuel: ops(type: FUEL)
    opsCatering: ops(type: CATERING)
    opsFlightPlanning: ops(type: FLIGHT_PLANNING)
    opsPermits: ops(type: PERMITS)
  }
`;

export const LegInfoFragment = gql`
  fragment LegInfo on LegConnection {
    edges {
      node {
        ...Leg
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
  ${LegFragment}
`;

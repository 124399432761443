import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Form, Segment, Message, Icon } from "semantic-ui-react";
import { parse } from "query-string";
import { Formik } from "formik";
import * as Yup from "yup";

import BasicPage from "../parts/BasicPage";
import PageMeta from "../parts/PageMeta";
import { LOGIN_PAGE } from "../routes";

Yup.addMethod(Yup.string, "sameAs", function(ref, message) {
  return this.test("sameAs", message, function(value) {
    // @ts-ignore
    let other = this.resolve(ref);

    return !other || !value || value === other;
  });
});

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Minimum password length is 8 characters"),
  passwordAgain: Yup.string()
    .required("Repeat your password")
    // @ts-ignore
    .sameAs(Yup.ref("password"), "Passwords do not match")
});

interface Props extends RouteComponentProps, WithTranslation {
  ready: boolean;
}

class NewPassword extends React.Component<Props> {
  email?: string | Array<string> | null;
  token?: string | Array<string> | null;
  errors: Array<Error>;

  constructor(props: Props) {
    super(props);

    const query = parse(props.location.search);
    const errors = [];

    if (!query.email) {
      errors.push(new Error(props.t("newPassword.errorEmailMissing")));
    }

    if (!query.token) {
      errors.push(new Error(props.t("newPassword.errorTokenMissing")));
    }

    this.email = query.email;
    this.token = query.token;
    this.errors = errors;
  }

  render() {
    const { t } = this.props;

    return (
      <BasicPage title={t("newPassword.title")}>
        {() => {
          if (this.errors.length > 0) {
            return <Message error header="Error" list={this.errors.map(error => error.message)} />;
          }

          return (
            <React.Fragment>
              <PageMeta title={t("newPassword.pageTitle")} />
              <Formik
                initialValues={{
                  password: "",
                  passwordAgain: ""
                }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 1000);
                }}
                validationSchema={validationSchema}
                render={({ values, errors, touched, isSubmitting, submitCount, handleChange, handleSubmit }) => (
                  <Form
                    size="large"
                    onSubmit={handleSubmit}
                    loading={isSubmitting}
                    error={Object.keys(errors).length > 0 && submitCount > 0}
                  >
                    <Segment stacked>
                      <Form.Input
                        fluid
                        icon="lock"
                        iconPosition="left"
                        label={t("newPassword.inputPassword")}
                        type="password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        error={!!(touched.password && errors.password)}
                      />
                      <Form.Input
                        fluid
                        icon="lock"
                        iconPosition="left"
                        label={t("newPassword.inputPasswordAgain")}
                        type="password"
                        id="passwordAgain"
                        value={values.passwordAgain}
                        onChange={handleChange}
                        error={!!(touched.passwordAgain && errors.passwordAgain)}
                      />
                      <Message
                        error
                        header="Form Errors"
                        // @ts-ignore
                        list={Object.keys(errors).map(error => errors[error])}
                      />
                      <Button color="blue" fluid size="large" type="submit">
                        {t("newPassword.submitButton")}
                      </Button>
                    </Segment>
                    <Message attached="bottom" size="mini" info>
                      <Icon name="help" />
                      {t("newPassword.loginText")}
                      <Link to={LOGIN_PAGE}>{t("newPassword.loginLink")}</Link>
                    </Message>
                  </Form>
                )}
              />
            </React.Fragment>
          );
        }}
      </BasicPage>
    );
  }
}

export default withTranslation()(NewPassword);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    marginLeft: 20,
    borderBottom: "1px solid #3d3d3d",
    marginTop: 16,
    marginBottom: 16
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
}

const MenuDivider: React.FunctionComponent<Props> = ({ classes, className }) => (
  <div className={clsx(classes.root, className)} />
);

export default withStyles(styles)(MenuDivider);

import { Query } from "react-apollo";
import gql from "graphql-tag";
import React, { Component } from "react";
import { Form, SemanticWIDTHS } from "semantic-ui-react";

import {
  suggestAirports,
  suggestAirportsVariables,
  suggestAirports_airports_edges
} from "./__generated__/suggestAirports";

const SUGGEST_AIRPORTS = gql`
  query suggestAirports($filter: AirportFilter!, $first: Int!, $after: String) {
    airports(filter: $filter, first: $first, after: $after) {
      edges {
        node {
          id
          name
          city
          codeIata
          codeIcao
        }
        cursor
      }
    }
  }
`;

const formatAirports = (airports: ReadonlyArray<suggestAirports_airports_edges> | null) => {
  if (!airports) return [];

  return airports.map(airport => ({
    key: airport.node.id,
    text: `${airport.node.name || ""} (${airport.node.codeIcao})`,
    value: airport.node.id,
    airport: airport.node
  }));
};

export interface Props {
  query: string;
  id?: string;
  name?: string;
  label?: string;
  inline?: boolean;
  width?: SemanticWIDTHS;
  value?: string | null;
  disabledValue?: string | null;
  required?: boolean;
  onBlur?: () => void;
  onChange: Function;
  error?: Object;
  className?: string;
  placeholder?: string;
}

interface State {
  query: string;
}

class SuggestAirportsQuery extends Query<suggestAirports, suggestAirportsVariables> {}

class AirportSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { query: this.props.query };
  }

  componentWillReceiveProps(nextProps: Readonly<Props>): void {
    if (nextProps.query !== this.state.query && nextProps.value !== this.props.value) {
      this.setState({
        query: nextProps.query
      });
    }
  }

  render() {
    return (
      <SuggestAirportsQuery query={SUGGEST_AIRPORTS} variables={{ first: 100, filter: { query: this.state.query } }}>
        {({ loading, error, data, refetch }) => {
          const options =
            !error && data && data.airports != null
              ? formatAirports(
                  this.props.disabledValue == null
                    ? data.airports.edges
                    : data.airports.edges.filter(edge => edge.node.id !== this.props.disabledValue)
                )
              : [];

          return (
            <Form.Dropdown
              selection
              search={options => options}
              className={this.props.className}
              name={this.props.name}
              label={this.props.label}
              inline={this.props.inline}
              width={this.props.width}
              value={this.props.value || ""}
              required={this.props.required}
              placeholder={this.props.placeholder}
              onChange={(event, data) => {
                this.props.onChange(event, {
                  ...data,
                  searchQuery: this.state.query
                });
              }}
              onBlur={this.props.onBlur}
              selectOnBlur={false}
              onFocus={() => {
                this.setState({ query: "" });
                refetch();
              }}
              loading={loading}
              error={!!(error || this.props.error)}
              options={options}
              onSearchChange={(event, { searchQuery }) => {
                this.setState({ query: searchQuery });
                refetch();
              }}
            />
          );
        }}
      </SuggestAirportsQuery>
    );
  }
}

export default AirportSelector;

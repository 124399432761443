import React from "react";
import { useTranslation } from "react-i18next";

import { Table, TableCell, TableHeaderCell, TableRow } from "../../components/Table";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import Message from "../../components/Message";

import { airports_airports_edges } from "./__generated__/airports";
import AirportDetailModal from "./AirportDetailModal";
import { AirportInfo_edges_node } from "./__generated__/AirportInfo";

interface Props {
  airports: ReadonlyArray<airports_airports_edges> | null;
  refetch: Function;
  loading: boolean;
}

const AirportList: React.FunctionComponent<Props> = React.memo(({ refetch, loading, airports }) => {
  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const { t } = useTranslation();

  const [modalOpen, setModalState] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<AirportInfo_edges_node | null>(null);

  const openModal = (data: any) => () => {
    setModalData(data);
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  if (loading) return <Loader />;

  if (!airports) return <Message title="No airports found" />;

  return (
    <React.Fragment>
      <Table>
        <thead>
          <TableRow>
            <TableHeaderCell>{t("airports.table.codeIcao")}</TableHeaderCell>
            <TableHeaderCell>{t("airports.table.codeIata")}</TableHeaderCell>
            <TableHeaderCell>{t("airports.table.name")}</TableHeaderCell>
            <TableHeaderCell>{t("airports.table.city")}</TableHeaderCell>
            <TableHeaderCell>{t("airports.table.country")}</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </thead>
        <tbody>
          {airports.map(airport => (
            <TableRow key={airport.node.id}>
              <TableCell>
                <strong>{airport.node.codeIcao}</strong>
              </TableCell>
              <TableCell>{airport.node.codeIata}</TableCell>
              <TableCell>{airport.node.name}</TableCell>
              <TableCell>{airport.node.city}</TableCell>
              <TableCell>{airport.node.country && airport.node.country.name}</TableCell>
              <TableCell>
                <Button onClick={openModal(airport.node)} variant="secondary" small>
                  {t("airports.table.detail")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {modalOpen && modalData && <AirportDetailModal handleClose={closeModal} airport={modalData} />}
    </React.Fragment>
  );
});

export default AirportList;

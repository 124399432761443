import { ApolloClient } from "apollo-client";
import * as React from "react";

import { LoginMethod, LogoutMethod } from "../types/functions";

interface Props {
  children: React.ReactElement<any>;
  client: ApolloClient<any>;
}

interface State {
  isAuth: boolean;
  name: string | null;
  email: string | null;
}

interface ContextType extends State {
  login: LoginMethod;
  logout: LogoutMethod;
}

const defaultContext: ContextType = {
  isAuth: false,
  name: null,
  email: null,
  login: () => new Promise(() => {}),
  logout: () => {}
};

const AuthContext = React.createContext<ContextType>(defaultContext);

class AuthProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const user = sessionStorage.getItem("user");
    const userObject = user ? JSON.parse(user) : {};

    this.state = {
      isAuth: !!user,
      name: userObject.name || null,
      email: userObject.email || null
    };
  }

  public login: LoginMethod = async (email, password) => {
    // TODO: Implement proper login logic using fetch or whatever
    // simulate server response taking some time
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    this.setState({
      isAuth: true,
      name: "John Doe",
      email
    });

    sessionStorage.setItem(
      "user",
      JSON.stringify({
        name: "John Doe",
        email
      })
    );
  };

  public logout: LogoutMethod = () => {
    this.props.client.clearStore().then(() => {
      sessionStorage.removeItem("user");

      this.setState({
        isAuth: false,
        name: null,
        email: null
      });
    });
  };

  public render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          name: this.state.name,
          email: this.state.email,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };

import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Formik } from "formik";
import uuid from "uuid/v4";
import * as Yup from "yup";

import { addOperator, addOperatorVariables } from "./__generated__/addOperator";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../../components/Modal";
import Button from "../../components/Button";
import Paragraph from "../../components/Paragraph";
import TextInput from "../../components/TextInput";
import Error from "../../components/Error";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  codeIcao: Yup.string().required("ICAO is required")
});

const ADD_OPERATOR = gql`
  mutation addOperator($id: UUID!, $name: String!, $codeIcao: String!) {
    addOperator(id: $id, data: { name: $name, codeIcao: $codeIcao }) {
      id
    }
  }
`;

interface Props {
  handleClose: () => void;
}

const AddOperatorModal: React.FunctionComponent<Props> = ({ handleClose }) => {
  return (
    <Mutation<addOperator, addOperatorVariables> mutation={ADD_OPERATOR} refetchQueries={["getOperators"]} awaitRefetchQueries>
      {(addOperatorFn, { error: mutationError }) => (
        <Formik
          initialValues={{
            name: "",
            codeIcao: ""
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              // @ts-ignore
              await addOperatorFn({
                variables: {
                  id: uuid(),
                  ...values
                }
              });
              handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, error }) => (
            <Modal component="form" onSubmit={handleSubmit} handleClose={handleClose} closeOnOverlayClick>
              <ModalHeader
                title={"Add new operator"}
                actionButton={
                  <Button onClick={handleClose} variant="secondary" small>
                    Back to list
                  </Button>
                }
              />
              <ModalContent>
                <Paragraph>Fill in the name and details of the new operator.</Paragraph>
                <TextInput
                  name="name"
                  id="name"
                  label={"Name"}
                  value={values.name}
                  onChange={handleChange}
                  error={!!(touched.name && errors.name)}
                  fullWidth
                  paddingBottom
                />
                <TextInput
                  name="codeIcao"
                  id="codeIcao"
                  label={"ICAO"}
                  value={values.codeIcao}
                  onChange={handleChange}
                  error={!!(touched.codeIcao && errors.codeIcao)}
                  fullWidth
                  paddingBottom
                />
                {error && <Error title={error.name} message={error.message} closable={false} />}
                {mutationError && <Error title={mutationError.name} message={mutationError.message} closable={false} />}
              </ModalContent>
              <ModalFooter>
                <Button type="submit" variant="primary" loading={isSubmitting}>
                  {"Add operator"}
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Formik>
      )}
    </Mutation>
  );
};

export default AddOperatorModal;

import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import { Mutation, Query } from "react-apollo";

import PageMeta from "../../parts/PageMeta";
import Layout from "../../parts/Layout";
import { FlightInfoFragment } from "./flights.fragment";
import { getFlights, getFlights_flights_edges_node, getFlightsVariables } from "./__generated__/getFlights";
import FilterForm from "./FilterForm";
import { DateTime } from "luxon";
import FlightList from "./FlightList";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FlightListItem from "./FlightListItem";
import FlightDetail from "./FlightDetail";
import { cancelFlight, cancelFlightVariables } from "./__generated__/cancelFlight";
import { FlightStatus } from "../../__generated__/globalTypes";

const GET_FLIGHTS = gql`
  query getFlights($since: DateTime!, $to: DateTime!, $first: Int!, $after: String, $filter: FlightFilter) {
    flights(since: $since, to: $to, first: $first, after: $after, filter: $filter) {
      ...FlightInfo
    }
  }
  ${FlightInfoFragment}
`;

export const CANCEL_FLIGHT = gql`
  mutation cancelFlight($id: UUID!) {
    cancelFlight(id: $id) {
      id
      status
    }
  }
`;

class FlightsQuery extends Query<getFlights, getFlightsVariables> {}

class CancelMutation extends Mutation<cancelFlight, cancelFlightVariables> {}

interface Props {}

const Sales: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [date, setDate] = React.useState<DateTime>(DateTime.utc());
  const [flightDetail, setFlightDetail] = React.useState<getFlights_flights_edges_node | null>(null);

  return (
    <Layout title={t("sales.title")}>
      <PageMeta title={t("sales.pageTitle")} />
      <FilterForm date={date} onSubmit={({ date }) => setDate(date)}>
        <FlightsQuery
          query={GET_FLIGHTS}
          variables={{
            first: 500,
            since: date.startOf("day").toISO(),
            to: date.endOf("day").toISO(),
            filter: {
              status: [FlightStatus.CONFIRMED]
            }
          }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loader />;
            }

            if (error) {
              return <Message title="There was an error, please try again." />;
            }

            if (!data || data.flights == null || data.flights.edges == null || data.flights.edges.length <= 0) {
              return <Message title="There is nothing to show." />;
            }

            return (
              <FlightList>
                {data.flights.edges.map(flight => (
                  <FlightListItem
                    key={flight.node.id}
                    flight={flight.node}
                    openDetail={() => setFlightDetail(flight.node)}
                    action={
                      <CancelMutation
                        mutation={CANCEL_FLIGHT}
                        variables={{ id: flight.node.id }}
                        refetchQueries={["getFlights"]}
                        awaitRefetchQueries
                      >
                        {(cancelFlight, { error, loading, called }) => (
                          <Button
                            variant="secondaryDanger"
                            small
                            onClick={() => cancelFlight()}
                            loading={loading}
                            disabled={!!error && called}
                          >
                            Cancel
                          </Button>
                        )}
                      </CancelMutation>
                    }
                  />
                ))}
              </FlightList>
            );
          }}
        </FlightsQuery>
      </FilterForm>
      {flightDetail && <FlightDetail handleClose={() => setFlightDetail(null)} flight={flightDetail} />}
    </Layout>
  );
};

export default Sales;

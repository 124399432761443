import React from "react";
import withStyles, { WithStyles } from "react-jss";
import gravatar from "gravatar";
import KeyBoardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { AuthConsumer } from "./AuthContext";
import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
    marginBottom: 20,
    padding: "14px 12px",
    borderRadius: 6,
    backgroundColor: theme.colors.darkBlue,
    transition: "background-color 0.2s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.black
    }
  },
  userAvatar: {
    width: 32,
    height: 32,
    marginRight: 8,
    borderRadius: 100
  },
  userName: {
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.colors.white
  },
  userType: {
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(10),
    color: "#cdcdcd",
    textTransform: "uppercase"
  },
  dropdownIcon: {
    flex: 1,
    color: theme.colors.white,
    textAlign: "right"
  },
  item: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    padding: 14,
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.colors.white,
    backgroundColor: theme.colors.darkBlue,
    transition: "background-color 0.2s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.black
    }
  }
});

interface Props extends WithStyles<typeof styles> {}

interface State {
  open: boolean;
}

class UserDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false
    };
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClose);
  }

  handleClose = () => {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.handleClose);
    });
  };

  handleOpen = () => {
    this.setState({ open: true }, () => {
      document.addEventListener("click", this.handleClose);
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <AuthConsumer>
        {({ name, email, logout }) => (
          <div className={classes.root} onClick={this.handleOpen}>
            <img src={gravatar.url(email || "")} alt="" className={classes.userAvatar} />
            <div>
              <div className={classes.userName}>{name}</div>
              <div className={classes.userType}>Operator</div>
            </div>
            <div className={classes.dropdownIcon}>
              <KeyBoardArrowDownIcon fontSize="small" />
            </div>
            {this.state.open && (
              <div className={classes.item} onClick={logout}>
                Logout
              </div>
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles)(UserDropdown);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import { DateTime, Settings } from "luxon";
import clsx from "clsx";
import { ApolloError } from "apollo-client";

import { Theme } from "../../theme";
import { getLegs_legs_edges } from "./__generated__/getLegs";
import { LegType, OpsType } from "../../__generated__/globalTypes";
import BasicTable from "../../components/BasicTable";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import OPSSwitcher from "./OPSSwitcher";
import { formatDuration } from "../../utils";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const styles = (theme: Theme) => ({
  root: {
    padding: "16px 0",
    backgroundColor: theme.colors.white,
    border: "1px solid",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
    borderColor: theme.colors.lightGrey,
    borderRadius: 6
  },
  date: {
    whiteSpace: "nowrap"
  },
  occupiedRow: {
    "& td": {
      fontWeight: 700
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  legs: ReadonlyArray<getLegs_legs_edges> | null;
  refetch: Function;
  loading: boolean;
  error?: ApolloError;
}

const LegList: React.FunctionComponent<Props> = ({ refetch, loading, error, legs, classes }) => {
  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message title={error.message} />;
  }

  if (!legs || legs.length === 0) {
    return <Message title="No results found." subtitle="Try to select another date." />;
  }

  return (
    <div className={classes.root}>
      <BasicTable>
        <thead>
          <tr>
            <th align="left">Date</th>
            <th align="left">ADEP</th>
            <th align="left">ADES</th>
            <th align="left">Pax</th>
            <th align="left">DEP</th>
            <th align="left">ARR</th>
            <th align="left">Time</th>
            <th align="left">FBO</th>
            <th align="left">FUEL</th>
            <th align="left">CAT</th>
            <th align="left">FPL</th>
            <th align="left">PRMT</th>
          </tr>
        </thead>
        <tbody>
          {legs &&
            legs.map(leg => (
              <tr key={leg.node.id} className={clsx(leg.node.type === LegType.OCCUPIED && classes.occupiedRow)}>
                <td className={classes.date}>{DateTime.fromISO(leg.node.takeOffTime).toFormat("dd MMM yyyy")}</td>
                <td>{leg.node.from && leg.node.from.codeIata}</td>
                <td>{leg.node.to && leg.node.to.codeIata}</td>
                <td>{leg.node.passengerCount}</td>
                <td>{DateTime.fromISO(leg.node.takeOffTime).toFormat("HH:mm")}</td>
                <td>{DateTime.fromISO(leg.node.landingTime).toFormat("HH:mm")}</td>
                <td>
                  {formatDuration(leg.node.duration)}
                </td>
                <td>
                  <OPSSwitcher status={leg.node.opsCrew} opsType={OpsType.CREW} legId={leg.node.id} />
                </td>
                <td>
                  <OPSSwitcher status={leg.node.opsFuel} opsType={OpsType.FUEL} legId={leg.node.id} />
                </td>
                <td>
                  <OPSSwitcher status={leg.node.opsCatering} opsType={OpsType.CATERING} legId={leg.node.id} />
                </td>
                <td>
                  <OPSSwitcher
                    status={leg.node.opsFlightPlanning}
                    opsType={OpsType.FLIGHT_PLANNING}
                    legId={leg.node.id}
                  />
                </td>
                <td>
                  <OPSSwitcher status={leg.node.opsPermits} opsType={OpsType.PERMITS} legId={leg.node.id} />
                </td>
              </tr>
            ))}
        </tbody>
      </BasicTable>
    </div>
  );
};

export default withStyles(styles)(LegList);

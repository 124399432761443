import React from "react";
import { DateTime, Settings } from "luxon";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";

import DatePicker from "../../components/DatePicker";

Settings.throwOnInvalid = true;
Settings.defaultZoneName = "utc";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    marginBottom: 24,
    backgroundColor: theme.colors.white,
    borderRadius: 6,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)"
  },
  field: {
    width: 192,
    padding: 24,
    borderRight: "1px solid",
    borderRightColor: theme.colors.lightGrey
  },
  label: {
    paddingBottom: 8,
    fontFamily: theme.typography.primaryFontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "16px",
    color: theme.colors.darkBlue
  }
});

interface SubmitData {
  date: DateTime;
}

interface Props extends WithStyles<typeof styles> {
  date: DateTime;
  onSubmit: (values: SubmitData) => void;
  children: React.ReactNode;
}

const FilterForm: React.FunctionComponent<Props> = ({ classes, date, children, onSubmit }) => {
  return (
    <React.Fragment>
      <form className={classes.root}>
        <div className={classes.field}>
          <div className={classes.label}>Date</div>
          <DatePicker
            defaultDate={date}
            onChange={(date: string) => {
              onSubmit({
                date: DateTime.fromISO(date)
              });
            }}
          />
        </div>
      </form>
      {children}
    </React.Fragment>
  );
};

export default withStyles(styles)(FilterForm);

import React from "react";
import withStyles, { WithStyles } from "react-jss";
import clsx from "clsx";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.mediumGrey
  },
  step: {
    flex: 1,
    padding: 24,
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.colors.grey,
    textAlign: "center"
  },
  activeStep: {
    color: theme.colors.blue,
    fontWeight: 700,
    borderBottom: "3px solid",
    borderBottomColor: theme.colors.blue
  }
});

interface Props extends WithStyles<typeof styles> {
  steps: Array<string>;
  active: number;
}

class ModalSteps extends React.Component<Props> {
  render() {
    const { classes, steps, active } = this.props;

    return (
      <div className={classes.root}>
        {steps.map((step, index) => (
          <div key={`${step}-${index}`} className={clsx(classes.step, active === index + 1 && classes.activeStep)}>
            {step}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(ModalSteps);

import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import { Theme } from "../../theme";
import { OpsStatus, OpsType } from "../../__generated__/globalTypes";
import { changeOps, changeOpsVariables } from "./__generated__/changeOps";

const CHANGE_OPS = gql`
  mutation changeOps($legId: UUID!, $type: OpsType!, $status: OpsStatus!) {
    changeOps(legId: $legId, type: $type, status: $status) {
      id
      updatedOps: ops(type: $type)
    }
  }
`;

const styles = (theme: Theme) => ({
  root: {
    position: "relative"
  },
  opsStatus: {
    cursor: "pointer"
  },
  select: {
    position: "absolute",
    zIndex: 1,
    minWidth: 160,
    backgroundColor: theme.colors.white,
    borderRadius: 6,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)"
  },
  selectItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 12,
    cursor: "pointer",
    fontWeight: 400,
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: 16,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(0, 66, 255, 0.08)"
    }
  },
  selectItemIcon: {
    marginRight: 12
  },
  bold: {
    fontWeight: 700
  },
  TODO: {
    color: theme.colors.red
  },
  REQUESTED: {
    color: "orange"
  },
  CONFIRMED: {
    color: theme.colors.green
  },
  DISABLED: {
    color: theme.colors.grey
  }
});

class ChangeOpsMutation extends Mutation<changeOps, changeOpsVariables> {}

interface Props extends WithStyles<typeof styles> {
  legId: string;
  opsType: OpsType;
  status: OpsStatus | null;
}

const OPSSwitcher: React.FunctionComponent<Props> = ({ classes, status, ...props }) => {
  const [selectOpen, changeSelectState] = React.useState<boolean>(false);

  React.useEffect(() => {
    const closeSelect = () => {
      changeSelectState(false);
    };

    if (selectOpen) {
      document.addEventListener("click", closeSelect);
    }

    return function cleanup() {
      document.removeEventListener("click", closeSelect);
    };
  });

  return (
    <div className={classes.root}>
      <ChangeOpsMutation mutation={CHANGE_OPS} refetchQueries={["getLegs"]} awaitRefetchQueries>
        {changeOps => (
          <React.Fragment>
            <FiberManualRecordIcon
              className={clsx(classes.opsStatus, status ? classes[status] : classes.TODO)}
              onClick={() => {
                changeSelectState(true);
              }}
            />
            {selectOpen && (
              <div className={classes.select}>
                {Object.values(OpsStatus).map((opsStatus: OpsStatus) => (
                  <div
                    key={opsStatus}
                    className={clsx(classes.selectItem, classes[opsStatus], status === opsStatus && classes.bold)}
                    onClick={() => {
                      if (status !== opsStatus) {
                        changeOps({
                          variables: {
                            legId: props.legId,
                            type: props.opsType,
                            status: opsStatus
                          }
                        });
                      }
                    }}
                  >
                    <FiberManualRecordIcon className={clsx(classes.selectItemIcon, classes[opsStatus])} />
                    {String(opsStatus).toLowerCase()}
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
      </ChangeOpsMutation>
    </div>
  );
};

export default withStyles(styles)(React.memo(OPSSwitcher));

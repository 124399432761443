import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../../theme";

const styles = (theme: Theme) => ({
  root: {
    flex: 1,
    padding: "28px 32px",
    overflowX: "auto"
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
}

class ModalContent extends React.PureComponent<Props> {
  render() {
    const { classes, children, className } = this.props;

    return <div className={clsx(classes.root, className)}>{children}</div>;
  }
}

export default withStyles(styles)(ModalContent);

import React, { ChangeEvent } from "react";
import withStyles, { WithStyles } from "react-jss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Mutation, MutationFn } from "react-apollo";
import gql from "graphql-tag";
import uuid from "uuid/v4";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import AirportDropdown from "../../components/AirportDropdown";
import Select from "../../components/Select";
import Heading from "../../components/Heading";
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalSteps } from "../../components/Modal";

import { AicraftsFragment } from "./aircrafts.fragment";
import { AircraftCategory, AircraftRangeInput } from "../../__generated__/globalTypes";
import { addAircraft, addAircraftVariables } from "./__generated__/addAircraft";
import { Theme } from "../../theme";

const validationSchemaPage1 = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  tailRegistration: Yup.string().required("Tail registration is required"),
  codeIcao: Yup.string().required("ICAO code is required"),
  maximumCapacity: Yup.number()
    .integer("Maximum capacity must be a whole number")
    .required("Maximum capacity is required")
    .positive("Maximum capacity must not be negative"),
  category: Yup.string()
    .required("Category is required")
    .oneOf(Object.values(AircraftCategory), "Category has to be one of the defined types"),
  homebaseId: Yup.string().required("Homebase is required")
});

const validationSchemaPage2 = Yup.object().shape({
  turnaroundTime: Yup.number()
    .integer("Turnaround minutes must be a whole number")
    .required("Turnaround minutes is required")
    .positive("Turnaround minutes must not be negative"),
  rateOfClimb: Yup.number()
    .integer("Rate of climb must be a whole number")
    .required("Rate of climb is required")
    .positive("Rate of climb must not be negative"),
  rateOfDescent: Yup.number()
    .integer("Rate of descent must be a whole number")
    .required("Rate of descent is required")
    .positive("Rate of descent must not be negative"),
  cruiseAltitude: Yup.number()
    .integer("Cruise altitude must be a whole number")
    .required("Cruise altitude is required")
    .positive("Cruise altitude must not be negative"),
  takeOffSpeed: Yup.number()
    .integer("Takeoff speed must be a whole number")
    .required("Takeoff speed is required")
    .positive("Takeoff speed must not be negative"),
  landingSpeed: Yup.number()
    .integer("Landing speed must be a whole number")
    .required("Landing speed is required")
    .positive("Landing speed must not be negative"),
  cruiseSpeed: Yup.number()
    .integer("Cruise speed must be a whole number")
    .required("Cruise speed is required")
    .positive("Cruise speed must not be negative")
});

const validationSchemaPage3 = Yup.object().shape({
  hourlyFeeFerry: Yup.number()
    .required("Hourly fee ferry is required")
    .positive("Hourly fee ferry must not be negative"),
  hourlyFeeOccupied: Yup.number()
    .required("Hourly fee occupied is required")
    .positive("Hourly fee occupied must not be negative"),
  fixedFee: Yup.number()
    .required("Fixed fee is required")
    .positive("Fixed fee must not be negative"),
  overnightFee: Yup.number()
    .required("Overnight fee is required")
    .positive("Overnight fee must not be negative"),
  passengerFee: Yup.number()
    .required("Passenger fee is required")
    .positive("Passenger fee must not be negative"),
  landingFee: Yup.number()
    .required("Landing fee is required")
    .positive("Landing fee must not be negative"),
  preflightCheckTime: Yup.number()
    .integer("Preflight check time must be a whole number")
    .required("Preflight check time is required")
    .positive("Preflight check time must not be negative"),
  preflightCheckTimeHomebase: Yup.number()
    .integer("preflight check time - homebase must be a whole number")
    .required("Preflight check time - homebase is required")
    .positive("Preflight check time - homebase must not be negative"),
  postflightCheckTime: Yup.number()
    .integer("Postflight check time must be a whole number")
    .required("Postflight check time required")
    .positive("Postflight check time must not be negative")
});

const validationSchemaPage4 = Yup.object().shape({
  flightRanges: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("UUID was not generated correctly"),
        passengerCount: Yup.number()
          .integer("Passenger count must be a whole number")
          .required("Passenger count is required"),
        maximumFlightRange: Yup.number()
          .integer("Maximum flight range must be a whole number")
          .required("Maximum flight range is required")
          .positive("Maximum flight range must not be negative"),
        minimalLandingRunwayLength: Yup.number()
          .integer("Minimal landing runway length must be a whole number")
          .required("Minimal landing runway length is required")
          .positive("Minimal landing runway length must not be negative"),
        runwayLengthWarning: Yup.number()
          .integer("Runway length warning must be a whole number")
          .required("Runway length warning is required")
          .positive("Runway length warning must not be negative")
      })
    )
    .min(1, "At least 1 Flight range must be specified")
});

const ADD_AIRCRAFT = gql`
  mutation addAircraft(
    $id: UUID!
    $name: String!
    $tailRegistration: String!
    $codeIcao: String!
    $maximumCapacity: Int!
    $category: AircraftCategory!
    $homebaseId: UUID!
    $turnaroundTime: Int!
    $rateOfClimb: Int! # feet per minute
    $rateOfDescent: Int! # feet per minute
    $cruiseAltitude: Int! # feets
    $takeOffSpeed: Int! # knots
    $landingSpeed: Int! # knots
    $cruiseSpeed: Int! # knots
    $hourlyFeeFerry: Float! # EUR
    $hourlyFeeOccupied: Float! # EUR
    $fixedFee: Float! # EUR
    $overnightFee: Float! # EUR
    $passengerFee: Float! # EUR
    $landingFee: Float! # EUR
    $preflightCheckTime: Int! # minutes
    $preflightCheckTimeHomebase: Int! # minutes
    $postflightCheckTime: Int! # minutes
    $flightRanges: [AircraftRangeInput!]!
  ) {
    addAircraft(
      id: $id
      data: {
        name: $name
        tailRegistration: $tailRegistration
        codeIcao: $codeIcao
        maximumCapacity: $maximumCapacity
        category: $category
        homebaseId: $homebaseId
        turnaroundTime: $turnaroundTime
        rateOfClimb: $rateOfClimb
        rateOfDescent: $rateOfDescent
        cruiseAltitude: $cruiseAltitude
        takeOffSpeed: $takeOffSpeed
        landingSpeed: $landingSpeed
        cruiseSpeed: $cruiseSpeed
        hourlyFeeFerry: $hourlyFeeFerry
        hourlyFeeOccupied: $hourlyFeeOccupied
        fixedFee: $fixedFee
        overnightFee: $overnightFee
        passengerFee: $passengerFee
        landingFee: $landingFee
        preflightCheckTime: $preflightCheckTime
        preflightCheckTimeHomebase: $preflightCheckTimeHomebase
        postflightCheckTime: $postflightCheckTime
        flightRanges: $flightRanges
      }
    ) {
      ...Aircraft
    }
  }
  ${AicraftsFragment}
`;

const styles = (theme: Theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "&>*": {
      flexBasis: "30%",
      flexGrow: 0
    }
  }
});

const flightRangesFactory = (count: number): Array<AircraftRangeInput> => {
  const flightRanges = [];

  for (let i = 0; i <= count; i++) {
    const flightRange: AircraftRangeInput = {
      id: uuid(),
      passengerCount: i,
      maximumFlightRange: 0,
      minimalLandingRunwayLength: 0,
      runwayLengthWarning: 0
    };

    flightRanges.push(flightRange);
  }

  return flightRanges;
};

interface Props extends WithTranslation, WithStyles<typeof styles> {
  handleClose: (args?: any) => void;
}

interface AircraftCategoryOption {
  key: string;
  text: string;
  value: string;
}

interface State {
  page: number;
  homebaseQuery: string;
}

class AddAircraftMutation extends Mutation<addAircraft, addAircraftVariables> {}

class AddAircraftModal extends React.Component<Props, State> {
  aircraftCategories: Array<AircraftCategoryOption>;
  newAircraft: addAircraftVariables;

  constructor(props: Props) {
    super(props);

    this.state = {
      homebaseQuery: "",
      page: 1
    };

    this.newAircraft = {
      id: uuid(),
      name: "",
      tailRegistration: "",
      codeIcao: "",
      maximumCapacity: 0,
      category: AircraftCategory.LIGHT,
      homebaseId: "",
      turnaroundTime: 0,
      rateOfClimb: 0,
      rateOfDescent: 0,
      cruiseAltitude: 0,
      takeOffSpeed: 0,
      landingSpeed: 0,
      cruiseSpeed: 0,
      hourlyFeeFerry: 0,
      hourlyFeeOccupied: 0,
      fixedFee: 0,
      overnightFee: 0,
      passengerFee: 0,
      landingFee: 0,
      preflightCheckTime: 0,
      preflightCheckTimeHomebase: 0,
      postflightCheckTime: 0,
      flightRanges: []
    };

    this.aircraftCategories = Object.values(AircraftCategory).map(category => ({
      key: category,
      text: props.t(`aircraftCategory.${category}`),
      value: category
    }));
  }

  setHomebaseQuery = (homebaseQuery: string) => {
    this.setState({ homebaseQuery });
  };

  renderPage1() {
    const { t, classes } = this.props;

    return (
      <Formik
        validationSchema={validationSchemaPage1}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          this.newAircraft = { ...this.newAircraft, ...values };
          this.setState({ page: 2 });
        }}
        initialValues={{
          name: this.newAircraft.name,
          tailRegistration: this.newAircraft.tailRegistration,
          codeIcao: this.newAircraft.codeIcao,
          maximumCapacity: this.newAircraft.maximumCapacity,
          category: this.newAircraft.category,
          homebaseId: this.newAircraft.homebaseId
        }}
        render={({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid
        }) => (
          <form onSubmit={handleSubmit} id="addAircraftForm">
            <Heading variant="h2">Name & registration</Heading>
            <div className={classes.flex}>
              <TextInput
                name="name"
                id="name"
                label={t("addAircraft.inputName")}
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="tailRegistration"
                id="tailRegistration"
                label={t("addAircraft.inputTailRegistration")}
                value={values.tailRegistration}
                onChange={handleChange}
                error={!!errors.tailRegistration}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="codeIcao"
                id="codeIcao"
                label={t("addAircraft.inputCodeIcao")}
                value={values.codeIcao}
                onChange={handleChange}
                error={!!errors.codeIcao}
                fullWidth
                paddingBottom
              />
            </div>
            <Heading variant="h2">Basic info</Heading>
            <div className={classes.flex}>
              <AirportDropdown
                name="homebaseId"
                id="homebaseId"
                label={t("addAircraft.inputHomebaseId")}
                value={values.homebaseId}
                query={this.state.homebaseQuery}
                onChange={(event: ChangeEvent, data: { value: string; searchQuery: string }) => {
                  setFieldValue("homebaseId", data.value);
                  this.setHomebaseQuery(data.searchQuery);
                }}
                error={!!errors.homebaseId}
                paddingBottom
              />
              <Select
                label={t("addAircraft.inputCategory")}
                options={this.aircraftCategories}
                value={values.category}
                onChange={(event: any, data: { value: string }) => {
                  setFieldValue("category", data.value);
                }}
                error={!!errors.category}
                paddingBottom
              />
              <TextInput
                name="maximumCapacity"
                id="maximumCapacity"
                label={t("addAircraft.inputMaximumCapacity")}
                type="number"
                min="0"
                value={values.maximumCapacity}
                onChange={handleChange}
                error={!!errors.maximumCapacity}
                fullWidth
                paddingBottom
              />
            </div>
          </form>
        )}
      />
    );
  }

  renderPage2() {
    const { t, classes } = this.props;

    return (
      <Formik
        validationSchema={validationSchemaPage2}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          this.newAircraft = { ...this.newAircraft, ...values };
          this.setState({ page: 3 });
        }}
        initialValues={{
          turnaroundTime: this.newAircraft.turnaroundTime,
          rateOfClimb: this.newAircraft.rateOfClimb,
          rateOfDescent: this.newAircraft.rateOfDescent,
          cruiseAltitude: this.newAircraft.cruiseAltitude,
          takeOffSpeed: this.newAircraft.takeOffSpeed,
          landingSpeed: this.newAircraft.landingSpeed,
          cruiseSpeed: this.newAircraft.cruiseSpeed
        }}
        render={({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid
        }) => (
          <form onSubmit={handleSubmit} id="addAircraftForm">
            <Heading variant="h2">Aircraft performance</Heading>
            <div className={classes.flex}>
              <TextInput
                name="turnaroundTime"
                id="turnaroundTime"
                label={t("addAircraft.inputTurnaroundMinutes")}
                type="number"
                min="0"
                labelAfter="mins"
                value={values.turnaroundTime}
                onChange={handleChange}
                error={!!errors.turnaroundTime}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="rateOfClimb"
                id="rateOfClimb"
                label={t("addAircraft.inputRateOfClimb")}
                type="number"
                min="0"
                labelAfter="ft/mins"
                value={values.rateOfClimb}
                onChange={handleChange}
                error={!!errors.rateOfClimb}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="rateOfDescent"
                id="rateOfDescent"
                type="number"
                min="0"
                labelAfter="ft/mins"
                value={values.rateOfDescent}
                onChange={handleChange}
                label={t("addAircraft.inputRateOfDescent")}
                error={!!errors.rateOfDescent}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="cruiseAltitude"
                id="cruiseAltitude"
                type="number"
                min="0"
                labelAfter="ft"
                value={values.cruiseAltitude}
                onChange={handleChange}
                label={t("addAircraft.inputCruiseAltitude")}
                error={!!errors.cruiseAltitude}
                fullWidth
                paddingBottom
              />
            </div>
            <Heading variant="h2">Aircraft speeds</Heading>
            <div className={classes.flex}>
              <TextInput
                name="takeOffSpeed"
                id="takeOffSpeed"
                type="number"
                min="0"
                label={t("addAircraft.inputTakeOffSpeed")}
                value={values.takeOffSpeed}
                onChange={handleChange}
                labelAfter="knots"
                error={!!errors.takeOffSpeed}
                fullWidth
              />
              <TextInput
                name="landingSpeed"
                id="landingSpeed"
                type="number"
                min="0"
                value={values.landingSpeed}
                onChange={handleChange}
                labelAfter="knots"
                label={t("addAircraft.inputLandingSpeed")}
                error={!!errors.landingSpeed}
                fullWidth
              />
              <TextInput
                name="cruiseSpeed"
                id="cruiseSpeed"
                type="number"
                min="0"
                value={values.cruiseSpeed}
                onChange={handleChange}
                labelAfter="knots"
                label={t("addAircraft.inputCruiseSpeed")}
                error={!!errors.cruiseSpeed}
                fullWidth
              />
            </div>
          </form>
        )}
      />
    );
  }

  renderPage3() {
    const { t, classes } = this.props;

    return (
      <Formik
        validationSchema={validationSchemaPage3}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          this.newAircraft = { ...this.newAircraft, ...values };
          this.setState({ page: 4 });
        }}
        initialValues={{
          hourlyFeeFerry: this.newAircraft.hourlyFeeFerry,
          hourlyFeeOccupied: this.newAircraft.hourlyFeeOccupied,
          fixedFee: this.newAircraft.fixedFee,
          overnightFee: this.newAircraft.overnightFee,
          passengerFee: this.newAircraft.passengerFee,
          landingFee: this.newAircraft.landingFee,
          preflightCheckTime: this.newAircraft.preflightCheckTime,
          preflightCheckTimeHomebase: this.newAircraft.preflightCheckTimeHomebase,
          postflightCheckTime: this.newAircraft.postflightCheckTime
        }}
        render={({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid
        }) => (
          <form onSubmit={handleSubmit} id="addAircraftForm">
            <Heading variant="h2">{t("addAircraft.fees")}</Heading>
            <div className={classes.flex}>
              <TextInput
                name="hourlyFeeFerry"
                id="hourlyFeeFerry"
                type="number"
                min="0"
                step="any"
                value={values.hourlyFeeFerry}
                onChange={handleChange}
                label={t("addAircraft.inputHourlyFeeFerry")}
                labelAfter="EUR"
                error={!!errors.hourlyFeeFerry}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="hourlyFeeOccupied"
                id="hourlyFeeOccupied"
                type="number"
                min="0"
                step="any"
                value={values.hourlyFeeOccupied}
                onChange={handleChange}
                labelAfter="EUR"
                label={t("addAircraft.inputHourlyFeeOccupied")}
                error={!!errors.hourlyFeeOccupied}
                fullWidth
                paddingBottom
              />

              <TextInput
                name="fixedFee"
                id="fixedFee"
                type="number"
                min="0"
                step="any"
                value={values.fixedFee}
                onChange={handleChange}
                labelAfter="EUR"
                label={t("addAircraft.inputFixedFee")}
                error={!!errors.fixedFee}
                fullWidth
                paddingBottom
              />

              <TextInput
                name="overnightFee"
                id="overnightFee"
                type="number"
                min="0"
                step="any"
                value={values.overnightFee}
                onChange={handleChange}
                labelAfter="EUR"
                label={t("addAircraft.inputOvernightFee")}
                error={!!errors.overnightFee}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="passengerFee"
                id="passengerFee"
                type="number"
                min="0"
                step="any"
                value={values.passengerFee}
                onChange={handleChange}
                labelAfter="EUR"
                label={t("addAircraft.inputPassengerFee")}
                error={!!errors.passengerFee}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="landingFee"
                id="landingFee"
                type="number"
                min="0"
                step="any"
                value={values.landingFee}
                onChange={handleChange}
                labelAfter="EUR"
                label="Landing fee"
                error={!!errors.landingFee}
                fullWidth
                paddingBottom
              />
            </div>
            <Heading variant="h2">{t("addAircraft.checkTimes")}</Heading>
            <div className={classes.flex}>
              <TextInput
                name="preflightCheckTime"
                id="preflightCheckTime"
                type="number"
                min="0"
                value={values.preflightCheckTime}
                onChange={handleChange}
                labelAfter="mins"
                label={t("addAircraft.inputPreflightCheckTime")}
                error={!!errors.preflightCheckTime}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="preflightCheckTimeHomebase"
                id="preflightCheckTimeHomebase"
                type="number"
                min="0"
                value={values.preflightCheckTimeHomebase}
                onChange={handleChange}
                labelAfter="mins"
                label={t("addAircraft.inputPreflightCheckTimeHomebase")}
                error={!!errors.preflightCheckTimeHomebase}
                fullWidth
                paddingBottom
              />
              <TextInput
                name="postflightCheckTime"
                id="postflightCheckTime"
                type="number"
                min="0"
                value={values.postflightCheckTime}
                onChange={handleChange}
                labelAfter="mins"
                label={t("addAircraft.inputPostflightCheckTime")}
                error={!!errors.postflightCheckTime}
                fullWidth
                paddingBottom
              />
            </div>
          </form>
        )}
      />
    );
  }

  renderPage4(addAircraft: MutationFn<addAircraft, addAircraftVariables>) {
    const { t, classes } = this.props;

    return (
      <Formik
        validationSchema={validationSchemaPage4}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          const newAircraft = { ...this.newAircraft, ...values };
          this.newAircraft = newAircraft;

          try {
            await addAircraft({
              variables: newAircraft
            });
            this.props.handleClose();
          } catch (error) {
            console.error(error);
          } finally {
            actions.setSubmitting(false);
          }
        }}
        initialValues={{
          flightRanges: this.newAircraft.flightRanges.length
            ? this.newAircraft.flightRanges
            : flightRangesFactory(this.newAircraft.maximumCapacity)
        }}
        render={({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid
        }) => (
          <form onSubmit={handleSubmit} id="addAircraftForm">
            <Heading variant="h2">{t("addAircraft.flightRanges.title")}</Heading>
            <FieldArray
              name="flightRanges"
              render={() => (
                <React.Fragment>
                  {values.flightRanges.map((flightRange, index) => (
                    <React.Fragment>
                      <Heading variant="h3">{index} passengers</Heading>
                      <div className={classes.flex}>
                        <TextInput
                          id={`flightRanges.${index}.maximumFlightRange`}
                          name={`flightRanges.${index}.maximumFlightRange`}
                          type="number"
                          min="0"
                          label={t("addAircraft.flightRanges.maximumFlightRange")}
                          labelAfter="nm"
                          value={flightRange.maximumFlightRange}
                          onChange={handleChange}
                          fullWidth
                          paddingBottom
                          error={
                            !!(
                              errors &&
                              errors.flightRanges &&
                              errors.flightRanges[index] &&
                              // @ts-ignore
                              errors.flightRanges[index].maximumFlightRange
                            )
                          }
                        />
                        <TextInput
                          id={`flightRanges.${index}.minimalLandingRunwayLength`}
                          name={`flightRanges.${index}.minimalLandingRunwayLength`}
                          type="number"
                          min="0"
                          label={t("addAircraft.flightRanges.minimalLandingRunwayLength")}
                          labelAfter="ft"
                          value={flightRange.minimalLandingRunwayLength}
                          onChange={handleChange}
                          fullWidth
                          paddingBottom
                          error={
                            !!(
                              errors &&
                              errors.flightRanges &&
                              errors.flightRanges[index] &&
                              // @ts-ignore
                              errors.flightRanges[index].minimalLandingRunwayLength
                            )
                          }
                        />
                        <TextInput
                          id={`flightRanges.${index}.runwayLengthWarning`}
                          name={`flightRanges.${index}.runwayLengthWarning`}
                          type="number"
                          min="0"
                          label={t("addAircraft.flightRanges.runwayLengthWarning")}
                          labelAfter="ft"
                          value={flightRange.runwayLengthWarning}
                          onChange={handleChange}
                          fullWidth
                          paddingBottom
                          error={
                            !!(
                              errors &&
                              errors.flightRanges &&
                              errors.flightRanges[index] &&
                              // @ts-ignore
                              errors.flightRanges[index].runwayLengthWarning
                            )
                          }
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            />
          </form>
        )}
      />
    );
  }

  render() {
    const { t } = this.props;

    return (
      <AddAircraftMutation mutation={ADD_AIRCRAFT} refetchQueries={["getAircrafts"]} awaitRefetchQueries>
        {(addAircraft, { loading, error }) => (
          <Modal handleClose={this.props.handleClose} size="large" closeOnOverlayClick>
            <ModalHeader
              title={t("addAircraft.title")}
              actionButton={
                <Button small variant="secondary" onClick={this.props.handleClose}>
                  Back to list
                </Button>
              }
            />
            <ModalSteps
              active={this.state.page}
              steps={["Basic info", "Aircraft performance", "Fees", "Flight ranges"]}
            />
            <ModalContent>
              {this.state.page === 1 && this.renderPage1()}
              {this.state.page === 2 && this.renderPage2()}
              {this.state.page === 3 && this.renderPage3()}
              {this.state.page === 4 && this.renderPage4(addAircraft)}
            </ModalContent>
            <ModalFooter>
              {this.state.page !== 1 && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ page: this.state.page - 1 });
                  }}
                >
                  Back
                </Button>
              )}
              <Button type="submit" variant="primary" form="addAircraftForm" loading={loading}>
                {this.state.page === 4 ? "Add aircraft" : "Next"}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </AddAircraftMutation>
    );
  }
}

export default withTranslation()(withStyles(styles)(AddAircraftModal));

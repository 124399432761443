import React from "react";
import clsx from "clsx";
import withStyles, { WithStyles } from "react-jss";

import { Theme } from "../theme";

const styles = (theme: Theme) => ({
  root: {},
  wrapper: {
    width: 74,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  label: {
    paddingBottom: 8,
    fontFamily: theme.typography.primaryFontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.colors.darkBlue
  },
  button: {
    width: 16,
    height: 16,
    borderRadius: 2,
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 600,
    backgroundColor: theme.colors.lightGrey,
    color: theme.colors.blue,
    cursor: "pointer",
    border: "none",
    padding: 0,
    transition: "background-color 0.2s, color 0.2s",
    "&:hover, &:focus": {
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
      outline: "none"
    },
    "&:disabled, &:disabled:hover, &:disabled:focus": {
      backgroundColor: theme.colors.mediumGrey,
      color: "#cdcdcd"
    }
  },
  increase: {
    paddingTop: 1
  },
  value: {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.colors.black,
    textAlign: "center"
  },
  input: {
    display: "none"
  }
});

interface Props extends WithStyles<typeof styles> {
  step?: number;
  value?: number;
  min?: number;
  max?: number;
  label?: string;
  disabled?: boolean;
  className?: string;
  onChange: ({ value }: { value: number }) => void;
}

class NumberInput extends React.PureComponent<Props> {
  static defaultProps = {
    min: 0,
    max: 999,
    step: 1,
    onChange: () => {},
    onBlur: () => {}
  };

  inputComponent = React.createRef<HTMLDivElement>();

  handleChange = (change: number) => {
    this.props.onChange({ value: this.props.value! + change });
  };

  decrease = () => {
    this.handleChange(-this.props.step!);
  };

  increase = () => {
    this.handleChange(this.props.step!);
  };

  render() {
    const { classes, className, min, max, disabled, value, label } = this.props;

    return (
      <div className={clsx(classes.root, className)}>
        <div className={classes.label}>{label}</div>
        <div className={classes.wrapper}>
          <button onClick={this.decrease} className={classes.button} disabled={disabled || min === value} type="button">
            -
          </button>
          <div ref={this.inputComponent} className={classes.value}>
            {value}
          </div>
          <button
            onClick={this.increase}
            className={clsx(classes.button, classes.increase)}
            disabled={disabled || max === value}
            type="button"
          >
            +
          </button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NumberInput);

import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Heading from "../../components/Heading";

import { airports_airports_edges_node_handlings } from "./__generated__/airports";
import AirportAircraftCategoryFeeTable from "./AirportAircraftCategoryFeeTable";

interface Props {
  handling: airports_airports_edges_node_handlings | null;
}

const AirportHandling: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { handling } = props;

  if (!handling) return null;

  return (
    <div>
      <Heading variant={"h3"}>{handling.name}</Heading>

      {(handling.url || handling.phone || handling.email || handling.openingTimes) && (
        <ul>
          {handling.url && (
            <li>
              <strong>Web:</strong> {handling.url}
            </li>
          )}
          {handling.phone && (
            <li>
              <strong>Phone:</strong> {handling.phone}
            </li>
          )}
          {handling.email && (
            <li>
              <strong>Email:</strong> {handling.email}
            </li>
          )}
          {handling.openingTimes && (
            <li>
              <strong>Opening times:</strong> {DateTime.fromISO(handling.openingTimes.opening).toFormat("HH:mm")} -{" "}
              {DateTime.fromISO(handling.openingTimes.closing).toFormat("HH:mm")}
            </li>
          )}
        </ul>
      )}

      <AirportAircraftCategoryFeeTable fees={handling.handlingFees} />
    </div>
  );
};

export default AirportHandling;
